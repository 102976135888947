import React from "react";

const NotFound = () => {
  return (
    <div className="w-full h-full">
      <img
        className="w-[100vw] h-[100vh]"
        src="https://img.freepik.com/free-vector/funny-error-404-background-design_1167-219.jpg?t=st=1648959040~exp=1648959640~hmac=a5ee0587c9762b9091d08c504615641f08d748d35297177aa3b37dd8cd6266d9&w=826"
        alt=""
      />
    </div>
  );
};

export default NotFound;
