import React from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/Topbar";
import useHandleScroll from "../../hooks/useHandleScroll";

import project_idea from "../../assets/img/project_idea.jpg";
import ProjectCard from "../../components/projectCard/ProjectCard";
import ProjectDots from "../../components/projectCard/ProjectDots";

// image imports
import eco from "../../assets/img/EFGEcopark.jpg";
import meal from "../../assets/img/EFGFeedMeal.png";
import foodLtd from "../../assets/img/EFGFoodLtd.jpg";
import park1 from "../../assets/img/EFGFoodPark01.jpg";
import park2 from "../../assets/img/EFGFoodPark02.jpg";
import freshFish from "../../assets/img/EFGFreshWaterFish.jpg";
import packaging from "../../assets/img/EFGPackging.png";
import mbOnline from "../../assets/img/MasalaBazaz(MBOnline).jpg";
import shrimps from "../../assets/img/MasalaShrimps.jpg";
// image imports

const Project = () => {
  document.title = "Projects";
  const size = useHandleScroll();

  const yr2022 = [
    {
      title: "EFG Fresh Water Fish",
      loc: "Jagannatpur, Sunamganj",
      area: "4 Acres",
      img: freshFish,
    },
    {
      title: "Masala Bazar(MB Online)",
      loc: "Moulovibazar",
      area: "4 Acres      ",
      img: mbOnline,
    },
  ];

  const yr2023 = [
    {
      title: "EFG Food Ltd.",
      loc: "Nasirabad, Chittagong",
      area: "1 Acres",
      img: foodLtd,
    },
    {
      title: "EFG Ecopark",
      loc: "Jagannatpur, Sunamganj",
      area: "5 Acres",
      img: eco,
    },
    {
      title: "EFG Packaging",
      loc: "Ashulia, Dhaka        ",
      area: "",
      img: packaging,
    },
  ];
  const yr2024 = [
    {
      title: "EFG Food  Park 01",
      loc: "Habiohanj",
      area: "5.5 Acres ",
      img: park1,
    },
  ];

  const yr2025 = [
    {
      title: "EFG Food Park 02",
      loc: "Mirsharai Economic Zone,Chittagram",
      area: "10 Acres",
      img: park2,
    },
    {
      title: "EFG Feed Meal",
      loc: "Jabusa, Khulna",
      area: "1.75 Acres      ",
      img: meal,
    },
  ];

  const yr2027 = [
    {
      title: "Masala Shrimps",
      loc: "Jabusa, Khulna",
      area: "1.75 Acres",
      img: shrimps,
    },
  ];

  return (
    <div id="projects">
      <TopBar></TopBar>
      <Navbar sPosition={size}></Navbar>
      <div className="hidden">
        <div className="mt-28 w-[60%] desktop:w-[80%] mx-auto mb-16">
          <div className="flex items-center mb-6">
            <h2 className="text-4xl font-bold ">2022</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-10 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>

          <div className="flex w-full mb-10 tablet:flex-col">
            <img
              src="https://i.ibb.co/W3hpbsF/EFG-Fresh-Water-Fish.png"
              alt=""
              className=" w-[577px] tablet:mb-6"
            />
            <div className="ml-6 text-2xl text-left laptop:text-base">
              <h2 className="text-4xl font-bold laptop:text-2xl ">
                EFG Fresh Water Fish
              </h2>
              <p>
                <span>Location:</span> Jagannatpur, Sunamganj
              </p>
              <p>
                <span>Land Area:</span> 4 Acres
              </p>
            </div>
          </div>
          <div className="flex w-full tablet:flex-col">
            <img
              src="https://i.ibb.co/r6drNhS/Masala-Bazar-MB-Online.png"
              alt=""
              className=" w-[577px] tablet:mb-6"
            />
            <div className="ml-6 text-2xl text-left">
              <h2 className="text-4xl font-bold laptop:text-2xl">
                Masala Bazar(MB Online)
              </h2>
              <p>
                <span>Location:</span> Moulovibazar
              </p>
              <p>
                <span>Land Area:</span> 4 Acres
              </p>
            </div>
          </div>
        </div>

        <div className="mt-28 w-[60%] desktop:w-[80%] mx-auto mb-16">
          <div className="flex items-center justify-end mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-10 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            <h2 className="text-4xl font-bold text-right">2023</h2>
          </div>

          <div className="flex justify-between w-full mb-10 tablet:flex-col">
            <div className="mr-6 text-2xl text-left">
              <h2 className="text-4xl font-bold ">EFG Food Ltd.</h2>
              <p>
                <span>Location:</span> Nasirabad, Chittagong
              </p>
              <p>
                <span>Land Area:</span> 1 Acres
              </p>
            </div>
            <img
              src="https://i.ibb.co/Zx1PyWK/EFG-Food-Ltd.png"
              alt=""
              className=" w-[577px] tablet:mt-6"
            />
          </div>
          <div className="flex justify-between w-full mb-10 tablet:flex-col">
            <div className="mr-6 text-2xl text-left">
              <h2 className="text-4xl font-bold ">EFG Ecopark</h2>
              <p>
                <span>Location:</span> Jagannatpur, Sunamganj
              </p>
              <p>
                <span>Land Area:</span> 5 Acres
              </p>
            </div>
            <img
              src="https://i.ibb.co/JC8MWvx/EFG-Ecopark.png"
              alt=""
              className=" w-[577px] tablet:mt-6"
            />
          </div>
          <div className="flex justify-between w-full tablet:flex-col">
            <div className="mr-6 text-2xl text-left">
              <h2 className="text-4xl font-bold ">EFG Packging</h2>
              <p>
                <span>Location:</span> Ashulia, Dhaka
              </p>
              <p>
                <span>Land Area:</span> 4 Acres
              </p>
            </div>
            <img
              src="https://i.ibb.co/brQSC4J/EFG-Packging.png"
              alt=""
              className=" w-[577px] tablet:mt-6"
            />
          </div>
        </div>

        <div className="mt-28 w-[60%] desktop:w-[80%] mx-auto mb-16">
          <div className="flex items-center mb-6">
            <h2 className="text-4xl font-bold ">2024</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-10 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>

          <div className="flex w-full mb-10 tablet:flex-col">
            <img
              src="https://i.ibb.co/gFXbqyr/EFG-Food-Park-01.png"
              alt=""
              className=" w-[577px] tablet:mb-6"
            />
            <div className="ml-6 text-2xl text-left laptop:text-base">
              <h2 className="text-4xl font-bold laptop:text-2xl ">
                EFG Food Park 01
              </h2>
              <p>
                <span>Location:</span> Habiohanj
              </p>
              <p>
                <span>Land Area:</span> 5.5 Acres
              </p>
            </div>
          </div>
        </div>

        <div className="mt-28 w-[60%] desktop:w-[80%] mx-auto mb-16">
          <div className="flex items-center justify-end mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-10 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M7 16l-4-4m0 0l4-4m-4 4h18"
              />
            </svg>
            <h2 className="text-4xl font-bold text-right">2025</h2>
          </div>

          <div className="flex justify-between w-full mb-10 tablet:flex-col">
            <div className="mr-6 text-2xl text-left">
              <h2 className="text-4xl font-bold ">EFG Food Park 02</h2>
              <p>
                <span>Location:</span> Mirsharai Economic Zone, Chittagram
              </p>
              <p>
                <span>Land Area:</span> 10 Acres
              </p>
            </div>
            <img
              src="https://i.ibb.co/nRZ8ZkS/EFG-Food-Park-02.png"
              alt=""
              className=" w-[577px] tablet:mt-6"
            />
          </div>
          <div className="flex justify-between w-full mb-10 tablet:flex-col">
            <div className="mr-6 text-2xl text-left">
              <h2 className="text-4xl font-bold ">EFG Feed Meal</h2>
              <p>
                <span>Location:</span> Jabusa, Khulna
              </p>
              <p>
                <span>Land Area:</span> 1.75 Acres
              </p>
            </div>
            <img
              src="https://i.ibb.co/Ns7QpNG/EFG-Feed-Meal.png"
              alt=""
              className=" w-[577px] tablet:mt-6"
            />
          </div>
        </div>

        <div className="mt-28 w-[60%] desktop:w-[80%] mx-auto mb-16">
          <div className="flex items-center mb-6">
            <h2 className="text-4xl font-bold ">2027</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-10 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </div>

          <div className="flex w-full mb-10 tablet:flex-col">
            <img
              src="https://i.ibb.co/hHTkmrz/Masala-Shrimps.png"
              alt=""
              className=" w-[577px] tablet:mb-6"
            />
            <div className="ml-6 text-2xl text-left laptop:text-base">
              <h2 className="text-4xl font-bold laptop:text-2xl ">
                Masala Shrimps
              </h2>
              <p>
                <span>Location:</span> Jabusa, Khulna
              </p>
              <p>
                <span>Land Area:</span> 1.75 Acres
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* banner Image */}
      <div className="relative h-[444px] mobile:h-[250px]">
        <img
          src={project_idea}
          alt="project_idea"
          className="object-cover w-full h-full "
        />
        <div className="absolute left-1/2 top-2/4">
          <div className="relative left-[-50%]">
            <h2 className="mx-2 mb-1 text-5xl font-normal text-white mobs:text-2xl tab2:text-4xl desktop:text-5xl mobile:text-3xl">
              Upcoming Projects
              <hr className="py-[2px] mobile:py-[1px] bg-white" />
            </h2>
          </div>
        </div>
      </div>

      {/* project road-map */}

      <div className="h-[2400px] mobs:h-[3300px]">
        <div className="relative h-full w-1 mx-auto tab1:ml-10 bg-[#3f3f3f]">
          {/* 2022 */}
          <ProjectDots
            dot="top-28 right-4"
            line="right-0 tab1:left-16"
            yearClass="right-16 tab1:left-32"
            year="2022"
          ></ProjectDots>
          <ProjectCard
            classes="absolute top-28 tab1:top-44 left-12"
            cardData={yr2022}
          ></ProjectCard>

          {/* 2023 */}
          <ProjectCard
            classes="absolute top-[600px] tab1:top-[670px]  mobs:top-[870px] right-12 tab1:left-12"
            cardData={yr2023}
          ></ProjectCard>
          <ProjectDots
            dot="top-[600px]  mobs:top-[800px] right-4"
            line="left-16"
            yearClass="left-32"
            year="2023"
          ></ProjectDots>

          {/* 2024 */}
          <ProjectDots
            dot="top-[1200px] mobs:top-[1800px] right-4"
            line="right-0 tab1:left-16"
            yearClass="right-16 tab1:left-32"
            year="2024"
          ></ProjectDots>
          <ProjectCard
            classes="absolute top-[1200px] mobs:top-[1870px] tab1:top-[1280px] left-12"
            cardData={yr2024}
          ></ProjectCard>

          {/* 2025 */}
          <ProjectCard
            classes="absolute top-[1600px] mobs:top-[2270px] tab1:top-[1680px] right-12 tab1:left-12"
            cardData={yr2025}
          ></ProjectCard>
          <ProjectDots
            dot="top-[1600px] mobs:top-[2200px] right-4"
            line="left-16"
            yearClass="left-32"
            year="2025"
          ></ProjectDots>

          {/* 2027 */}
          <ProjectDots
            dot="top-[2100px] mobs:top-[2900px] right-4"
            line="right-0 tab1:left-16"
            yearClass="right-16 tab1:left-32"
            year="2027"
          ></ProjectDots>
          <ProjectCard
            classes="absolute top-[2100px] mobs:top-[2970px] tab1:top-[2180px] left-12"
            cardData={yr2027}
          ></ProjectCard>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default Project;
