import React, { useEffect, useState } from "react";

// import AOS from "aos";
import "aos/dist/aos.css";

import aqua_banner from "../../assets/img/aqua_banner.png";
import rosemco_banner from "../../assets/img/rosemco_banner.png";
import saidola_banner from "../../assets/img/saidola_banner.png";
import masala_banner from "../../assets/img/masala_banner.png";
import fashion_banner from "../../assets/img/fashion_banner.png";
import eurasia_banner from "../../assets/img/eurasia_banner.png";
import tech_banner from "../../assets/img/tech_banner.png";
import thirty_year_banner from "../../assets/img/thirty_year_banner.png";
import thirty_year_mobile from "../../assets/img/thirty_year_mobile.png";
// import award from "../../assets/img/award_pic.png";
import useWindowDimensions from "../../hooks/useWindowDimentions";

const ShowcaseCard = ({ slide }) => {
  const { height, width } = useWindowDimensions();
  // console.log(height, width);

  // const [slide, setSlide] = useState(1);
  // const [stopTimer, setStopTimer] = useState(false);

  // const {
  //   aqua_banner,
  //   rosemco_banner,
  //   saidola_banner,
  //   masala_banner,
  //   tech_banner,
  //   fashion_banner,
  //   eurasia_banner,
  // } = imageArray;

  // useEffect(() => {
  //   let timer1;
  //   if (stopTimer === false) {
  //     timer1 = setTimeout(() => {
  //       if (slide === 3) {
  //         setSlide(1);
  //       } else {
  //         setSlide(slide + 1);
  //       }
  //     }, 3500);
  //     setStopTimer(false);
  //   } else if (stopTimer === true) {
  //     clearTimeout(timer1);
  //     setStopTimer(true);
  //   }

  //   return () => {
  //     clearInterval(timer1);
  //   };
  // }, [slide, stopTimer]);

  // let slide;
  // useEffect(() => {
  //   slide = state;

  //   return () => {};
  // }, [slide]);

  return (
    <>
      {slide === 1 && (
        <>
          {/* 3rd pic */}
          <div
            className="absolute top-0 right-0 h-full 2xl:wide:w-[40%] wide:w-[100%] wide:-right-40 desktop:-right-80 mobs:w-[170%] mobs:-right-52 animate-fadeRight mobile:w-[180%] tab2:w-[150%]"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1400"
            // data-aos-easing="ease-in-sine"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <img
              src={width <= 821 ? thirty_year_mobile : thirty_year_banner}
              alt="rosemco_banner"
              className="object-cover w-full h-full tab2:object-contain"
            />
          </div>
        </>
      )}

      {/* (
        <>
          
          <div
            className="absolute top-0 right-0 h-full 2xl:wide:w-[40%] wide:w-[100%] wide:-right-40 desktop:-right-80 mobs:w-[170%] mobs:-right-50 animate-fadeRight tab2:w-[170%]"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1400"
            // data-aos-easing="ease-in-sine"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <img
              src={thirty_year_banner}
              alt="rosemco_banner"
              className="object-cover w-full h-full "
            />
          </div>
        </>
      )} */}

      {slide === 2 && (
        <>
          {/* 1st pic */}
          <div
            className="absolute top-0 h-full right-96 wide:w-[66%] wide:right-60 desktop:w-[100%] desktop:right-5 laptop:w-[100%] laptop:right-10 mobile:w-[140%] mobile:-right-[68px] animate-fadeRightSlower"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1400"
            // data-aos-easing="ease-in"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <div className="relative w-full h-full">
              <img
                src={saidola_banner}
                alt="saidola_banner"
                className="object-cover w-full h-full"
              />
            </div>
          </div>
          {/* 2nd pic */}
          <div
            className="absolute top-0 h-full right-20 wide:right-4 wide:w-[60%] desktop:w-[70%] desktop:-right-10 laptop:w-[80%] laptop:-right-4 mobile:w-[120%] mobile:-right-36 animate-fadeRightSlow"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1300"
            // data-aos-easing="ease-in"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <img
              src={aqua_banner}
              alt="aqua_banner"
              className="object-cover w-full h-full"
            />
          </div>
          {/* 3rd pic */}
          <div
            className="absolute top-0 right-0 h-full wide:w-[40%] desktop:w-[60%] desktop:-right-24 laptop:w-[60%] laptop:-right-14 mobile:w-[80%] mobile:-right-28 animate-fadeRight"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1400"
            // data-aos-easing="ease-in-sine"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <img
              src={rosemco_banner}
              alt="rosemco_banner"
              className="object-cover w-full h-full"
            />
          </div>
        </>
      )}
      {slide === 3 && (
        <>
          {/* 1st pic */}
          <div
            className="absolute top-0 h-full right-96 wide:w-[66%] wide:right-60 desktop:w-[100%] desktop:right-5 laptop:w-[100%] laptop:right-10 mobile:w-[140%] mobile:-right-20 animate-fadeRightSlower"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1400"
            // data-aos-easing="ease-in"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <img
              src={masala_banner}
              alt="saidola_banner"
              className="object-cover w-full h-full"
            />
          </div>
          {/* 2nd pic */}
          <div
            className="absolute top-0 h-full right-20 wide:right-4 wide:w-[60%] desktop:w-[70%] desktop:-right-10 laptop:w-[80%] laptop:-right-4 mobile:w-[125%] mobile:-right-36 animate-fadeRightSlow"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1300"
            // data-aos-easing="ease-in"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <img
              src={eurasia_banner}
              alt="aqua_banner"
              className="object-cover w-full h-full"
            />
          </div>
          {/* 3rd pic */}
          <div
            className="absolute top-0 right-0 h-full wide:w-[40%] desktop:w-[60%] desktop:-right-24 laptop:w-[60%] laptop:-right-14 mobile:w-[90%] mobile:-right-28 animate-fadeRight"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1400"
            // data-aos-easing="ease-in-sine"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <img
              src={fashion_banner}
              alt="rosemco_banner"
              className="object-cover w-full h-full"
            />
          </div>
        </>
      )}
      {slide === 4 && (
        <>
          {/* 3rd pic */}
          <div
            className="absolute top-0 right-0 h-full 2xl:wide:w-[40%] wide:w-[100%] wide:-right-40  desktop:-right-80 mobs:w-[170%] mobs:-right-50 animate-fadeRight tab2:w-[150%]"
            // data-aos="fade-left"
            // data-aos-delay="50"
            // data-aos-duration="1400"
            // data-aos-easing="ease-in-sine"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <img
              src={tech_banner}
              alt="rosemco_banner"
              className="object-cover w-full h-full tab2:object-contain"
            />
          </div>
        </>
      )}
    </>
  );
};

export default ShowcaseCard;
