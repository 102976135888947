import React, { useState } from "react";

import Footer from "../../components/footer/Footer";
import News from "../../components/news/News";
import Awards from "../../layout/awards/Awards";
// import Banner from "../../layout/banner/Banner";
import Heroes from "../../layout/heroes/Heroes";
import Mission from "../../layout/mission/Mission";
import Service from "../../layout/services/Service";
import Feedback from "../../layout/feedBack/Feedback";

import zoy from "../../../src/assets/icons/zoy_logo.svg";
import crown from "../../../src/assets/icons/Crown Farms Logo-01.png";
import al_rahim from "../../../src/assets/icons/Al_Rohim_logo.svg";
import masalaBazar from "../../../src/assets/icons/masalaBazaarLogo.svg";
import masala from "../../../src/assets/icons/masala_logo.svg";
import efg_fashion from "../../../src/assets/icons/efg_Logo.png";

// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";

import "./Home.scss";

import TopBar from "../../components/topbar/Topbar";
import Navbar from "../../components/navbar/Navbar";
import useHandleScroll from "../../hooks/useHandleScroll";
import Showcase from "../../layout/showcase/Showcase";
import Titles from "../../components/titles/Titles";
import Modal from "../../components/modal/Modal";

import AOS from "aos";
import "aos/dist/aos.css";
import { AllContext } from "../../hooks/useContextApi";
import { useEffect } from "react";

AOS.init();
document.title = "Home";

const Home = () => {
  const scrollPosition = useHandleScroll();
  const values = React.useContext(AllContext);
  // console.log(timer);

  return (
    <>
      {/* <div className="bg-bg-color"> */}
      <div
        className={
          values.open || values.loader
            ? "blur pointer-events-none overflow-hidden w-[100vw]"
            : ""
        }
      >
        <TopBar />
        <Navbar sPosition={scrollPosition} />
        <Showcase></Showcase>
        <Mission></Mission>

        <Service />

        <Awards />

        <Heroes></Heroes>

        {/* brands */}
        <section className="mt-10 mobile:mt-4">
          <Titles text="Brands"></Titles>
          <div className="flex flex-wrap items-center w-11/12 py-20 mx-auto mobile:py-10 mobile:pb-0 Grid lg:w-11/12 justify-evenly lg:justify-around">
            <div className="w-24 md:w-28 xl:w-40">
              <img className="w-full shimmer" src={masala} alt="" />
            </div>
            <div className="w-24 md:w-28 xl:w-40">
              <img
                className="w-full shimmer"
                src={masalaBazar}
                alt="Al_Rohim_logo"
              />
            </div>

            <div className="w-24 md:w-28 xl:w-40">
              <img className="w-full shimmer" src={crown} alt="crown_logo" />
            </div>
            <div className="w-24 md:w-28 xl:w-40">
              <img
                className="w-full shimmer"
                src={al_rahim}
                alt="Al_Rohim_logo"
              />
            </div>
            <div className="w-24 md:w-28 xl:w-40">
              <img className="w-full shimmer" src={zoy} alt="zoy_logo" />
            </div>
            <div className="w-24 md:w-28 xl:w-40">
              <img
                className="w-full shimmer"
                src={efg_fashion}
                alt="Al_Rohim_logo"
              />
            </div>
          </div>
        </section>
        <News></News>
        <Feedback type="home" />
        <Footer></Footer>
      </div>
    </>
  );
};

export default Home;
