import React from "react";
import { useLocation } from "react-router-dom";
import CompanyPage from "../../components/companypage/CompanyPage";
const Efgt = ({ galary }) => {
  const location = useLocation();
  console.log(galary);

  return (
    <>
      {galary && (
        <div id="efgt">
          <CompanyPage pathname={location?.pathname}></CompanyPage>
        </div>
      )}
      {!galary && <div className="mt-20">this is efgt galary</div>}
    </>
  );
};

export default Efgt;
