import React from "react";
import { useLocation } from "react-router-dom";
import CompanyPage from "../../components/companypage/CompanyPage";

const HorizonSeaFood = () => {
  const location = useLocation();
  return (
    <div>
      <CompanyPage pathname={location.pathname}></CompanyPage>
    </div>
  );
};

export default HorizonSeaFood;
