import React from "react";
import test from "../../assets/img/snacks.jpg";
import VideoCard from "../videocard/VideoCard";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
import "swiper/scss";
import ImageCard from "../imagecard/ImageCard";
import { useState } from "react";

const GalaryCommon = () => {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <>
      <section className="wide:w-[88%] w-[73%] mx-auto pb-5">
        <div className="flex items-center justify-start pt-4">
          <h2 className=" text-xl font-semibold text-left text-[#006A4E]">
            Videos
          </h2>
          <ChevronDoubleRightIcon className="inline text-[#006A4E] w-5 cursor-pointer" />
        </div>
        {/* <div className="grid items-center hidden grid-cols-4 gap-6 tab2:flex desktop_mini:grid-cols-3 justify-items-center tab2:overflow-x-scroll">
          <VideoCard bg={test}></VideoCard>
          <VideoCard bg={test}></VideoCard>
          <VideoCard bg={test}></VideoCard>
          <VideoCard bg={test}></VideoCard>
        </div> */}

        <Swiper
          className="  2xl:w-[1400px] w-[1280px] desktop:w-[980px] laptop:w-[740px] tablet:w-[540px] mobile:w-[480px] mobs:w-[360px] mx-auto mt-16 mb-20 relative"
          modules={[Navigation]}
          breakpoints={{
            // when window width is >= 640px
            360: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            480: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 110,
            },
            // when window width is >= 768px
            768: {
              width: 680,
              slidesPerView: 2.2,
            },
            1024: {
              width: 1024,
              slidesPerView: 3,
            },
            1280: {
              width: 1280,
              slidesPerView: 4,
            },
            1535: {
              width: 1535,
              slidesPerView: 4,
            },
          }}
          // navigation
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          spaceBetween={110}
          loop="true"
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {[1, 2, 3, 4, 5].map((item) => (
            <SwiperSlide>
              <div
                key={item}
                className="relative h-[360px] tab2:my-2 w-[280px] p-0 overflow-hidden drop-shadow transition-all mx-auto"
              >
                <VideoCard bg={test}></VideoCard>
              </div>
            </SwiperSlide>
          ))}

          <div className="flex items-center justify-center mt-8 text-lg font-semibold tab2:hidden">
            <div
              ref={navigationPrevRef}
              className="flex mr-14 hover:text-green-900"
            >
              <ChevronDoubleLeftIcon className="inline w-5 cursor-pointer" />
              <p className="ml-1 cursor-pointer select-none">Prev</p>
            </div>
            <div ref={navigationNextRef} className="flex hover:text-green-900">
              <p className="mr-1 cursor-pointer select-none">Next</p>
              <ChevronDoubleRightIcon className="inline w-5 cursor-pointer" />
            </div>
          </div>
        </Swiper>

        <div className="flex items-center justify-start pt-0 pb-6">
          <h2 className=" text-xl font-semibold text-left text-[#006A4E]">
            Photos
          </h2>
          <ChevronDoubleRightIcon className="inline text-[#006A4E] w-5 cursor-pointer" />
        </div>
        <div className="grid grid-cols-4 gap-5 desktop:grid-cols-3 tab1:grid-cols-2 tab1:leading-[2px] mobile:grid-cols-1">
          {[5, 8, 2, 9, 4, 6, 1, 10, 25, 62, 47, 23, 21, 78].map((item) => (
            <ImageCard key={item} test={test}></ImageCard>
          ))}
        </div>
      </section>
    </>
  );
};

export default GalaryCommon;
