import React from "react";
import { useLocation } from "react-router-dom";
import CompanyPage from "../../components/companypage/CompanyPage";

const EfgAqua = ({ galary }) => {
  const location = useLocation();
  return (
    <>
      {galary ? (
        <div id="efgaqua">
          <CompanyPage pathname={location.pathname}></CompanyPage>
        </div>
      ) : (
        <div>This is efg aqua galary</div>
      )}
    </>
  );
};

export default EfgAqua;
