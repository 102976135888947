import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

// import Card from "../../components/card/Card";
// import Button from "../../components/button/Button";
import Titles from "../../components/titles/Titles";

// import service1 from "../../assets/img/Group2.jpg";
// import service2 from "../../assets/img/Group3.png";
// import service3 from "../../assets/img/Group4.png";
// import efgFashion from "../../assets/img/Group5.png";
// import machine from "../../assets/img/Group6.png";
// import bazar from "../../assets/img/Group7.png";
// import shopping from "../../assets/img/Group8.png";
// import eurasia from "../../assets/img/Group9.png";
// import rosemco from "../../assets/img/Group10.png";
import horizon from "../../assets/img/Group11.png";
// import aqua from "../../assets/img/Group12.png";
// import efg_Logo from "../../assets/img/efg_Logo.png";
// import efg_card_img from "../../assets/img/efg_card_img.png";
// import efhTech from "../../assets/img/Cart Image-EFG Tech.png";
// import aqua from "../../assets/img/Cart Image-Masala Shirmps.png";
// import masalbazzar from "../../assets/img/Cart Image-MasalaBazaar.png";
// import eurasia from "../../assets/img/Cart Image-MB Online.png";
// import rosemco from "../../assets/img/Cart Image-Rosemco.png";
// import saidowla from "../../assets/img/Cart Image-saidola.png";
import data from "../../assets/database.json";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
import "swiper/scss";

import { HashLink } from "react-router-hash-link";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

const Service = () => {
  const cardData = data.complex.slice(1, 9);
  // console.log(cardData[0].link);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <div
      data-aos="fade-right"
      data-aos-delay="0"
      data-aos-duration="600"
      data-aos-easing="ease-in-out"
      data-aos-mirror="false"
      data-aos-once="false"
      data-aos-anchor-placement="top-center"
      className="mt-20 mobile:mt-12"
    >
      <span id="companies"></span>
      <Titles text="Our Companies" />

      <Swiper
        className=" 2xl:w-[1400px] w-[1280px] desktop:w-[980px] laptop:w-[768px] tablet:w-[640px] mobile:w-[480px] mobs:w-[360px] mx-auto mt-16 mb-20 relative"
        modules={[Navigation]}
        breakpoints={{
          // when window width is >= 640px
          360: {
            width: 640,
            slidesPerView: 2,
            spaceBetween: 20,
          },
          480: {
            width: 640,
            slidesPerView: 2,
            spaceBetween: 20,
          },
          640: {
            width: 640,
            slidesPerView: 2,
            spaceBetween: 110,
          },
          // when window width is >= 768px
          768: {
            width: 768,
            slidesPerView: 3,
          },
          1024: {
            width: 1024,
            slidesPerView: 3,
          },
          1280: {
            width: 1280,
            slidesPerView: 4,
          },
          1535: {
            width: 1535,
            slidesPerView: 5,
          },
        }}
        // navigation
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        spaceBetween={110}
        loop="true"
        // onSlideChange={() => console.log("slide change")}
        // onSwiper={(swiper) => console.log(swiper)}
      >
        {cardData.map((item) => (
          <SwiperSlide key={item.groupsName}>
            <div className="relative h-[360px] tab2:my-2 w-[280px] p-0 overflow-hidden drop-shadow rounded-2xl transition-all mx-auto bg-white ">
              <img src={item.card_image} className="w-full h-[46%]" alt="" />
              <div className="p-4 mt-2 ">
                <div className="flex items-center">
                  {/* <img src={item.logo} alt="" className="w-20 h-12 mr-1" /> */}
                  <h2 className="text-xl font-medium whitespace-nowrap">
                    {item.groupsName}
                  </h2>
                </div>
                <p className="my-2 text-xs text-left">{item.groupsDesc}</p>
              </div>
              <div className="absolute flex justify-end w-full bottom-4 right-3 text-tertiary-color">
                <HashLink
                  to={`${item.link}#${item?.link.split("/")[1]}`}
                  className="mr-1 text-sm font-bold"
                >
                  View Details
                </HashLink>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          </SwiperSlide>
        ))}
        <div className="flex items-center justify-center mt-8 text-lg font-semibold tab2:hidden">
          <div
            ref={navigationPrevRef}
            className="flex mr-14 hover:text-green-900"
          >
            <ChevronDoubleLeftIcon className="inline w-5 cursor-pointer" />
            <p className="ml-1 cursor-pointer select-none">Prev</p>
          </div>
          <div ref={navigationNextRef} className="flex hover:text-green-900">
            <p className="mr-1 cursor-pointer select-none">Next</p>
            <ChevronDoubleRightIcon className="inline w-5 cursor-pointer" />
          </div>
        </div>
      </Swiper>
    </div>
  );
};

export default Service;
