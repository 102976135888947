import React from "react";
import { useLocation } from "react-router-dom";
import CompanyPage from "../../components/companypage/CompanyPage";

const Efgf = ({ galary }) => {
  const location = useLocation();

  return (
    <>
      {galary ? (
        <div id="efgf">
          <CompanyPage pathname={location?.pathname}></CompanyPage>
        </div>
      ) : (
        <div>This is efgf galary</div>
      )}
    </>
  );
};

export default Efgf;
