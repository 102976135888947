import React from "react";
import { useState } from "react";

export const AllContext = React.createContext();

const ContextApi = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [openImg, setOpenImg] = useState(false);
  const [loader, setLoader] = useState(false);

  const openModal = () => {
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const openImgModal = () => {
    setOpenImg(true);
  };

  const closeImgModal = () => {
    setOpenImg(false);
  };
  const vars = {
    open,
    openImg,
    setOpen,
    openModal,
    closeModal,
    openImgModal,
    closeImgModal,
    loader,
    setLoader,
  };

  return <AllContext.Provider value={vars}>{children}</AllContext.Provider>;
};

export default ContextApi;
