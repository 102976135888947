import CountUp from "react-countup";
import AOS from "aos";
import "aos/dist/aos.css";

import Card from "../card/Card";
// import fish from "../../assets/img/fish-1.png";
// import gif1 from "../../assets/gif/ezgif-7.gif";
// import gif2 from "../../assets/gif/ezgif-2.gif";

import "./bannerContent.scss";
import { useEffect } from "react";

AOS.init();
const HeaderContent = (props) => {
  // const data = useData();

  const {
    groupsName,
    groupsDesc,
    state,
    proVar,
    proPerD,
    vehicle,
    pUnits,
    emp,
    leftImg,
    leftGif,
    rightGif,
    ltInfo,
    lbInfo,
    ltSign,
    lbSign,
    rtInfo,
    rmInfo,
    rbInfo,
    rtSign,
    rmSign,
    rbSign,
    func,
  } = props;

  // func("printed data from child");

  useEffect(() => {
    // console.log(func);

    return () => {};
  }, [func]);

  return (
    <>
      <div className="flex pt-[160px] justify-center laptop:-ml-8 tab2:hidden">
        {/* left-side */}
        <div
          data-aos="fade-right"
          data-aos-delay="50"
          data-aos-duration="1200"
          data-aos-easing="ease-in-out"
          // data-aos-offset='100'
          // data-aos-anchor-placement='top-center'
          xs={4}
          className="-mt-16 -mr-16 left-section desktop: laptop:-mr-2"
        >
          <div className="w-[15vw] h-20 mt-40 ml-8 desktop:mt-44">
            <img src={leftGif} alt="gif1" className="" />
          </div>
          <CountUp start={50} end={proVar} delay={0} suffix={ltSign}>
            {({ countUpRef }) => (
              <div className="bg-[#1B502F] text-white  w-[12vw] h-32 flex items-center justify-center mt-12 desktop: desktop: desktop:text-xs desktop: laptop:w-[15vw]">
                <div className="">
                  {proVar > 0 && <h4 ref={countUpRef}></h4>}
                  <p>{ltInfo}</p>
                </div>
              </div>
            )}
          </CountUp>
          <div className=" w-[11vw] h-20 object-contain mt-16 ml-24 laptop:ml-12 laptop:mt-6 desktop:ml-">
            {leftImg && (
              <img
                className="object-cover w-full h-full"
                src={leftImg}
                alt="fish"
              />
            )}
          </div>
          {/* <img src={flag2} alt='flag2' className='' /> */}
          <Card
            classes="bg-[#08B05F] text-white w-[8vw] h-28 flex items-center justify-center -mt-20 desktop:w-[10vw] desktop:h- desktop:-mt- laptop:w-[14vw] laptop:-mt-20"
            type="textOnly"
            title=""
            text=""
          />
          <CountUp start={1000} end={proPerD} delay={0} suffix={lbSign}>
            {({ countUpRef }) => (
              <div className="bg-[#afa113] text-white w-[12vw] h-32  flex items-center justify-center col-start-2 -mt-[140px] desktop:w- desktop:text-xs laptop:w-[15vw] desktop:-mt-28 laptop:-mt-16">
                <div className="">
                  {proPerD > 0 && <h4 ref={countUpRef}></h4>}
                  <p>{lbInfo}</p>
                </div>
              </div>
            )}
          </CountUp>
        </div>

        {/* center card */}
        <Card
          classes="bg-[#DEB887] text-white w-[27vw] h-[480px] desktop:h-[440px]  flex justify-center rounded-[30px] px-4 mx- -mt-10 desktop:w-[30vw] laptop:w-[28vw] tab1:w-[30vw] laptop:h- desktop:mx-"
          type="textOnly"
          title=""
          func={func}
          state={state}
          text={
            <p className="text-[16px] text-black font-normal right desktop:text-sm">
              {groupsDesc}
            </p>
          }
        >
          <div className="p-6 px-0 mt-12 desktop:mt-10 laptop:mt-8">
            <h1 className=" px-0 text-4xl font-bold text-[#1C3A27] mb-2 tracking-[4px]  group-name desktop:text-3xl laptop:text-2xl">
              {groupsName}
            </h1>
            {groupsName === "EURASIA" && (
              <>
                <p className="text-[#1C3A27] text-xl font-medium tracking-widest mt-[-10px]">
                  Food Processing (BD) LTD
                </p>
              </>
            )}
            {/* <div className='w-[266px] h-[30px] bg-[#08b05f] flex items-center justify-center font-bold text-lg mb-1'>
              <p className='tracking-[8px]'>GROUP</p>
            </div> */}
            {state === 1 && (
              <>
                <h3 className="tracking-[16px] bg-[#08b05f] py-1  font-bold mb-1 desktop:text-sm laptop:text-xs">
                  GROUP
                </h3>
                <p className="desktop:text-xs">Bangladesh Operations</p>
              </>
            )}
            {/* {console.log(func)} */}
            {/* <button
              onClick={() => {
                func(state + 1);
              }}
            >
              Eurasia
            </button> */}
          </div>
        </Card>

        {/* right side */}
        <div className="ml-6 -mt-4 right-section">
          <div
            data-aos="fade-up"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <Card
              classes="bg-[#6E6ECF] text-white  w-[7vw] h-28 flex items-center justify-center mx- mt-10 ml-8 desktop:w-[10vw] desktop:h- desktop:mt- laptop:w- laptop:w-[14vw] laptop:ml-2"
              type="textOnly"
              title=""
              text=""
            />
          </div>

          <div
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <CountUp start={0} end={vehicle} delay={0} suffix={rtSign}>
              {({ countUpRef }) => (
                <div className="bg-[#1D1D35] w-[11vw] h-32 text-white  flex items-center justify-center -ml-16 mt- laptop:w-[16vw] laptop:-ml-18 desktop:-ml-2 2xl:-ml-24 desktop:text-sm">
                  <div className="">
                    {vehicle > 0 && <h4 ref={countUpRef}></h4>}
                    <p>{rtInfo}</p>
                  </div>
                </div>
              )}
            </CountUp>
          </div>
          {/* <img src={flag1} alt='flag1' className=' h-[76px]' /> */}
          <div
            data-aos="fade-up-left"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
            className=" w-[15vw] h-20 mt-6 desktop:w- desktop:h- desktop:mt- "
          >
            <img src={rightGif} alt="gif2" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <CountUp start={0} end={pUnits} delay={0} suffix={rmSign}>
              {({ countUpRef }) => (
                <div className="bg-[#799e2d] text-white w-[10vw] h-32 flex items-center justify-center -mt- laptop:w-[15vw] desktop:text-sm desktop:h- desktop:-mt-">
                  <div className="">
                    <h4 ref={countUpRef}></h4>
                    <p>{rmInfo}</p>
                  </div>
                </div>
              )}
            </CountUp>
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
            className="col-start-2 "
          >
            <CountUp start={0} end={emp} delay={0} suffix={rbSign}>
              {({ countUpRef }) => (
                <div className="bg-[#4EA6B6] text-white w-[10vw] h-32 flex items-center justify-center -mt-2 laptop:w-[15vw] desktop:mt-4 desktop:text-sm">
                  <div className="">
                    {emp > 0 && <h4 ref={countUpRef}></h4>}
                    <p>{rbInfo}</p>
                  </div>
                </div>
              )}
            </CountUp>
          </div>
        </div>
      </div>

      {/* tab mobile view */}
      <div className="w-11/12 mx-auto tab2:block tab2min:hidden">
        <div className="flex pt-[120px] flex-col justify-center items-center">
          <div
            className="w-[80%] tab2:h-[200px] mobile:h-[250px] mobs:h-[280px] mobs:w-[90%] h-[500px] mb-14 rounded-[30px] bg-[#DEB887]  flex justify-center"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <Card
              classes="text-white flex justify-center rounded-[30px] px-4 mx- laptop:h- desktop:mx-"
              type="textOnly"
              title=""
              text={
                <p className="text-[16px] text-black tab2:text-sm font-semibold mobs:mt-0 right tab2:mt-4 mt-10">
                  {groupsDesc}
                </p>
              }
            >
              <div className="p-6 mt-12 tab2:p-0 mobs:mt-8 mobs:p-0 desktop:mt-10 laptop:mt-8">
                <h1 className=" tab2:text-2xl text-4xl font-bold text-[#1C3A27] mb-2 tracking-[4px]  group-name  p-0">
                  {groupsName}
                </h1>
                {/* <div className='w-[266px] h-[30px] bg-[#08b05f] flex items-center justify-center font-bold text-lg mb-1'>
              <p className='tracking-[8px]'>GROUP</p>
            </div> */}
                {groupsName === "EURASIA" && (
                  <>
                    <p className="text-[#1C3A27] text-sm desktop:text-xs tracking-widest mt-[-10px]">
                      Food Processing (BD) LTD
                    </p>
                  </>
                )}
              </div>
            </Card>
          </div>

          {/* tabview picture portion here */}

          <div className="w-[80%] mobs:w-[90%]">
            <div className="mb-4 h-[150px] w-full">
              <img
                className="object-cover w-full h-full rounded-md"
                src={rightGif}
                alt=""
              />
            </div>
            <div className="mb-4 h-[150px] w-full">
              <img
                className="object-cover w-full h-full rounded-md"
                src={leftGif}
                alt=""
              />
            </div>
          </div>

          <div
            className="w-[80%] h-28 mb-4 text-white flex flex-col justify-center text-lg bg-[#1B502F] rounded-[5px]"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <CountUp start={50} end={proVar} delay={0} suffix={ltSign}>
              {({ countUpRef }) => (
                <div className="">
                  <h4 ref={countUpRef}></h4>
                  <p>{ltInfo}</p>
                </div>
              )}
            </CountUp>
          </div>
          <div
            className="w-[80%] h-28 mb-4 text-white flex flex-col justify-center text-lg bg-[#afa113] rounded-[5px]"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <CountUp start={1000} end={proPerD} delay={0} suffix={lbSign}>
              {({ countUpRef }) => (
                <div className="">
                  {proPerD > 0 && <h4 ref={countUpRef}></h4>}
                  <p>{lbInfo}</p>
                </div>
              )}
            </CountUp>
          </div>
          <div
            className="w-[80%] h-28 mb-4 text-white flex flex-col justify-center text-lg bg-[#1D1D35] rounded-[5px]"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <CountUp start={0} end={vehicle} delay={0} suffix={rtSign}>
              {({ countUpRef }) => (
                <div className="">
                  {vehicle > 0 && <h4 ref={countUpRef}></h4>}
                  <p>{rtInfo}</p>
                </div>
              )}
            </CountUp>
          </div>
          <div
            className="w-[80%] h-28 mb-4 text-white flex flex-col justify-center text-lg bg-[#799e2d] rounded-[5px]"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <CountUp start={0} end={pUnits} delay={0} suffix={rmSign}>
              {({ countUpRef }) => (
                <div className="">
                  <h4 ref={countUpRef}></h4>
                  <p>{rmInfo}</p>
                </div>
              )}
            </CountUp>
          </div>
          <div
            className="w-[80%] h-28 mb-4 text-white flex flex-col justify-center text-lg bg-[#4EA6B6] rounded-[5px]"
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1200"
            data-aos-easing="ease-in-out"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
          >
            <CountUp start={0} end={emp} delay={0} suffix={rbSign}>
              {({ countUpRef }) => (
                <div className="">
                  {emp > 0 && <h4 ref={countUpRef}></h4>}
                  <p>{rbInfo}</p>
                </div>
              )}
            </CountUp>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderContent;
