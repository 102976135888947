import React from "react";

import arrow from "../../assets/img/Arrow.png";
import "./ProductBanner.scss";

const ProductBanner = ({ bannerImage }) => {
  return (
    <>
      <div
        className="h-[770px] mobile:h-[360px] p-banner mt-[0px] hidden"
        style={{
          backgroundImage: `url(${bannerImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          zIndex: -10,
        }}
      >
        <div className="text-left mt-[10px] ml-[50%] lg:ml-[60%] bg z-50">
          <h2 className="text-[10vw] md:text-[6vw] lg:text-[5vw] text-white card tracking-wide pt-48">
            FOODS
          </h2>
          <h4 className="ml-4 text-[5vw] md:text-[3vw] lg:text-[2vw] tracking-widest text-white card">
            to good your mood
          </h4>
          <div className="w-2/3">
            <img className="object-fill" src={arrow} alt="" />
          </div>
        </div>
      </div>

      {/* new div */}
      <div className="relative">
        <div className=" w-full h-[600px] laptop:h-[400px] mobile:h-[250px]">
          <img
            className="object-cover w-full h-full"
            src={bannerImage}
            alt=""
          />
        </div>
        <div className="absolute left-1/2 top-[30%] tab2:hidden">
          <div className="relative left-[-10%]">
            <div className="w-2/3 mt-2 ml-auto">
              <h2 className="text-[10vw] md:text-[6vw] lg:text-[5vw] text-white card tracking-wide">
                FOODS
              </h2>
              <h4 className="ml-4 text-[5vw] md:text-[3vw] lg:text-[2vw] tracking-widest text-white card">
                to good your mood
              </h4>
            </div>
            <div className="w-[60%] mt-2 ml-auto mr-4">
              <img className="object-fill" src={arrow} alt="" />
            </div>
          </div>
        </div>
        {/* for tab and mobile */}
        <div className="absolute hidden left-1/2 top-[40%] tab2:block">
          <div className="relative left-[-10%]">
            <div className="w-2/3 mt-2 ml-auto mobs:w-full tab2:w-4/5">
              <h2 className="text-[10vw] md:text-[6vw] lg:text-[5vw] text-white card tracking-wide mobs:text-xl tab2:text-2xl">
                FOODS
              </h2>
              <h4 className="ml-4 mt-2 text-[5vw] md:text-[3vw] lg:text-[2vw] tracking-wides tab2:text-base text-white card">
                to good your mood
              </h4>
            </div>
            <div className="w-[75%] mt-2 ml-auto mr-4 mobs:w-11/12 tab2:mr-0">
              <img className="object-fill" src={arrow} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductBanner;
