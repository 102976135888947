import React, { useState } from "react";

import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
// import TopBar from "../../components/topbar/Topbar";

import banner from "../../assets/img/productbanner.jpg";
import ProductBanner from "../../layout/productBanner/ProductBanner";
import ProductCategory from "../../components/ProductCategory/ProductCategory";
import Titles from "../../components/titles/Titles";
import halal from "../../assets/img/halal.png";
// import ProductList from "../../components/productList/ProductList";
// import SimpleSlider from "../../components/SimpleSlider";
import useHandleScroll from "../../hooks/useHandleScroll";

const Product = () => {
  const scrollPosition = useHandleScroll();
  document.title = "Products";
  return (
    <div id="products ">
      <div>
        {/* <TopBar isProduct={1}></TopBar> */}
        <Navbar sPosition={scrollPosition}></Navbar>

        <ProductBanner bannerImage={banner} />
        {/* <h2>This is product page</h2> */}
        <div className="hidden py-16">
          <Titles text="Discover"></Titles>
        </div>
        <div className="flex-col items-center justify-center hidden parallax">
          <img
            className="lg:w-[200px] lg:h-[200px] w-[150px] h-[150px] mb-5"
            src={halal}
            alt=""
          />
          <h2 className="p-8 mt-5 text-xl font-bold text-white md:text-2xl lg:p-0 lg:text-3xl card">
            We are proud to deliver products that are of a high standard and
            always safe for consumption.
          </h2>
        </div>
        <ProductCategory></ProductCategory>
        {/* <ProductList /> */}
        {/* <SimpleSlider /> */}
        <Footer></Footer>
      </div>
    </div>
  );
};

export default Product;
