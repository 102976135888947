import React from "react";
import { useContext } from "react";
import { AllContext } from "../../hooks/useContextApi";
import Modal from "../modal/Modal";
import "./ImageCard.scss";

const ImageCard = ({ test }) => {
  const values = useContext(AllContext);
  return (
    <>
      <div
        onClick={() => values.openImgModal()}
        className="relative w-full h-full group"
      >
        <div className="h-full ">
          <img className="w-full h-full" src={test} alt="" />
        </div>

        <div className="absolute bottom-0 z-50 p-2 mobile:h-1/3 h-1/2 bg-black/[.4] text-[#FFF] laptop:visible invisible group-hover:visible transition-all">
          <div className="z-[500]">
            <h2 className="py-[2px] text-base font-semibold text-left laptop:text-sm">
              All Projects
            </h2>
            <p className="text-xs laptop:text-[10px] text-left ">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
            </p>
          </div>
        </div>
      </div>

      {values.openImg && (
        <div className="fixed left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-50 modal-transition">
          <Modal
            open={values.openImg}
            type="image"
            closeFunc={values.closeImgModal}
            imageLink={test}
          ></Modal>
        </div>
      )}
    </>
  );
};

export default ImageCard;
