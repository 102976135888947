import React from "react";
import Titles from "../titles/Titles";
import vegetable from "../../assets/img/vegetable.jpg";
import frozen from "../../assets/img/frozen.jpg";
import fish from "../../assets/img/fish_seafood.jpg";
import snacks from "../../assets/img/snacks_desert.png";
import garments from "../../assets/img/garments.png";
import shrimp from "../../assets/img/shrimp.jpg";
import icon1 from "../../assets/img/icon1.png";
import icon2 from "../../assets/img/icon2.png";
import icon3 from "../../assets/img/icon3.png";
import icon4 from "../../assets/img/icon4.png";
import icon5 from "../../assets/img/icon5.png";
import icon6 from "../../assets/img/icon6.png";

import "./ProductCategory.scss";

const ProductCategory = () => {
  return (
    <div className="w-[70%] desktop:w-[90%] tablet:w-[60%] mobs:w-[90%] mx-auto mb-10">
      <div className="py-10">
        <Titles text="Product Category"></Titles>
      </div>

      {/* mother div where grid applied */}
      <div className="grid gap-8 p-5 text-white tablet:p-0 md:grid-cols-2 lg:grid-cols-3 lg:grid-rows-2">
        {/* category vegetable */}
        <div className="overflow-hidden shadow-2xl rounded-xl">
          <div
            className="flex flex-col items-center justify-center w-full h-[38vh] p-card hover:scale-125 "
            style={{
              backgroundImage: `url(${vegetable})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img className="w-[17%] h-[20%]" src={icon1} alt="" />
            <h2 className="pt-5 text-2xl font-bold card">
              Fresh Vegetables <br />& Fruits
            </h2>
          </div>
        </div>
        {/* category frozen foods */}
        <div className="overflow-hidden shadow-lg rounded-xl">
          <div
            className="flex flex-col items-center justify-center w-full h-[38vh] p-card hover:scale-125"
            style={{
              backgroundImage: `url(${frozen})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img className="w-[17%] h-[20%]" src={icon2} alt="" />
            <h2 className="pt-5 text-2xl font-bold card">Frozen foods</h2>
          </div>
        </div>

        {/* category fish & sea foods */}
        <div className="overflow-hidden shadow-2xl rounded-xl">
          <div
            className="flex flex-col items-center justify-center w-full h-[38vh] p-card hover:scale-125"
            style={{
              backgroundImage: `url(${fish})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img className="w-[17%] h-[20%]" src={icon3} alt="" />
            <h2 className="pt-5 text-2xl font-bold card">Fish & seafoods</h2>
          </div>
        </div>
        {/* snacks & deserts */}
        <div className="overflow-hidden shadow-2xl rounded-xl">
          <div
            className="flex flex-col items-center justify-center w-full h-[38vh] p-card hover:scale-125"
            style={{
              backgroundImage: `url(${snacks})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img className="w-[17%] h-[20%]" src={icon4} alt="" />
            <h2 className="pt-5 text-2xl font-bold card">Snacks & deserts</h2>
          </div>
        </div>
        {/* ready garments */}
        <div className="overflow-hidden shadow-2xl rounded-xl">
          <div
            className="flex flex-col items-center justify-center w-full h-[38vh] p-card hover:scale-125"
            style={{
              backgroundImage: `url(${garments})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img className="w-[17%] h-[20%]" src={icon5} alt="" />
            <h2 className="pt-5 text-2xl font-bold card">Readymade garments</h2>
          </div>
        </div>
        {/* shrimps */}
        <div className="overflow-hidden shadow-2xl rounded-xl">
          <div
            className="flex flex-col items-center justify-center w-full h-[38vh] p-card hover:scale-125"
            style={{
              backgroundImage: `url(${shrimp})`,
              backgroundSize: "100% 100%",
              backgroundRepeat: "no-repeat",
            }}
          >
            <img className="w-[17%] h-[20%]" src={icon6} alt="" />
            <h2 className="pt-5 text-2xl font-bold font card">Shrimp</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCategory;
