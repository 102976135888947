import React from "react";
import efg_logo from "../../assets/img/efglogo.png";
import "./Dropdown.scss";

const Dropdown = (props) => {
  return (
    <div className=' accordion accordion-flush' id='accordionFlushExample'>
      {/* Quick links first col */}
      <div className='bg-[#1A2D40] border border-l-0 border-r-0 border-[#1A2D40] rounded-none accordion-item'>
        <h2 className='mb-0 text-center accordion-header' id='flush-headingOne'>
          <button
            className='relative flex items-center w-full px-5 py-4 text-base text-left text-white transition bg-[#1A2D40] border-0 rounded-none accordion-button collapsed focus:outline-none'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#flush-collapseOne'
            aria-expanded='false'
            aria-controls='flush-collapseOne'
          >
            Quick Links
          </button>
          <hr />
        </h2>
        <div
          id='flush-collapseOne'
          className='border-0 accordion-collapse collapse'
          aria-labelledby='flush-headingOne'
          data-bs-parent='#accordionFlushExample'
        >
          <div className='flex flex-col justify-center p-10 text-left accordion-body'>
            <a className='py-1 text-white' href='#'>
              Brands
            </a>
            <hr className='text-black' />
            <a className='py-1 text-white' href='#'>
              Our Mission
            </a>
            <hr className='text-black' />
            <a className='py-1 text-white' href='#'>
              Our Services
            </a>
            <hr className='text-black' />
            <a className='py-1 text-white' href='#'>
              Awards & Certificates
            </a>
            <hr className='text-black' />
            <a className='py-1 text-white' href='#'>
              News & Articles
            </a>
            <hr className='text-black' />
            <a className='py-1 text-white' href='#'>
              EFG at a glance
            </a>
          </div>
          <hr />
        </div>
      </div>

      {/* Brands 2nd col */}
      <div className='bg-[#1A2D40] border text-left border-l-0 border-r-0 border-gray-200 rounded-none accordion-item'>
        <h2 className='mb-0 accordion-header' id='flush-headingTwo'>
          <button
            className='relative flex items-center w-full px-5 py-4 text-base text-white transition bg-[#1A2D40] border-0 rounded-none accordion-button collapsed focus:outline-none '
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#flush-collapseTwo'
            aria-expanded='false'
            aria-controls='flush-collapseTwo'
          >
            Brands
          </button>
        </h2>
        <div
          id='flush-collapseTwo'
          className='border-0 accordion-collapse collapse'
          aria-labelledby='flush-headingTwo'
          data-bs-parent='#accordionFlushExample'
        >
          <div className='p-10 accordion-body'>
            <div className='flex items-center py-1 justify-left '>
              <div className='mr-2 bg-white rounded-2xl'>
                <img className='px-1' width='50px' src={efg_logo} alt='' />
              </div>
              <a className='text-white' href='#'>
                EFG Fashion
              </a>
            </div>
            <hr />
            <div className='flex items-center py-1 justify-left'>
              <div className='mr-2 bg-white rounded-2xl'>
                <img className='px-1' width='50px' src={efg_logo} alt='' />
              </div>
              <a className='text-white' href='#'>
                Rosemco
              </a>
            </div>
            <hr />
            <div className='flex items-center py-1 justify-left'>
              <div className='mr-2 bg-white rounded-2xl'>
                <img className='px-1' width='50px' src={efg_logo} alt='' />
              </div>
              <a className='text-white' href='#'>
                Al-rohim
              </a>
            </div>
            <hr />
            <div className='flex items-center py-1 justify-left'>
              <div className='mr-2 bg-white rounded-2xl'>
                <img className='px-1' width='50px' src={efg_logo} alt='' />
              </div>
              <a className='text-white' href='#'>
                Zoy
              </a>
            </div>
            <hr />
            <div className='flex items-center py-1 justify-left'>
              <div className='mr-2 bg-white rounded-2xl'>
                <img className='px-1' width='50px' src={efg_logo} alt='' />
              </div>
              <a className='text-white' href='#'>
                masala
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* programme and initiative 3rd column */}
      <div className='bg-[#1A2D40] border text-left border-b-0 border-l-0 border-r-0 border-gray-200 rounded-none accordion-item'>
        <h2 className='mb-0 accordion-header' id='flush-headingThree'>
          <button
            className='relative flex items-center w-full px-5 py-4 text-base text-left text-white transition bg-[#1A2D40] border-0 rounded-none accordion-button collapsed focus:outline-none'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#flush-collapseThree'
            aria-expanded='false'
            aria-controls='flush-collapseThree'
          >
            Programs & Initiative
          </button>
        </h2>
        <div
          id='flush-collapseThree'
          className='accordion-collapse collapse'
          aria-labelledby='flush-headingThree'
          data-bs-parent='#accordionFlushExample'
        >
          <div className='flex flex-col px-5 py-4 text-white accordion-body'>
            <a className='py-1' href='#'>
              Global Impact Initiatives
            </a>
            <hr />
            <a className='py-1' href='#'>
              On-Going Programs & Initiatives
            </a>
            <hr />
            <a className='py-1' href='#'>
              Upcoming Events
            </a>
          </div>
        </div>
      </div>

      {/* About Us last col */}
      <div className='accordion-item border-l-0 border-r-0 rounded-none bg-[#1A2D40] border border-gray-200'>
        <hr />
        <h2 className='mb-0 accordion-header' id='flush-headingFour'>
          <button
            className='accordion-button
            collapsed
            relative
            flex
            items-center
            w-full
            py-4
            px-5
            text-base text-white text-left
            bg-[#1A2D40] border-0
            rounded-none
            transition
            focus:outline-none '
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#flush-collapseFour'
            aria-expanded='false'
            aria-controls='flush-collapseFour'
          >
            About Us
          </button>
        </h2>
        <div
          id='flush-collapseFour'
          className='border-0 accordion-collapse collapse'
          aria-labelledby='flush-headingFour'
          data-bs-parent='#accordionFlushExample'
        >
          <div className='px-5 py-4 accordion-body'>
            <div className='flex flex-col p-5 text-left text-white'>
              <a className='py-1' href='#'>
                About EFG
              </a>
              <hr />
              <a className='py-1' href='#'>
                Goals & Principals
              </a>
              <hr />
              <a className='py-1' href='#'>
                Meet The Team
              </a>
              <hr />
              <a className='py-1' href='#'>
                FAQ
              </a>
              <hr />
              <a className='py-1' href='#'>
                Contact Us
              </a>
              <hr />
            </div>
            {/* efg head office */}
            <div className='flex flex-col leading-5 text-white'>
              <div className='flex items-center justify-center pt-3 pb-2 mobile:flex-col'>
                {/* <img className='w-[30vw]' src={efg_logo} alt='' /> */}
                <div className='pt-1 ml-0'>
                  <h3 className='text-xl font-bold'>Euro Foods Group</h3>
                  <p>Bangladesh Operation</p>
                </div>
              </div>
              <div className='flex flex-col p-10'>
                <h3 className='py-1 font-bold md:text-left'>Head Office:</h3>
                <div className='flex justify-center py-4 md:justify-start'>
                  <svg
                    className='fill-[#fff]'
                    viewBox='0 0 512 512'
                    x='0px'
                    y='0px'
                    width='25px'
                    height='25px'
                  >
                    <title>map-pointer-globe-glyph</title>
                    <path d='M165.15,220h36.33c6.12,16.07,12.07,30.23,22.19,42.13-18.16-5.8-32.31-14.16-44.38-26.06-6.11-4.18-10.12-10-14.14-16.07ZM179.29,84.91h0c12.07-12.23,26.22-22.2,44.38-26.38C213.55,70.75,207.6,84.91,201.48,99H165.15c4-4.18,10.12-10.29,14.14-14.14ZM346.68,99H306.34c-4-14.14-12.07-30.22-20.1-42.44,18.16,6.11,34.4,14.15,46.47,28.31,4,3.85,10.12,10,14,14.14Zm-30.22,56.29h0a198.29,198.29,0,0,0-5.94-48.24h40.2c8.2,14.15,14.31,32.16,14.31,48.24Zm0,8H365c0,18.33-6.11,34.4-14.31,50.49v-1.92h-40.2c4-14.16,5.94-30.23,5.94-48.56Zm-56.59,48.56h0V163.38h48.56c0,18.33-2.09,34.4-6.13,48.56Zm0,8h38.58c-6.11,18-14.3,34.09-24.28,46.31-4,0-10.12,0-14.3,1.92Zm-60.48-56.6H252v48.56H207.6c-4-14.16-6.12-30.23-8.22-48.56Zm-8-8H147c1.93-16.08,6.11-34.09,14.16-48.24h38.25c-4,16.08-8,32.16-8,48.24ZM252,107.1h0v48.24h-52.6c2.09-16.08,4.18-32.16,8.22-48.24ZM252,99H209.53c5.94-16.07,16.23-30.22,26.21-44.38,4,0,10.12-1.93,16.25-1.93Zm50.32,8.05h0a197.63,197.63,0,0,1,6.13,48.24H259.87V107.1ZM259.87,99h0V52.74c4.18,0,10.29,0,14.3,1.93,10,11.9,18.17,28.31,24.28,44.38ZM147,163.38h44.38c0,18.33,4,34.4,8,48.56H161.13c-8.05-14.16-12.23-30.23-14.16-48.56ZM235.74,266.3h0c-10-14.16-20.26-28.31-26.21-46.31H252v48.24c-6.12-1.92-12.23-1.92-16.25-1.92Zm97-30.24a123,123,0,0,1-46.47,28.31c8-14.15,16.07-28.31,20.1-44.38h40.34c-3.85,6.12-10,11.89-14,16.07ZM256,512a401.32,401.32,0,0,0,28.15-48.23c50.65-82.65,133.31-231.89,133.31-304.25C417.45,72.68,344.76,0,256,0S94.55,72.68,94.55,159.52c0,72.36,82.65,221.6,133.16,304.25A397.06,397.06,0,0,0,256,512Z' />
                  </svg>
                  <p className='w-[60%] mobile:w-[100%]'>
                    Navana Oval, 7th Floor, Plot-5, Sonargaon Janapath Road,
                    Sector-07, Uttara, Dhaka
                  </p>
                </div>

                <div className='flex items-center justify-center md:justify-start'>
                  <svg
                    className='fill-[#fff]'
                    x='0px'
                    y='0px'
                    width='25px'
                    height='25px'
                    viewBox='0 0 455 455'
                  >
                    <g>
                      <polygon points='60,134.911 60,380.089 196.194,257.5 	' />
                      <polygon points='318.806,257.5 455,380.089 455,134.911 	' />
                      <polygon points='257.5,272.318 432.579,114.729 82.421,114.729 	' />
                      <polygon points='257.5,312.682 218.615,277.681 82.421,400.271 432.579,400.271 296.385,277.681 	' />
                      <polygon points='395,54.729 0,54.729 0,340.271 30,340.271 30,84.729 395,84.729 	' />
                    </g>
                  </svg>
                  <p className='py-1 pl-3'>info@eurofoods-bd.com</p>
                </div>
                <div className='flex items-center justify-center md:justify-start'>
                  <svg
                    className='fill-[#fff]'
                    x='0px'
                    y='0px'
                    width='25px'
                    height='25px'
                    viewBox='0 0 92 92'
                    enableBackground='new 0 0 92 92'
                  >
                    <path
                      d='M46,0C20.6,0,0,20.6,0,46s20.6,46,46,46s46-20.6,46-46S71.4,0,46,0z M49.7,83.8c-0.2,0-0.4,0-0.7,0.1V62.2
	c5.2-0.1,9.9-0.2,14.2-0.5C59.4,73.4,52.3,81.2,49.7,83.8z M42.3,83.8c-2.7-2.7-9.7-10.5-13.5-22.1c4.2,0.3,9,0.5,14.2,0.5v21.7
	C42.8,83.9,42.6,83.8,42.3,83.8z M8,46c0-2.5,0.3-5,0.7-7.4c2.2-0.4,6.4-1,12.3-1.6c-0.5,2.9-0.8,5.9-0.8,9.1c0,3.2,0.3,6.2,0.7,9
	c-5.8-0.6-10.1-1.2-12.3-1.6C8.3,51,8,48.5,8,46z M26.3,46c0-3.4,0.4-6.6,1-9.6c4.6-0.3,9.8-0.6,15.7-0.6v20.4
	c-5.8-0.1-11.1-0.3-15.8-0.7C26.7,52.6,26.3,49.4,26.3,46z M49.6,8.2c2.7,2.7,9.6,10.7,13.5,22.1c-4.2-0.3-8.9-0.5-14.1-0.5V8.1
	C49.2,8.1,49.4,8.2,49.6,8.2z M43,8.1v21.7c-5.2,0.1-9.9,0.2-14.1,0.5c3.8-11.4,10.8-19.4,13.4-22.1C42.6,8.2,42.8,8.1,43,8.1z
	 M49,56.2V35.8c5.8,0.1,11.1,0.3,15.7,0.6c0.6,3,1,6.2,1,9.6c0,3.4-0.3,6.6-0.9,9.6C60.2,55.9,54.9,56.1,49,56.2z M70.9,37
	c5.9,0.6,10.1,1.2,12.3,1.6C83.7,41,84,43.5,84,46c0,2.5-0.3,5-0.7,7.4c-2.2,0.4-6.4,1-12.3,1.6c0.5-2.9,0.7-5.9,0.7-9.1
	C71.7,42.9,71.4,39.8,70.9,37z M81.4,32.2c-2.8-0.4-6.8-0.9-11.9-1.4c-2.4-8.6-6.6-15.5-10.1-20.4C69.5,14.2,77.5,22.2,81.4,32.2z
	 M32.6,10.4c-3.6,4.8-7.7,11.7-10.1,20.3c-5,0.4-9,1-11.9,1.4C14.5,22.2,22.6,14.2,32.6,10.4z M10.6,59.8c2.8,0.4,6.8,0.9,11.8,1.4
	c2.4,8.6,6.4,15.5,10,20.3C22.4,77.6,14.5,69.7,10.6,59.8z M59.6,81.5c3.6-4.8,7.6-11.6,10-20.2c5-0.4,9-1,11.8-1.4
	C77.5,69.7,69.6,77.6,59.6,81.5z'
                    />
                  </svg>
                  <p>
                    <a className='py-1 pl-2' href='#'>
                      www.eurofoods-bd.com
                    </a>
                  </p>
                </div>
                <div className='flex items-center justify-center md:justify-start'>
                  <svg
                    className='fill-[#fff]'
                    x='0px'
                    y='0px'
                    width='25px'
                    height='25px'
                    viewBox='0 0 31.161 31.161'
                  >
                    <g>
                      <path
                        d='M12.024,7.431c-1.267,0.08-2.385,0.229-3.357,0.451C7.698,8.104,7.015,8.425,6.616,8.851
		c0.063,0.266,0.096,0.709,0.096,1.328v0.61H0C0.244,8.841,0.752,7.355,1.523,6.33c0.731-0.956,1.736-1.584,3.008-1.885
		c1.273-0.301,2.141-0.496,2.604-0.584C8.188,3.667,9.311,3.529,10.5,3.449c1.188-0.08,2.889-0.12,5.1-0.12
		c3.251,0,5.537,0.066,6.846,0.2c1.313,0.133,2.674,0.42,4.088,0.862c1.414,0.443,2.461,1.116,3.146,2.018
		c0.746,0.992,1.239,2.453,1.481,4.38h-6.61v-0.318c0-0.743,0.022-1.282,0.075-1.62c-0.41-0.442-1.116-0.773-2.123-0.995
		c-1.002-0.221-2.065-0.367-3.198-0.438c-1.134-0.07-2.363-0.106-3.703-0.106C14.481,7.31,13.289,7.351,12.024,7.431z M6.711,11.292
		v1.143c0,0.548-0.271,0.823-0.811,0.823H0.672c-0.141,0-0.286-0.089-0.442-0.266C0.076,12.814,0,12.628,0,12.435v-1.143H6.711z
		 M2.604,26.213v-5.361c0-0.584,0.076-1.078,0.229-1.475c0.156-0.398,0.412-0.828,0.771-1.289l5.806-7.884V8.532h2.506l0.117,1.673
		h6.98V8.532h2.834v1.805l5.996,7.752c0.45,0.551,0.729,0.982,0.838,1.303c0.11,0.316,0.166,0.806,0.166,1.461v5.361
		c0,1.08-0.438,1.619-1.313,1.619H3.797C3.002,27.833,2.604,27.293,2.604,26.213z M20.085,17.346
		c1.252-0.602,1.879-1.326,1.879-2.177c0-0.833-0.629-1.544-1.889-2.137c-1.261-0.593-2.752-0.889-4.475-0.889
		c-1.748,0-3.252,0.301-4.513,0.902c-1.26,0.602-1.891,1.311-1.891,2.124c0,0.851,0.627,1.576,1.881,2.177
		c1.252,0.604,2.762,0.902,4.521,0.902C17.337,18.249,18.831,17.948,20.085,17.346z M31.161,12.435c0,0.212-0.062,0.402-0.183,0.57
		c-0.123,0.168-0.254,0.253-0.396,0.253h-5.342c-0.166,0-0.324-0.076-0.472-0.226c-0.147-0.15-0.225-0.349-0.225-0.597v-1.144h6.613
		v1.143L31.161,12.435L31.161,12.435z'
                      />
                    </g>
                  </svg>
                  <p className='py-1 pl-3'>+880248955205</p>
                </div>
                <div className='flex items-center justify-center md:justify-start'>
                  <svg
                    className='fill-[#fff]'
                    x='0px'
                    y='0px'
                    viewBox='0 0 227.52 227.52'
                    width='25px'
                    height='25px'
                  >
                    <path
                      d='M84,4.725H0v218.07h84v-9.035h143.52v-200H84V4.725z M64,202.795H20V24.725h44V202.795z M86.494,33.76H207.52v160H86.494
	V33.76z M190.007,78.094h-90v-20h90V78.094z M100.007,99.76h20v15.25h-20V99.76z M136.007,99.76h20v15.25h-20V99.76z M172.007,99.76
	h20v15.25h-20V99.76z M100.007,123.76h20v15.25h-20V123.76z M136.007,123.76h20v15.25h-20V123.76z M172.007,123.76h20v15.25h-20
	V123.76z M100.007,147.76h20v15.25h-20V147.76z M136.007,147.76h20v15.25h-20V147.76z M172.007,147.76h20v15.25h-20V147.76z'
                    />
                  </svg>
                  <p className='py-1 pl-3'>+880248955206</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
