import { useCallback, useEffect, useState } from "react";

// import TopBar from "../../components/topbar/Topbar";
// import Navbar from "../../components/navbar/Navbar";
import "./banner.scss";
import BannerContent from "../../components/bannerContent/bannerContent";

import fish from "../../assets/img/fish-1.png";
import gif1 from "../../assets/gif/ezgif-7.gif";
import gif2 from "../../assets/gif/ezgif-2.gif";
import gif3 from "../../assets/gif/ezgif-8.gif";

import efgt_short from "../../assets/img/efgt1.jpg";
import efgt_big from "../../assets/img/tech_big_image.jpg";

import eurasia1 from "../../assets/img/eurasia-1.png";
import eurasia2 from "../../assets/img/eurasia-2.png";
import eurasia3 from "../../assets/img/eurasia-3.png";

import rosemco1 from "../../assets/img/rosemco-1.jpg";

import saidowla1 from "../../assets/img/saidowla-1.jpg";

import masalabazaar1 from "../../assets/img/masalabazaar-1.jpg";

import efgf1 from "../../assets/img/efgf-1.jpg";
import efgf2 from "../../assets/img/efgf-2.jpg";

const Banner = ({ state }) => {
  const [content, setContent] = useState(1);

  const [stopTimer, setStopTimer] = useState(false);

  // let stopTimer = false;

  // const print = useCallback((num) => {
  //   console.log(num);
  //   setContent(num);
  // }, []);

  // useEffect(() => {
  //   let timer1;
  //   if (stopTimer === false) {
  //     timer1 = setTimeout(() => {
  //       if (content === 8) {
  //         setContent(1);
  //       } else {
  //         setContent(content + 1);
  //       }
  //     }, 3500);
  //     setStopTimer(false);
  //   } else if (stopTimer === true) {
  //     clearTimeout(timer1);
  //     setStopTimer(true);
  //   }

  //   // setStopTimer(true);

  //   return () => {
  //     clearInterval(timer1);
  //   };
  // }, [content, stopTimer, print]);

  useEffect(() => {
    setContent(state);

    return () => {};
  }, [state]);

  return (
    <>
      <div
        onMouseEnter={() => {
          // console.log("enter");
          setStopTimer(true);
        }}
        onMouseLeave={() => {
          // console.log("leave");
          setStopTimer(false);
        }}
        // className="h-[770px] w-full overflow-x-hidden tab2:h-max"
        // style={{
        //   backgroundImage: `url(${bannerImage})`,
        // }}
      >
        {content === 1 && (
          <BannerContent
            // func={print}
            leftImg={fish}
            leftGif={gif1}
            rightGif="https://i.ibb.co/yXh2xZ7/Main-brochure-Design-02.png"
            proPerD="500"
            proVar="9"
            vehicle="30"
            pUnits="13"
            emp="1000"
            state={content}
            groupsName="EURO FOODS"
            ltInfo={"Sister Concerns"}
            lbInfo={"Own Product Variant"}
            ltSign={"+"}
            lbSign={"+"}
            rtInfo={"Vehicles"}
            rmInfo={"Projects"}
            rbInfo={"Employees"}
            rtSign={"+"}
            rmSign={"+"}
            rbSign={"+"}
            groupsDesc="Euro Foods has become a leading International manufacturer, exporter and distributor of frozen and fresh foods, serving the restaurant,
          catering and specialist supermarket sectors."
          />
        )}
        {content === 2 && (
          <BannerContent
            // func={print}
            leftImg={eurasia3}
            leftGif={eurasia2}
            rightGif={eurasia1}
            proPerD="2500"
            proVar="700"
            vehicle="25"
            pUnits="5"
            emp="95"
            state={content}
            groupsName="EURASIA"
            ltInfo={"Product Variant"}
            lbInfo={"Tons Export Per Year"}
            ltSign={"+"}
            lbSign={"+"}
            rtInfo={"Vehicles"}
            rmInfo={"Processing Units"}
            rbInfo={"Exports"}
            rtSign={"+"}
            rmSign={"+"}
            rbSign={"%"}
            groupsDesc="EURASIA FOOD PROCESSING (BD) LTD is a joint venture of the UK and Bangladesh. EURASIA manufacture branded generic products for local and overseas markets. EURASIA adopting the most recent technology to formulate and produce generic formulations ensuring optimum quality."
          />
        )}
        {content === 3 && (
          <BannerContent
            // func={print}
            leftImg={""}
            leftGif={gif3}
            rightGif={rosemco1}
            proPerD="2200"
            proVar="100"
            vehicle="3"
            pUnits="9"
            emp="29"
            state={content}
            groupsName="ROSEMCO"
            ltInfo={"Export Oriented"}
            lbInfo={"Metric Tons Exported in a Year"}
            ltSign={"%"}
            lbSign={"+"}
            rtInfo={"Certificates & US FDA registered"}
            rmInfo={"Production Segment"}
            rbInfo={"product Variant"}
            rtSign={""}
            rmSign={""}
            rbSign={"+"}
            groupsDesc="Rosemco Foods Limited is a 100% export-oriented fish processing unit of Euro Foods Group. Rosemco's key segment is processing Block frozen shrimp, IQF shrimp, Semi IQF shrimp, Frozen Cook shrimp, IQF Marinated product and Chill fish processing and the targeted markets are the UK, USA, Canada, Australia, South Korea and Italy."
          />
        )}
        {content === 4 && (
          <BannerContent
            // func={print}
            leftImg={fish}
            leftGif={gif1}
            rightGif={gif2}
            proPerD="100"
            proVar="18"
            vehicle=""
            pUnits="200"
            emp=""
            state={content}
            groupsName="EFG AQUA"
            ltInfo={"Ponds"}
            lbInfo={"Prodcution Per Day"}
            ltSign={"+"}
            lbSign={"+"}
            rtInfo={""}
            rmInfo={"Targeted Future Ponds"}
            rbInfo={""}
            rtSign={""}
            rmSign={"+"}
            rbSign={""}
            groupsDesc="EFG Aqua is a new shrimp farming unit of Euro foods Group from 2021. Shrimp farming plays an essential role in Bangladeshi livelihoods, food security, and international trade. So, EFG enters this industry to get maximum output from natural resources using minimal environmental impact"
          />
        )}
        {content === 5 && (
          <BannerContent
            // func={print}
            leftImg={fish}
            leftGif={gif1}
            rightGif={saidowla1}
            proPerD="3"
            proVar="99"
            vehicle="2"
            pUnits="350"
            emp="120"
            state={content}
            groupsName="SAIDOWLA"
            ltInfo={"Export"}
            lbInfo={"Licenses(FIQC, US FDA, HACCP)"}
            ltSign={"%"}
            lbSign={"+"}
            rtInfo={"Category Product Variant(BF, IQF)"}
            rmInfo={"Tons Exports in a Year"}
            rbInfo={"Individual Local SKU"}
            rtSign={"+"}
            rmSign={"+"}
            rbSign={"+"}
            groupsDesc="Saidowla (Pvt.) Enterprise Limited is a 99% export-oriented Fish & Shrimps processing unit of Euro foods Group. Saidowla's key products are white water fish and prawns from local water bodies. Their processing units design and construction are approved by Ministry of Fisheries and Livestock (BD)."
          />
        )}
        {content === 6 && (
          <BannerContent
            // func={print}
            leftGif={efgf1}
            rightGif={efgf2}
            proPerD="20"
            proVar="1"
            vehicle="100"
            pUnits="10"
            emp="20000"
            state={content}
            groupsName="EFG FASHION"
            ltInfo={"Custom Online Tailoring & Printing"}
            lbInfo={"Product Variant"}
            ltSign={"st"}
            lbSign={"+"}
            rtInfo={"Sewing Machine"}
            rmInfo={"Renowned Clients"}
            rbInfo={"T-Shirt Production Per Month"}
            rtSign={"+"}
            rmSign={"+"}
            rbSign={"+"}
            groupsDesc="In 2021 Euro foods Group enters in fashion industry by EFG Fashion. EFG Fashion was formed with an aim to provide an ever green solution to your closet desires and always ensure its unique style and variety of collections. We renowned for our unique style and variety of collections."
          />
        )}
        {content === 7 && (
          <BannerContent
            // func={print}
            leftImg={fish}
            leftGif={gif1}
            rightGif={masalabazaar1}
            proPerD=""
            proVar="15000"
            vehicle="70"
            pUnits="2"
            emp="100"
            state={content}
            groupsName="MASALABAZAAR"
            ltInfo={"Product Variant"}
            lbInfo={"8 a.m - 11 p.m Retail Operation "}
            ltSign={"+"}
            lbSign={"+"}
            rtInfo={"Product Category"}
            rmInfo={"Retail Store"}
            rbInfo={"Employees"}
            rtSign={"+"}
            rmSign={"+"}
            rbSign={"+"}
            groupsDesc="Masala Bazaar, a concern of Euro Foods Group, a Complete Store For Your Family, a full Phage super store in Dhaka and Sylhet. You can choice from here Food and Non-food and Perishable Items from us. We can offer the best products to our consumer without compromising the quality."
          />
        )}
        {content === 8 && (
          <BannerContent
            // https://i.ibb.co/JkrMr0n/efg2.jpg
            // func={print}
            leftImg=""
            leftGif={efgt_short}
            rightGif={efgt_big}
            proPerD="5"
            proVar="15"
            vehicle="7"
            pUnits="7"
            emp="15"
            state={content}
            groupsName="EFG TECH"
            ltInfo={"Running Projects"}
            lbInfo={"Served Projects"}
            ltSign={"+"}
            lbSign={"+"}
            rtInfo={"Local & International Clients"}
            rmInfo={"Technology Stacks"}
            rbInfo={"Employees"}
            rtSign={"+"}
            rmSign={"+"}
            rbSign={"+"}
            groupsDesc="EFG Tech is a promising ICT start-up that began its journey in 2021 with a vision to create a difference in the ICT sector by providing cutting-edge technology solutions for the domestic and international markets."
          />
        )}
      </div>
    </>
  );
};

export default Banner;
