import React from "react";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/Topbar";
import useHandleScroll from "../../hooks/useHandleScroll";
import banner from "../../assets/img/message_banner.jpg";
import chairmansPic from "../../assets/img/chairman-message.png";
// import Titles from "../../components/titles/Titles";
import Footer from "../../components/footer/Footer";

const Message = () => {
  const scrollPosition = useHandleScroll();
  return (
    <>
      <TopBar></TopBar>
      <Navbar sPosition={scrollPosition}></Navbar>
      <div>
        {/* banner here */}
        <div className="relative w-full h-[450px] mobs:hidden">
          <img className="object-cover w-full h-full " src={banner} alt="" />
          <div className="absolute left-1/2 top-2/4">
            <div className="relative left-[-50%]">
              <h2 className="mx-2 mb-1 text-5xl font-normal text-white mobs:text-lg tab2:text-4xl desktop:text-5xl mobile:text-3xl">
                Message Form Our
              </h2>
              <h2 className="inline-block mx-2 mb-1 text-5xl font-normal text-white mobs:text-lg tab2:text-3xl mobile:text-3xl">
                Chairman
                <hr className="py-[2px] bg-white" />
              </h2>
            </div>
          </div>
        </div>
        {/* chairmans message with pic */}
        <section className="wide:w-[88%] w-[60%] mx-auto mt-16 mobs:mt-0">
          {/* title for moboile */}
          <div className="hidden mobs:block">
            <h2 className="pt-24 mx-2 mb-4 text-2xl font-bold">
              Message Form Our
            </h2>
            <h2 className="inline-block mx-2 mb-4 text-2xl font-bold">
              Chairman
              <hr className="py-[1px] bg-black" />
            </h2>
          </div>
          {/* chairmans pic */}
          <div className="grid items-center grid-cols-12 gap-1 justify-items-center">
            <div className="col-span-7 mobile:col-span-6 mobs:col-span-12">
              <img className="object-cover " src={chairmansPic} alt="" />
            </div>
            <div className="col-span-5 text-center mobile:col-span-6 mobs:col-span-12 mobs:text-lg mobs:mt-1">
              <h3 className="text-4xl font-bold laptop:text-2xl mobile:text-xl ">
                Shelim Hussain MBE
              </h3>
              <p className="text-2xl font-medium laptop:text-xl mobile:text-lg ">
                Chairman
              </p>
              <p className="tracking-widest">EURO FOODS GROUP</p>
            </div>
          </div>
          {/* articles here */}

          <article className="my-8 mobs:mt-4">
            <p className="py-4 text-lg leading-10 text-justify pb-7">
              Success of Euro Foods Group, Bangladesh stems from our reliance on
              both experience and knowledge. In our past 21 years of service, we
              continued to strive for quality and efficient service for
              consumers, both domestic and overseas. The digitalization that has
              been integral to our transformation in these past years has
              allowed us to adopt and pivot seamlessly to the new world. The
              group has achieved many recognitions of national and international
              importance for its compliance to regulatory guidelines, quality
              and best practices. <br />
              <br /> We envision a global industry where our product captures
              the satisfaction and aspire of being an international leader in
              food safety, quality assurance and sustainable food production.{" "}
              <br />
              <br /> I am very proud and delighted that, Euro Foods Group,
              Bangladesh has shown impressive and sustainable growth in the last
              decade. I would like to express my personal gratitude to all our
              partners, business associates and customers who have shown full
              confidence in us. It is the trust placed in us by all partners
              that strengthens and inspires us at every step of the way.
            </p>
          </article>
        </section>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Message;
