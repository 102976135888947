import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

import AOS from "aos";
import "aos/dist/aos.css";

// import Button from "../../components/button/Button";
import map from "../../assets/img/map-pic-update.png";
import Titles from "../../components/titles/Titles";
import "./feedback.scss";
import useCookie from "../../hooks/useCookie";
import { AllContext } from "../../hooks/useContextApi";
import Loader from "../../components/loader/Loader";

AOS.init();
const Feedback = ({ type }) => {
  const [formData, setFormData] = useState(null);
  const { setCookie, getCookie } = useCookie();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const values = React.useContext(AllContext);

  const onSubmit = (data, e) => {
    e.preventDefault();

    if (getCookie("email")) {
      alert("Your mail has been send already. wait 10 mins to resend again");
      e.target.reset();
      return;
    }

    setFormData({ ...data });
    values.setLoader(true);
    if (Object.keys(data).length !== 0) {
      emailjs
        .send(
          process.env.REACT_APP_SERVICE_ID,
          process.env.REACT_APP_TEMPLATE_ID,
          data,
          process.env.REACT_APP_PUBLIC_KEY
        )
        .then(
          (result) => {
            if (result) {
              alert("Email Sent Successfully. Thank You.");
              setCookie("email", "send", 10);
              values.setLoader(false);
              console.log(getCookie("email"));
              console.log(result.text);
            }
          },
          (error) => {
            console.log(error.text);
          }
        )
        .finally(() => {
          e.target.reset();
          // data = null;
          setFormData(null);
          window.location.reload();
        });
    } else {
      alert("You can not leave any field empty");
    }
  };
  // console.log(formData);

  return (
    <>
      <Titles text="Get In Touch" />
      <div className="relative grid items-center grid-cols-12 p-6 mx-40 mt-8 mb-10 text-black rounded-lg mobile:mt-0 mobile:pt-0 justify-items-center tablet:flex-wrap wide:mx-20 mobile:mx-4">
        {/* map */}
        <div className="col-span-6 text-left laptop:col-span-12 tablet:basis-full tablet:mx-auto laptop:w-[95%] 2xl:w-[80%]">
          {type === "home" && (
            <div>
              <h2 className="mb-4 text-xl font-bold mobile:text-sm ">
                Find Us
              </h2>
              <p className="text-base tablet:w-full mobile:text-xs">
                Euro Foods Group - Bangladesh Operations, Head Office 7th Floor
                (Navana Oval, Plot - 5 Sonargaon Janapath, Dhaka 1230)
              </p>
            </div>
          )}
          <div
            // data-aos='fade-up'
            // data-aos-delay='50'
            // data-aos-duration='1000'
            // data-aos-easing='ease-out'
            // data-aos-mirror='true'
            // // data-aos-offset='100'
            // // data-aos-anchor-placement='top-center'
            // xs={4}
            className="my-10 mobile:mt-5 mobile:mb-0 max-h-fit tablet:px-4"
          >
            <img src={map} alt="map" className="" />
          </div>
        </div>

        {/* vertical line */}
        <div className="w-[2px] my-40 mt-48 hidden bg-black h-96 desktop:h-64 tablet:hidden"></div>

        {/* message */}
        <div className="col-span-6 ml-6 text-left laptop:col-span-12 tablet:basis-full tablet:mx-auto 2xl:basis-2/3">
          <h2 className="hidden mb-4 text-xl font-bold mobile:text-sm">
            Become a partner ?
          </h2>
          <p className=" laptop:w-[95%] 2xl:w-[95%] mobile:text-xs">
            Fill in the contact form below and one of our advisers will be in
            touch shortly. <br />
            <span className="text-red-500 ">
              (Note: Please do not submit any job application here. If found,
              they will be considered disqualified.)
            </span>
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col w-11/12 mobile:w-[100%] mobile:mx-auto mt-10 message-section max-h-fit tablet:ml-5 tablet:items-center">
              {/* root of name and number field */}
              <div className="grid w-full grid-cols-12">
                {/* name field */}
                <div className="relative col-span-6 mr-4 mobs:mr-0 mobs:col-span-12 ">
                  <input
                    {...register("name", { required: true })}
                    type="text"
                    placeholder="Name"
                    className="w-full mobile:text-sm"
                  />
                  {errors.name && (
                    <p className="absolute bottom-0 text-[10px] font-bold text-red-600 ">
                      *Name Required
                    </p>
                  )}
                </div>

                {/* number field */}
                <div className="relative w-full col-span-6 mobs:col-span-12">
                  <input
                    {...register(
                      "number",
                      { required: true },
                      { min: 11, max: 11 }
                    )}
                    type="number"
                    placeholder="Contact Number"
                    className="w-full border-2 mobile:text-sm"
                  />
                  {errors.number && (
                    <p className="absolute bottom-0 text-[10px] font-bold text-red-600 ">
                      *Phone Number Required
                    </p>
                  )}
                </div>
              </div>
              {/* root of email and subject field */}
              <div className="grid w-full grid-cols-12">
                {/* email field */}
                <div className="relative col-span-6 mr-4 mobs:mr-0 mobs:col-span-12">
                  <input
                    {...register("email", {
                      required: true,
                      pattern:
                        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                    })}
                    type="text"
                    placeholder="Email"
                    className="mobile:text-sm"
                  />
                  {errors.email && (
                    <p className="absolute bottom-0 text-[10px] font-bold text-red-600 ">
                      *An Authentic Email Required
                    </p>
                  )}
                </div>
                {/* subject field */}
                <div className="relative w-full col-span-6 mr-4 mobs:mr-0 mobs:col-span-12">
                  <input
                    {...register("subject")}
                    type="text"
                    className="mobile:text-sm"
                    placeholder="Subject(optional)"
                  />
                </div>
              </div>
              {/* message field */}
              <div className="relative w-full">
                <textarea
                  {...register("message", { required: true })}
                  type="text"
                  id=""
                  cols="30"
                  rows="4"
                  className=" mobile:text-sm"
                  placeholder="Message"
                />
                {errors.message && (
                  <p className="absolute bottom-0 text-[10px] font-bold text-red-600">
                    *Message Required
                  </p>
                )}
              </div>
              {/* <Button
              isButton={1}
              onClick={() => submitMail()}
              classes="px-28 py-3 mt-4 mobile:text-sm text-lg font-medium w-full rounded-md desktop:px-8 bg-[#FFCD72]"
              text="SEND MESSAGE"
            /> */}
              <input
                type="submit"
                value="SEND MESSAGE"
                className="px-28 py-3 mt-4 mobile:text-sm text-lg font-medium w-full rounded-md desktop:px-8 bg-[#FFCD72]"
              ></input>
            </div>
          </form>
          {/* <button className="px-28 py-3 mt-4 mobile:text-sm text-lg font-medium w-full rounded-md desktop:px-8 bg-[#FFCD72]">
            TRY LOCAL STORAGE
          </button> */}
        </div>

        {values.loader && <Loader></Loader>}
      </div>
    </>
  );
};

export default Feedback;
