import React from "react";

const ProjectDots = ({ dot, line, year, yearClass }) => {
  return (
    <div className="">
      <div
        className={`absolute flex items-center overflow-x-visible  w-16 h-16 tab2:w-10 tab2:h-10 -translate-x-1/2 bg-green-900 rounded-full left-1/2 ${dot}`}
      >
        <div className="relative ">
          <div className={` absolute w-12  h-1 bg-[#3f3f3f] ${line}`}></div>
          <h2 className={`absolute -translate-y-[40%] top-1/2 ${yearClass}`}>
            {year}
          </h2>
        </div>
      </div>
    </div>
  );
};

export default ProjectDots;
