const useCookie = () => {
  function setCookie(name, val, expireMinutes) {
    const date = new Date();
    const value = val;

    if (expireMinutes) {
      date.setTime(date.getTime() + expireMinutes * 60 * 1000);
    } else {
      // Set it expire in 7 days
      date.setTime(date.getTime() + 1 * 24 * 60 * 60 * 1000);
    }

    // Set it
    document.cookie =
      name + "=" + value + "; expires=" + date.toUTCString() + "; path=/";
  }

  function getCookie(name) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts && parts.length === 2) {
      return parts.pop().split(";").shift();
    } else {
      return null;
    }
  }

  function deleteCookie(name) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);

    // Set it
    document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
  }

  function deleteAllCookies() {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
  }

  return {
    setCookie,
    getCookie,
    deleteCookie,
    deleteAllCookies,
  };
};

export default useCookie;
