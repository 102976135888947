import { Container } from "postcss";
import React from "react";
import "./card.scss";
const Card = ({ children, type, text, title, classes, image, func, state }) => {
  // console.log(image);
  if (type === "default") {
    return (
      <div
        className={`${classes} card shadow-lg bg-no-repeat rounded-3xl`}
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="flex flex-col items-center justify-center mt-10 card-body">
          {children}
        </div>
      </div>
    );
  }
  if (type === "heads") {
    return (
      <div className={`${classes} card shadow-md rounded-3xl `}>
        <div className=" h-[250px]">
          <img
            className="object-contain w-full h-full rounded-t-3xl"
            src={image}
            alt=""
          />
        </div>
        <div className="flex flex-col items-center justify-center p-1  card-body h-[140px]">
          {children}
        </div>
      </div>
    );
  }
  if (type === "textOnly") {
    return (
      <div className={`${classes} card`}>
        <div className="card-body">
          {children}
          <h4 className="card-title">{title}</h4>
          {text}
        </div>
        {/* {console.log(func)}
        {func && state && (
          <button
            onClick={() => {
              func(state + 1);
            }}
          >
            Eurasia
          </button>
        )} */}
      </div>
    );
  }
  if (type === "newsOnly") {
    return (
      <div
        className={`${classes} card shadow-xl bg-no-repeat `}
        style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
      >
        {children}
      </div>
    );
  }
};

export default Card;
