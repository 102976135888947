import React, { useEffect, useState } from "react";
import Navbar from "../navbar/Navbar";
import TopBar from "../topbar/Topbar";
import map_logo from "../../assets/icons/map-logo.svg";
import location_logo from "../../assets/icons/location-logo.svg";
import established_logo from "../../assets/icons/established.svg";
import employee_logo from "../../assets/icons/employee.svg";

import insta from "../../assets/icons/Instagram.svg";
import fb from "../../assets/icons/Facebook.svg";
import tweet from "../../assets/icons/Twitter.svg";
import Titles from "../titles/Titles";
import check from "../../../src/assets/icons/check-circle.svg";
import Footer from "../footer/Footer";

import useHandleScroll from "../../hooks/useHandleScroll";
import Banner from "../../layout/banner/Banner";

import dotGif from "../../assets/gif/dot.gif";

import { data, helpCard } from "../../assets/database";

// import { ChevronMap, ChevronDoubleRightIcon } from "@heroicons/react/solid";

import "./CompanyPage.scss";

const CompanyPage = ({ pathname }) => {
  const size = useHandleScroll();

  const [title, setTitle] = useState("Default Title");

  const company = data.filter((item) => item.pathname === pathname);

  console.log(company[0].pathname === "/efgf");
  // console.log(company[0]?.certifications);

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = company[0].title;
  }, [company]);

  return (
    <div>
      <TopBar></TopBar>
      <Navbar sPosition={size}></Navbar>
      <Banner state={company[0].state}></Banner>
      {/* top banner */}
      <div className="relative hidden w-full pt-14">
        <div className="col-span-2 bg-motto absolute top-[43%] w-[60%] tablet:top-10 p-12 mobile:p-2">
          <h1
            className={
              company[0].motto === "company motto"
                ? "text-5xl font-semibold desktop:text-4xl mobile:whitespace-normal whitespace-nowrap laptop:text-xl p-6"
                : "text-5xl font-semibold desktop:text-4xl whitespace-nowrap laptop:text-xl"
            }
          >
            {company[0].title}
          </h1>
          <h4
            className={
              company[0].motto === "company motto"
                ? "hidden"
                : "block pt-3 text-3xl font-light text-left"
            }
          >
            {company[0].motto === "company motto" ? "hidden" : "block"}
          </h4>
        </div>
        <div className="flex justify-end w-full col-span-3">
          <img
            className="w-[70%] tablet:w-[100%] laptop:h-[600px] desktop:h-[675px] h-[750px] mobile:h-[225px]"
            src={company[0].banner}
            alt=""
          />
        </div>
      </div>
      <div className="pt-16 ">
        <div className="wide:w-[88%] w-[73%] mx-auto">
          {company[0].pathname === "/efgf" ||
          company[0].pathname === "/efgt" ||
          company[0].pathname === "/efgaqua" ? (
            <Titles
              text={`About ${company[0].title.split(" ")[0]} ${
                company[0].title.split(" ")[1]
              }`}
            ></Titles>
          ) : (
            <Titles text={`About ${company[0].title.split(" ")[0]}`}></Titles>
          )}

          {/* <h1 className="text-3xl font-bold text-left tablet:text-lg">
            About Us
          </h1> */}
          <p className="text-justify tablet:text-md mobile:text-xs">
            {company[0]?.about}
          </p>
          {/* cards here */}
          <div className="grid items-center grid-cols-4 pt-16 tablet:grid-cols-2 tablet:gap-4 justify-items-center">
            {/* card1 */}
            <div
              className=" wide:w-[260px] border-2 border-[#3f3f3f] tablet:w-full laptop:w-[162px] desktop:w-[210px] w-[220px] h-[161px] rounded-2xl  flex flex-col justify-center items-center"
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div className="flex">
                <img className="mr-3 w-7" src={established_logo} alt="" />
                <p className="text-2xl font-semibold wide:text-lg ">
                  Established
                </p>
              </div>
              <p className="text-lg wide:text-xl tablet:text-base">
                {company[0]?.established}
              </p>
            </div>
            {/* card2 */}
            <div
              className=" border-2 border-[#3f3f3f] wide:w-[260px] tablet:w-full laptop:w-[162px] desktop:w-[210px] w-[220px] h-[161px] rounded-2xl  flex flex-col justify-center items-center "
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div className="flex">
                <img className="mr-3 w-7" src={location_logo} alt="" />
                <p className="text-2xl font-semibold wide:text-lg ">Location</p>
              </div>
              <p className="text-lg wide:text-xl tablet:text-base">
                {company[0]?.location}
              </p>
            </div>
            {/* card3 */}
            <div
              className=" border-2 border-[#3f3f3f] wide:w-[260px] tablet:w-full laptop:w-[162px] desktop:w-[210px] w-[220px] h-[161px] rounded-2xl  flex flex-col justify-center items-center "
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div className="flex ">
                <img className="mr-3 w-7" src={map_logo} alt="" />
                <p className="text-2xl font-semibold wide:text-lg ">Area</p>
              </div>
              <p className="text-lg wide:text-xl tablet:text-base">
                {company[0]?.area}
              </p>
            </div>
            {/* card4 */}
            <div
              className="border-2 border-[#3f3f3f] wide:w-[260px] tablet:w-full laptop:w-[162px] desktop:w-[210px] w-[220px] h-[161px] rounded-2xl  flex flex-col justify-center items-center "
              style={{ backgroundColor: "#FAFAFA" }}
            >
              <div className="flex justify-evenly ">
                <img className="mr-3 w-7" src={employee_logo} alt="" />
                <p className="text-2xl font-semibold wide:text-lg ">Employee</p>
              </div>
              <p className="text-lg wide:text-xl tablet:text-base">
                {company[0]?.employee}
              </p>
            </div>
          </div>
        </div>

        {/* our certificates here */}

        {company[0]?.keyEvents.map((item) => (
          <div
            key={item.state}
            style={{}}
            className="mt-36 mb-12 tablet:mt-24 grid grid-cols-12 wide:w-[88%] w-[73%] mx-auto"
          >
            <div className="relative col-span-3 h-min mobs:col-span-11 laptop:col-span-7 mobile:col-span-10">
              {/* <img
              className=" laptop:h-[250px] h-full w-full"
              src={shape}
              alt=""
            /> */}
              {/* <div className="laptop:h-[200px] h-full w-full"></div> */}
              <div className="w-full h-full laptop:h-min">
                <h3 className="pt-4 pb-1 text-2xl font-bold text-left text-black mobile:pt-2">
                  {item?.keyEvents?.title}
                </h3>
                {item?.keyEvents?.events.map((data) => (
                  <div key={data} className="flex items-center">
                    <img className="w-6 h-6 " src={check} alt="" />
                    <p className="w-56 py-2 pl-2 text-sm text-justify text-black h-min laptop:w-11/12 desktop:w-48 xl:w-10/12">
                      {data} <br />
                    </p>
                  </div>
                ))}
              </div>
            </div>
            {/* cards */}
            <div className="flex flex-col col-span-9 mx-auto laptop:mt-2 laptop:col-span-12">
              <div className="grid h-full grid-cols-3 gap-1 mobile:grid-cols-1 mobile:w-[88%] mx-auto">
                {item.cards.map((info) => (
                  <div
                    className="bg-[#FFFFFF] mobile:p-6 p-4 m-4 drop-shadow-md rounded-[20px]"
                    key={info.details}
                  >
                    <img className=" h-11 w-11" src={dotGif} alt="" />
                    <h4 className="py-4 text-xl font-bold text-left ">
                      {info.title}
                    </h4>
                    <p className="text-sm text-justify">{info.details}</p>
                  </div>
                ))}
              </div>

              <div
                className={`flex items-center w-1/2 py-6 mx-auto ${
                  company[0]?.certifications.length === 1
                    ? "justify-center"
                    : "justify-between"
                } `}
              >
                {company[0]?.certifications.map((item) => (
                  <div key={item}>
                    <img className="w-24 h-22 " src={item} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}

        {/* <div
          className={company[0]?.certifications.length > 0 ? "block" : "hidden"}
        >
          <h1 className="text-4xl font-bold tablet:text-lg pt-36 pb-11 tablet:pt-24">
            Our Certificates
          </h1>
          <div
            className={
              company[0]?.certifications.length === 1
                ? "flex items-center justify-center"
                : "flex items-center justify-evenly"
            }
          >
            
            <div>
              <img
                className="w-32 tablet:w-24 mobile:w-16"
                src={company[0]?.certifications[0]?.img_1}
                alt=""
              />
            </div>
            
            <div
              className={
                company[0]?.certifications[1]?.img_2 === undefined
                  ? "hidden"
                  : "block"
              }
            >
              <img
                className="w-32 tablet:w-24 mobile:w-16"
                src={company[0]?.certifications[1]?.img_2}
                alt=""
              />
            </div>
            
            <div
              className={
                company[0]?.certifications[2]?.img_3 === undefined
                  ? "hidden"
                  : "block"
              }
            >
              <img
                className="w-32 tablet:w-24 mobile:w-16"
                src={company[0]?.certifications[2]?.img_3}
                alt=""
              />
            </div>
          </div>
        </div> */}

        {/* Our Products */}
        <div className="pt-30 pb-20 tablet:pb-5 tablet:pt-16 wide:w-[88%] w-[60%] mx-auto">
          {/* heading */}
          <Titles text="Our Products"></Titles>
          <div className="flex items-center justify-between">
            {/* <h1 className="text-3xl font-bold tablet:text-lg">Our Products</h1> */}
            {/* <a
              className="text-xl font-semibold tablet:text-xs text-[#1C3A27] underline"
              href="#"
            >
              Download Catalog
            </a> */}
          </div>

          {/* all products */}
          <div className="grid grid-cols-4 gap-4 mt-20 tablet:mt-12 tablet:grid-cols-2 tablet:justify-items-center tablet:items-center">
            {/* product */}
            {company[0]?.ourProducts.map((item) => (
              <div
                key={item.details}
                className="flex flex-col items-center justify-center"
              >
                <div
                  className={
                    pathname === "/efgf"
                      ? "bg-[#B6B5B9] rounded-xl"
                      : "bg-[#B6B5B9] rounded-xl w-full"
                  }
                >
                  <img
                    className="w-full rounded-xl h-[193px] drop-shadow-md"
                    src={
                      item.image
                        ? item.image
                        : "https://imagesvc.meredithcorp.io/v3/mm/image?url=https%3A%2F%2Fstatic.onecms.io%2Fwp-content%2Fuploads%2Fsites%2F9%2F2021%2F07%2F13%2FUltimate-Veggie-Burgers-FT-Recipe-0821.jpg"
                    }
                    alt=""
                  />
                </div>
                {item.details === "details here" ? (
                  ""
                ) : (
                  <div className="">
                    <h4 className="mt-2 text-lg max-line-2 tablet:text-xs">
                      {item.details}
                    </h4>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* get in touch */}
        <div className="pb-16">
          <Titles text="Get In Touch"></Titles>
        </div>
        {/* <h1 className="font-bold text-left tablet:block md:hidden tablet:pt-16 tablet:text-lg">
          Get In Touch
        </h1> */}

        {/* find us title */}
        <div className="grid grid-cols-12 mb-8 text-left mobile:grid-rows-2 mobile:items-center wide:w-[88%] w-[60%] mx-auto">
          {company[0].pathname === "/efgf" && (
            <div className="col-span-6 mobile:row-start-2 mobile:col-span-12 mobile: row-span-[50%]">
              <h2 className="mb-4 text-lg font-bold">Factory Address: </h2>
              <div className=" mobile:text-sm">
                <p>
                  {company[0]?.address.area} {company[0]?.address.road}
                </p>
                {/* <p> {company[0]?.address.road}</p> */}
                <p>{company[0]?.address.email}</p>
                <p>{company[0]?.address.phone1}</p>
                <p>{company[0]?.address.phone2}</p>
                <div className="flex items-center">
                  <p>Visit Us:</p>
                  <a
                    className="pl-2 text-red-600 underline"
                    href={company[0]?.address.webLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.efgfashion.com
                  </a>
                </div>
              </div>
              <h1 className="mt-12 mb-2 text-lg font-bold tablet:text-lg">
                Our Social Media Link
              </h1>
              <div className="flex justify-start pt-4">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/efgfashiongarments/"
                >
                  <img className="w-10 mr-6" src={fb} alt="" />
                </a>
                <a href="/efgf">
                  <img className="w-10 mr-6" src={tweet} alt="" />
                </a>
                <a
                  rel="noreferrer"
                  href="https://www.instagram.com/efgfashiongarments/?hl=en"
                >
                  <img className="w-10 mr-6" src={insta} alt="" />
                </a>
              </div>
            </div>
          )}
          {company[0].pathname === "/rosemco" && (
            <div className="col-span-6 mobile:row-start-2 mobile:col-span-12 mobile: row-span-[50%]">
              <h2 className="mb-4 text-lg font-bold">Factory Address: </h2>
              <div className=" mobile:text-sm">
                <p>
                  {company[0]?.address.area} <br /> {company[0]?.address.road}
                </p>
                {/* <p> {company[0]?.address.road}</p> */}
                <p>{company[0]?.address.district}</p>
                <p>{company[0]?.address.email}</p>
                <p>{company[0]?.address.phone1}</p>
                <p>{company[0]?.address.phone2}</p>
                <div
                  className={
                    company[0]?.address?.webLink
                      ? "flex items-center"
                      : "hidden"
                  }
                >
                  <p>Visit Us:</p>
                  <a
                    className="pl-2 text-red-600 underline"
                    href={company[0]?.address?.webLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    www.efgfashion.com
                  </a>
                </div>
              </div>
              <h1 className="mt-12 mb-2 text-lg font-bold tablet:text-lg">
                Our Social Media Link
              </h1>
              <div className="flex justify-start pt-4">
                <a rel="noreferrer" href="/rosemco">
                  <img className="w-10 mr-6" src={fb} alt="" />
                </a>
                <a rel="noreferrer" href="/rosemco">
                  <img className="w-10 mr-6" src={tweet} alt="" />
                </a>
                <a rel="noreferrer" href="/rosemco">
                  <img className="w-10 mr-6" src={insta} alt="" />
                </a>
              </div>
            </div>
          )}

          {(company[0].pathname === "/eurasia" ||
            company[0].pathname === "/saidowla" ||
            company[0].pathname === "/masalabazaar" ||
            company[0].pathname === "/efgaqua" ||
            company[0].pathname === "/efgt") && (
            <div className="col-span-6 mobile:row-start-2 mobile:col-span-12 mobile: row-span-[50%]">
              <h2 className="mb-4 text-lg font-bold">Corporate Office: </h2>
              <div className=" mobile:text-sm">
                <p>Navana Oval, 7th Floor, Plot-5 Sonargaon Janapath Road,</p>
                <p> Sector-07, Uttara, Dhaka</p> <p>+880248955206</p>
                <p>+880248955206</p>
                <p>info@eurofoods-bd.com</p>
                <p>www.eurofoods-bd.com</p>
                {company[0].pathname === "/masalabazaar" && (
                  <div className="flex items-center">
                    <p>Visit Us:</p>
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      className="pl-2 text-red-600 underline"
                      href={company[0]?.address.webLink}
                    >
                      www.masalabazaar.com.bd
                    </a>
                  </div>
                )}
                {company[0].pathname === "/efgt" && (
                  <div className="flex items-center">
                    <p>Visit Us:</p>
                    <a
                      rel="noreferrer"
                      target={"_blank"}
                      className="pl-2 text-red-600 underline"
                      href={company[0]?.address.webLink}
                    >
                      www.efgtech.com.bd
                    </a>
                  </div>
                )}
              </div>
              <h1 className="mt-12 mb-2 text-lg font-bold tablet:text-lg">
                Our Social Media Link
              </h1>
              <div className="flex justify-start pt-4">
                {company[0].pathname === "/efgt" ? (
                  <a
                    rel="noreferrer"
                    target={"_blank"}
                    href="https://www.facebook.com/people/EFG-Tech/100076926999070/"
                  >
                    <img className="w-10 mr-6" src={fb} alt="" />
                  </a>
                ) : (
                  <a href="">
                    <img className="w-10 mr-6" src={fb} alt="" />
                  </a>
                )}
                <a href="">
                  <img className="w-10 mr-6" src={tweet} alt="" />
                </a>
                <a href="">
                  <img className="w-10 mr-6" src={insta} alt="" />
                </a>
              </div>
            </div>
          )}

          {/* <div className="col-span-6 mobile:row-start-2 mobile:col-span-12 mobile: row-span-[50%]">
            <h2 className="mb-4 text-lg font-bold">Corporate Office: </h2>
            <div className=" mobile:text-sm">
              <p>Navana Oval, 7th Floor, Plot-5 Sonargaon Janapath Road,</p>
              <p> Sector-07, Uttara, Dhaka</p> <p>+880248955206</p>
              <p>+880248955206</p>
              <p>info@eurofoods-bd.com</p>
              <p>www.eurofoods-bd.com</p>
            </div>
            <h1 className="mt-12 mb-2 text-lg font-bold tablet:text-lg">
              Our Social Media Link
            </h1>
            <div className="flex justify-start pt-4">
              <a href="">
                <img className="w-10 mr-6" src={fb} alt="" />
              </a>
              <a href="">
                <img className="w-10 mr-6" src={tweet} alt="" />
              </a>
              <a href="">
                <img className="w-10 mr-6" src={insta} alt="" />
              </a>
            </div>
          </div> */}

          <div className="col-span-2 mobile:hidden"></div>
          <div className="col-span-4 mobile:row-start-1 mobile:col-span-8 mobile:col-start-3">
            {/* <h1 className="text-2xl font-bold tablet:font-normal tablet:text-lg ">
              Find Us
            </h1> */}
            <img className="" src={company[0]?.map} alt="" />
          </div>
        </div>
      </div>

      <Footer></Footer>
    </div>
  );
};

export default CompanyPage;
