import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";

import "./App.scss";
import ScrollToTop from "./components/scrolltotop/ScrollToTop";
import AboutUs from "./pages/aboutus/Aboutus";
import AllGalary from "./pages/allgalary/AllGalary";
import ContactUs from "./pages/contactus/ContactUs";
import EfgAqua from "./pages/efgaqua/EfgAqua";
import Efgf from "./pages/efgf/Efgf";
import Efgt from "./pages/efgt/Efgt";
import Eurasia from "./pages/eurasia/Eurasia";
import Galary from "./pages/galary/Galary";
import Home from "./pages/home/Home";
import HorizonSeaFood from "./pages/horizonseafood/HorizonSeaFood";
import MasalaBazaar from "./pages/masalabazaar/MasalaBazaar";
import Message from "./pages/message/Message";
import NotFound from "./pages/notfound/NotFound";
import Product from "./pages/product/Product";
import Project from "./pages/projects/Project";
import Rosemco from "./pages/rosemco/Rosemco";
import Saidowla from "./pages/saidowla/Saidowla";
import ContextApi from "./hooks/useContextApi.js";

function App() {
  return (
    <ContextApi>
      <div className="bg"></div>
      <div className="App content">
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Home></Home>} />
              <Route path="/home" element={<Home></Home>} />
              <Route path="/products" element={<Product></Product>} />
              <Route path="/aboutus" element={<AboutUs></AboutUs>} />
              <Route path="/gallery" element={<Galary></Galary>}>
                <Route path="allprojects" element={<AllGalary></AllGalary>} />
                <Route
                  path="eurasia"
                  element={<Eurasia galary={false}></Eurasia>}
                />
                <Route
                  path="rosemco"
                  element={<Rosemco galary={false}></Rosemco>}
                />
                <Route path="efgf" element={<Efgf galary={false}></Efgf>} />
                <Route
                  path="saidowla"
                  element={<Saidowla galary={false}></Saidowla>}
                />
                <Route
                  path="masalabazaar"
                  element={<MasalaBazaar galary={false}></MasalaBazaar>}
                />
                <Route path="efgt" element={<Efgt galary={false}></Efgt>} />
                <Route
                  path="efgaqua"
                  element={<EfgAqua galary={false}></EfgAqua>}
                />
              </Route>
              <Route
                path="/eurasia"
                element={<Eurasia galary={true}></Eurasia>}
              />
              <Route path="/efgf" element={<Efgf galary={true}></Efgf>} />
              <Route
                path="/rosemco"
                element={<Rosemco galary={true}></Rosemco>}
              />
              <Route path="/efgt" element={<Efgt galary={true}></Efgt>} />
              <Route
                path="/saidowla"
                element={<Saidowla galary={true}></Saidowla>}
              />
              <Route
                path="/masalabazaar"
                element={<MasalaBazaar galary={true}></MasalaBazaar>}
              />
              <Route
                path="/efgaqua"
                element={<EfgAqua galary={true}></EfgAqua>}
              />
              <Route
                path="/horizonseafood"
                element={<HorizonSeaFood></HorizonSeaFood>}
              />
              <Route path="/projects" element={<Project></Project>} />
              <Route path="/contactus" element={<ContactUs></ContactUs>} />
              <Route path="/message" element={<Message></Message>} />
              <Route path="/*" element={<NotFound></NotFound>} />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
        {/* <Product /> */}
      </div>
    </ContextApi>
  );
}

export default App;
