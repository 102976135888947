import React from "react";

const Titles = ({ text }) => {
  return (
    <div>
      <div className="flex items-center justify-center py-5 mt-4 ">
        {/* <hr className="w-8 h-1 bg-black mx-7" /> */}
        <h2 className="mx-2 mb-1 text-2xl font-semibold mobile:text-lg mobile:font-light">
          {text}
          <hr className="py-[2px] mobile:py-[1px] rounded-lg bg-green-900" />
        </h2>
        {/* <hr className="w-8 h-1 bg-black mx-7" /> */}
      </div>
    </div>
  );
};

export default Titles;
