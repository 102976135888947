import React from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Disclosure, Menu, Transition, Popover } from "@headlessui/react";
import { Fragment, useState, useRef, useEffect } from "react";

const PopOverBtn = ({ linksArray, menuTitle, mode }) => {
  const buttonRef = useRef(null); // useRef<HTMLButtonElement>(null)
  const [openState, setOpenState] = useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  let timeout; // NodeJS.Timeout
  const timeoutDuration = 100;

  const toggleMenu = (open) => {
    // log the current open state in React (toggle open state)
    setOpenState((openState) => !openState);
    // toggle the menu by clicking on buttonRef
    buttonRef?.current?.click(); // eslint-disable-line
  };

  // Open the menu after a delay of timeoutDuration
  const onHover = (open, action) => {
    // if the modal is currently closed, we need to open it
    // OR
    // if the modal is currently open, we need to close it
    if (
      (!open && !openState && action === "onMouseEnter") ||
      (open && openState && action === "onMouseLeave")
    ) {
      // clear the old timeout, if any
      clearTimeout(timeout);
      // open the modal after a timeout
      timeout = setTimeout(() => toggleMenu(open), timeoutDuration);
    }
    // else: don't click! 😁
  };

  const handleClick = (open) => {
    setOpenState(!open); // toggle open state in React state
    clearTimeout(timeout); // stop the hover timer if it's running
  };

  const handleClickOutside = (event) => {
    if (buttonRef.current && !buttonRef.current.contains(event.target)) {
      event.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  return (
    <>
      <Popover className="relative">
        {({ open }) => (
          <div
            onMouseEnter={() => onHover(open, "onMouseEnter")}
            onMouseLeave={() => onHover(open, "onMouseLeave")}
          >
            <Popover.Button
              ref={buttonRef}
              className="outline-none hover:outline-none ring-0 ring-white"
            >
              <div
                className={classNames(
                  open ? "text-[#F4070C] " : "",
                  "flex justify-center"
                )}
                onClick={() => handleClick(open)}
              >
                <span
                  className={
                    mode === "mobile" ? "py-0 border-b-2 border-black" : "py-8 "
                  }
                >
                  {menuTitle}
                  <ChevronDownIcon
                    className={classNames(
                      open ? " rotate-180" : "",
                      "w-6 h-6 inline-block text-black",
                      "transform transition-all duration-500"
                    )}
                    aria-hidden="true"
                  />
                </span>
              </div>
            </Popover.Button>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-40"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className={
                  mode === "mobile"
                    ? "w-20"
                    : "absolute left-0 z-50 w-60 mt-2 origin-top-right bg-white rounded-lg shadow-lg top-14 focus:outline-none"
                }
              >
                <div
                  className={classNames(
                    mode === "mobile"
                      ? " border-none w-60 -mb-4 bg-transparent  "
                      : "",
                    "relative grid space-y-[2px]",
                    "bg-[#FFCD72] border-x border-y border-black",
                    " rounded-md text-left "
                  )}
                >
                  {linksArray.map(([title, href]) => (
                    <Fragment key={"PopoverPanel<>" + title + href}>
                      <a
                        href={href}
                        className={
                          mode === "mobile"
                            ? "p-2 py-2 hover:bg-green-900 hover:text-white "
                            : "p-2 py-2 hover:bg-green-900 hover:text-white"
                        }
                      >
                        {title}
                      </a>
                    </Fragment>
                  ))}
                </div>
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
    </>
  );
};

export default PopOverBtn;
