import React, { useState } from "react";
import * as ReactDom from "react-dom";
import close_icon from "../../assets/icons/close_icon.svg";

const Modal = ({ open, closeFunc, videoLink, imageLink, type }) => {
  // const values = React.useContext(AllContext);
  return ReactDom.createPortal(
    <>
      {type === "video" && (
        <div
          className={
            open === true
              ? "block bg-white rounded-2xl h-min  laptop:w-[80vw] lg:w-[50vw] fixed left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-50"
              : "hidden"
          }
        >
          {/* <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6QVPMZ2p1RQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> */}
          <div className="relative">
            <video id="vid" width="100%" height="auto" controls autoPlay>
              <source src={videoLink} type="video/mp4" />
            </video>
            <button
              className="absolute bg-white rounded-sm top-2 right-2"
              onClick={() => closeFunc()}
            >
              <img
                src={close_icon}
                title="close"
                className="h-[30px] w-[30px]"
                alt="close"
              />
            </button>
          </div>
        </div>
      )}

      {type === "image" && (
        <div>
          <div
            className={
              open === true
                ? "block bg-white rounded-2xl h-min  laptop:w-[80vw] mobile:w-[90vw] lg:w-[50vw] fixed left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-50"
                : "hidden"
            }
          >
            {/* <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/6QVPMZ2p1RQ"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe> */}
            <div className="relative">
              {/* <video id="vid" width="100%" height="auto" controls autoPlay>
                <source src={videoLink} type="video/mp4" />
              </video>
              <button
                className="absolute bg-white rounded-sm top-2 right-2"
                onClick={() => closeFunc()}
              >
                <img
                  src={close_icon}
                  title="close"
                  className="h-[30px] w-[30px]"
                  alt="close"
                />
              </button> */}
              <img src={imageLink} w-full h-full alt="" />
              <button
                className="absolute bg-white rounded-sm top-2 right-2"
                onClick={() => closeFunc()}
              >
                <img
                  src={close_icon}
                  title="close"
                  className="h-[30px] w-[30px]"
                  alt="close"
                />
              </button>
            </div>
          </div>
        </div>
      )}
    </>,
    document.getElementById("portal")
  );
};

export default Modal;
