import React from "react";
import * as ReactDom from "react-dom";

const Loader = () => {
  return ReactDom.createPortal(
    <>
      <div className="fixed left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-[80] flex justify-center items-center">
        <span className="text-xl font-bold">Processing... &nbsp;</span>
        <svg
          className="animate-spin h-7 w-7 mr-3 ... bg-green-500 text-gray-900"
          viewBox="0 0 180 180"
        ></svg>
      </div>
    </>,
    document.getElementById("portal")
  );
};

export default Loader;
