// import map_logo from "./icons/map-logo.svg";
// import location_logo from "./icons/location-logo.svg";
// import established_logo from "./icons/established.svg";
// import employee_logo from "./icons/employee.svg";
// import map from "./img/map-pic.png";
// import insta from "./icons/Instagram.svg";
// import fb from "./icons/Facebook.svg";
// import tweet from "./icons/Twitter.svg";

// import check from "./icons/check-circle.svg";
// import shape from "./img/shape.png";

import qualityIcon from "./icons/quality_icon.svg";
import navigateIcon from "./icons/navigate_icon.svg";
import treeIcon from "./icons/tree_icon.svg";

//  <!-- OUR PRODUCTS SECTION IMAGE IMPORT -->
import aqua_farming from "./img/EFG Aqua/EFG-Aqua-Farming-2.jpeg";
import aqua_farming_2 from "./img/EFG Aqua/EFG-Aqua-Farming.jpg";
import aqua_farming_3 from "./img/EFG Aqua/EFG-Aqua-Farming4.jpeg";
import aqua_farming_4 from "./img/EFG Aqua/Rosemco-product-1.jpeg";

import protect from "./img/EFG Fashian/EFG-Super-Protect.jpeg";
import trouser from "./img/EFG Fashian/EFG-PREMIUM-TROUSER.jpeg";
import panjabi from "./img/EFG Fashian/EFG-PREMIUM-PANJABI.jpg";
import custom_mask from "./img/EFG Fashian/CUSTOM-MASK-2.jpeg";
import sgs from "./img/EFG Fashian/efgfashion-SGS.png";

import auto from "./img/EFG Tech/Business-Automation.png";
import itSol from "./img/EFG Tech/IT-Solution.jpg";
import online from "./img/EFG Tech/MB-Online.png";
import pos from "./img/EFG Tech/POS-Systam.png";

import ghur from "./img/Eurasia/Eurasia-Ghur-Handesh.jpg";
import butter from "./img/Eurasia/Eurasia-Malta-Curamom-Butter.png";
import coconut from "./img/Eurasia/Eurasia-Shredded-Coconut.png";
import mg from "./img/Eurasia/MG-1269.png";

import halal from "./icons/Halal.svg";
import haccp from "./icons/Haccp.svg";
import brcgs from "./icons/Brcgs.svg";
// import fda from "./icons/fda.png";
// import global_seafood from "./icons/global_seafood.svg";
// import bap from "./icons/BAP.jpg";

import ms_2 from "./img/masala bazaar/Masala-Bazaar-2.jpg";
import ms_1 from "./img/masala bazaar/Masala-Bazaar-1.jpg";
import ms_3 from "./img/masala bazaar/Masala-Bazaar-3.jpg";
import ms_4 from "./img/masala bazaar/Masala-Bazaar-4.jpg";

import ros_1 from "./img/Rosemco/Rosemco-product-1.jpeg";
import ros_2 from "./img/Rosemco/Rosemco-product-2.jpeg";
import ros_3 from "./img/Rosemco/Rosemco-product-3.jpg";
import ros_4 from "./img/Rosemco/Rosemco-product-4.jpeg";

import ros_5 from "./img/Rosemco/ros_2.jpg";
import ros_6 from "./img/Rosemco/ros_3.jpg";
import ros_7 from "./img/Rosemco/ros_4.jpg";

import sai_1 from "./img/saidowla/MG-1758.jpeg";
import sai_2 from "./img/saidowla/MG-3162.jpeg";
import sai_3 from "./img/saidowla/MG-3207.jpeg";
import sai_4 from "./img/saidowla/MG-3516.jpg";
// import from './img'

// all maps
import saidowla_map from "./img/maps/Map-SAIDOWLA.png";
import rosemco_map from "./img/maps/Map-Rosemco.png";
import efgf_map from "./img/maps/Map-EFG-Fashion.png";
import masalabazaar_map from "./img/maps/Map-EFG-Fashion.png";
import efgAqua_map from "./img/maps/Map EFG Aqua Farming.png";
import efgt_map from "./img/maps/Map EFG Tech.png";
import eurasia_map from "./img/maps/eurasia_map.png";

//  <!-- data -->
const data = [
  {
    state: 2,
    title: "EURASIA Food Processing (BD) Ltd.",
    motto: "company motto",
    map: eurasia_map,
    pathname: "/eurasia",
    banner: "https://eurofoods.com.bd/images/eurasia/eurasia-big-image.jpg",
    about: `Euro Foods Group started its operation in Bangladesh on February 5, 2002, by establishing a company named EURASIA FOOD PROCESSING (BD) LTD (in short EFPL). It was a joint venture of the UK and Bangladesh. Key products are frozen Paratha & Snacks, Fruits & Vegetables, Sweets and Bakeries, Rice, and Spices.
      Established at a cost of US$ 2 million with the most modern Spiral Individual Quick Freezer, the company made an export of US$ 1.5 million in the year of its inception. All the goods manufactured and processed are mainly exported in the UK, USA, Canada, Australia, South Korea, Italy besides a significant number of products available in the local market of Bangladesh.
      EFPL adopted the most recent technology to formulate and produce generic formulations ensuring optimum quality compared to the international brands.
      The Company’s food safety policy was developed by the management team, under the guidance of the Chairman and has his full and demonstrable commitment. All employees are aware of the company’s commitment to food safety and food quality and their responsibility for it. We Provide all employees with appropriate training, particularly in areas that affect food safety and food quality. We have Implemented a management system that complies with the requirements of Food Safety Management and ISO 22000: 2005 and Global Standard for Food Safety Issue-6.`,
    established: 2002,
    location: "Gouripur, Ashulia, Savar, Dhaka",
    area: "95 Decimal",
    employee: "350+",
    keyEvents: [
      {
        keyEvents: {
          title: "Our Key Events",
          events: [
            "Freezing capacity increased to 4500kg/6 hours 2014",
            "Total export weight of 2500 tones in a calendar year 2016",
          ],
        },
        cards: [
          {
            icon: "",
            title: "Key Products",
            details:
              "Frozen Paratha, Snacks, Fruits & Vegetables, Sweets and Bakeries, Rice and Spices",
          },
          {
            icon: "",
            title: "Targeted Market",
            details:
              "95% export to UK, USA, Canada, Australia, South Korea, Italy and local Bangladesh market",
          },
          {
            icon: "",
            title: "Certificates",
            details: "Achieved Certification HACCP, BRCS and Halal",
          },
        ],
      },
    ],

    certifications: [halal, haccp, brcgs],
    ourProducts: [
      {
        image: mg,
        details: "Bun",
      },
      {
        image: butter,
        details: "Malta Curamom Butter Biscuit",
      },
      {
        image: ghur,
        details: "Ghur Handesh ",
      },
      {
        image: coconut,
        details: "Shredded Coconut",
      },
    ],
  },
  {
    state: 5,
    title: "SAIDOWLA (PVT.) Enterprise Ltd.",
    motto: "company motto",
    map: saidowla_map,
    pathname: "/saidowla",
    banner: "https://i.ibb.co/VLMZCK0/SAIDOWLA-Big-Image.png",
    about: `In 2005 Euro Foods Group acquired SAIDOWLA (PVT.) ENTERPRISE LTD in Sunamgonj, Sylhet, which is a fish processing supplier. Their Key Product is White water fish, prawns from lakes, rivers, and Ponds (Block Frozen, Individual Quick Frozen (IQF), and 99% export to the UK, USA, Canada, Australia, South Korea, Italy, and local Bangladesh market.
      The plant is surrounded by a 3.0 m high boundary wall, designed and constructed by a reputed consulting firm after getting clearance from the MOFL, Ministry of Industries and local authority. The factory is a two storied building having the facilities to implement the HACCP system. All processing activities are accommodated systemically in the ground floor. General administration, Laboratory, accounts, personal department, physician’s room and other necessary facilities are on the 1st floor. Floors of the establishment are constructed with hard mosaic, smooth, non-absorbent, non-porous, properly slopped, non-sloppy, easily washable, and resistant to corrosion of the chemicals like ammonia, chlorides, hypochlorite etc and fish oils. The Slope of the floor to drain is kept approximately 100: 1 (1 ft slope in 100 ft).
        `,
    established: 1985,
    location: "Sunamgonj, Sylhet",
    area: "3.6 Acres",
    employee: "200+",
    keyEvents: [
      {
        keyEvents: {
          title: "Future Plan",
          events: ['Product upgrade from "Ready to Cook" to "Ready to Eat"'],
        },
        cards: [
          {
            icon: "",
            title: "Key Products",
            details:
              "White water fish, prawn from Lake, River and Ponds (Block Frozen, Individual Quick Frozen (IQF)",
          },
          {
            icon: "",
            title: "Targeted Market",
            details:
              "99% export to UK, USA, Canada, Australia, South Korea, Italy and local Bangladesh market",
          },
          {
            icon: "",
            title: "Certificates",
            details: "Achieved Certification HACCP",
          },
        ],
      },
    ],
    certifications: [haccp],
    ourProducts: [
      {
        image: sai_3,
        details: "Pabda",
      },
      {
        image: sai_1,
        details: "Boal",
      },
      {
        image: sai_4,
        details: "Rani",
      },
      {
        image: sai_2,
        details: "Chapila",
      },
    ],
  },
  {
    state: 3,
    title: "ROSEMCO Foods Ltd.",
    motto: "company motto",
    map: rosemco_map,
    pathname: "/rosemco",
    banner: "https://i.ibb.co/41nmPN6/Rosemco-Big-Image.png",
    about: `Rosemco Foods Limited is a 100% export oriented fish processing industry established in the year 2000 and HACCP certified since 2010. With the past experience and future vision, the industry has been set up even to cope with the demand of the consumers of the next decades. It is one of the most modern industries in this sector equipped with much updated machinery adopting latest technology from where product quality can be achieved. This project is established by own finance of the owner having the sound financial condition. The Industry is a hundred percent export oriented establishment. The company has achieved a good reputation in the international market for its quality products the company Brand: Rosemco, and under the buyer Brands, Crown farm, Zoy, Crown Farms, Cha Cha Chef, Rohim, Masala, efg Basic, efg Value, Antilles mer and Asian Choice, etc. The company has already made an international market through distributing the quality sea food products and for its sincerity to the given commitment. To fulfill the requirements of USA, EU, Korea and Fish and Fishery products (Inspection and Quality control) Rules, 1997 of GOB, the plant is designed as per recommendations of the DOF personnel and set the adequate facilities`,
    established: 2000,
    location: "Niklapur, Rupsha, Khulna",
    area: "1.32 Acres",
    employee: "300+",
    keyEvents: [
      {
        keyEvents: {
          title: "Production Capacity",
          events: ["7000 Metric Tons Per Year"],
        },
        cards: [
          {
            icon: "",
            title: "Key Products",
            details: "Prawns (Aquaculture Farming and Wild Caught).",
          },
          {
            icon: "",
            title: "Targeted Market",
            details:
              "100% export to Europe, UK, USA, Korea, Asia, Middle East and Australia.",
          },
          {
            icon: "",
            title: "Certificates",
            details: "Achieved Certification HACCP and BRC",
          },
        ],
      },
    ],
    address: {
      area: "Niklapur, Rupsha",
      road: "Post. Code-9241",
      district: "Khulna, Bangladesh",
      email: "selim@eurofoods-bd.com",
      phone1: "+8801717444983",
      phone2: null,
      webLink: null,
    },
    certifications: [haccp],
    ourProducts: [
      {
        image: ros_1,
        details: "Black Tiger Shrimp",
      },
      {
        image: ros_5,
        details: "Black Tiger Hoso SIQF",
      },
      {
        image: ros_6,
        details: "Black Tiger Butterfly",
      },
      {
        image: ros_7,
        details: "Black Tiger Skewer",
      },
    ],
  },
  {
    state: 6,
    title: "EFG Fashion Ltd.",
    motto: "company motto",
    map: efgf_map,
    pathname: "/efgf",
    banner: "https://i.ibb.co/rxWWfMp/EFG-Fashion-Big-Image.png",
    about: `In 2021 EFG established its fashion segment, EFG Fashion provides strategic intelligence, trend forecasts, actionable insights, and accurate product performance data, and sharp creative judgment, designed to help our customers get closer to certainty in uncertain times and to seize precise opportunities in accelerating markets. Its present capacity is 20,000 T-Shirt/M. The company has generated over 150 employment and plays an important role in the local economy.`,
    established: 2020,
    location: "South Kolma, Savar, Dhaka",
    area: "5.2 Acres",
    employee: "150+",
    keyEvents: [
      {
        keyEvents: {
          title: "SGS Certified Company",
          events: [
            "SGS is the world's leading inspection, verification, testing and certification company. SGS is recognized as the global benchmark for quality and integrity.",
          ],
        },
        cards: [
          {
            icon: "",
            title: "Online Tailoring",
            details: "Largest Online Tailoring first time in Bangladesh",
          },
          {
            icon: "",
            title: "On Demand Printing",
            details: "Largest on demand printing in Bangladesh",
          },
          {
            icon: "",
            title: "Event Production",
            details: "Largest Clothing production for any event",
          },
        ],
      },
    ],
    address: {
      area: "South Kolma",
      road: "02/03, Dairy Farm-1341",
      district: "Savar, Dhaka",
      email: "support@efgfashion.com",
      phone1: "+8809612000123",
      phone2: "+8809678114545",
      webLink: "https://efgfashion.com/",
    },
    certifications: [sgs],
    ourProducts: [
      {
        image: protect,
        details: "EFG Super Protect",
      },
      {
        image: trouser,

        details: "EFG Premium Trouser",
      },
      {
        image: panjabi,

        details: "EFG Premium Panjabi",
      },
      {
        image: custom_mask,
        details: "Custom Mask",
      },
    ],
  },
  {
    state: 7,
    title: "MasalaBazaar Retails Ltd.",
    motto: "company motto",
    map: masalabazaar_map,
    pathname: "/masalabazaar",
    banner: "https://eurofoods.com.bd/images/mb_images/mb-store.jpg",
    about: `In 2021 EFG established Masala Bazaar. MasalaBazaar is a concern of Euro Foods Group, is one of the premium super shops of Bangladesh that meets all your domestic shopping requirements.
      With a diverse range of product offerings, a committed 1 Hour home delivery service, cashless payment facilities, favourable delivery slots, dedicated 365 days call center operations and the best support from delivery partners, MasalaBazaar is prioritizing customer needs and assuring the indisputable quality of service.
      The brick-and-mortar Masala Bazaar stores and Warehouses are the delivery dispatch points for MasalaBazaar. This is both a unique sales proposition and a point of difference for the brand. In addition to being a popular destination for groceries, Masalabazaar has some collection of books, electronics, home appliances, fashion accessories and a lot more.
      We are constantly expanding our product range as well working on giving clients best products at an affordable price. Online shopping in Bangladesh has never been this easy! Stay connected and stay tuned for new offers and deals we have from time to time. Remember, every day is a shopping day on MasalaBazaar! Get extra discounts and cheapest rate on selected products.
      Last but not the least, our goal with Masalabazaar is to help save our customers time and effort by providing a thoroughly streamlined digital shopping experience. We achieve that with the help of our enhanced e-commerce compatible website interface and mobile apps on both Android and iOS platforms.
        `,
    established: 2021,
    location: "Sylhet",
    area: "5000 Sqft",
    employee: "100+",
    certifications: [],
    address: {
      webLink: "https://efgfashion.com/",
    },
    keyEvents: [
      {
        keyEvents: {
          title: "Our Core Features",
          events: [
            "Authentic Ingredients",
            "Wholesale Cash & Carry",
            "Public Supermarket",
            "Competitive Prices",
          ],
        },
        cards: [
          {
            icon: "",
            title: "FREE SHIPPING",
            details: "We are providing Free shipping on all order",
          },
          {
            icon: "",
            title: "1 HOUR DELIVERY",
            details: "We offer Delivery within 1 hour on selected area",
          },
          {
            icon: "",
            title: "SUPPORT 14/7",
            details:
              "We provide customer support 14 hours a day, 7 days a week",
          },
        ],
      },
    ],
    ourProducts: [
      {
        image: ms_4,
        details: "bakorkhani-paratha",
      },
      {
        image: ms_1,
        details: "Beef Samosa",
      },
      {
        image: ms_2,
        details: "Aloo Puri Potato Snacks",
      },
      {
        image: ms_3,
        details: "Aloo Paratha",
      },
    ],
  },
  {
    state: 4,
    title: "EFG Aqua Farming Ltd.",
    motto: "company motto",
    map: efgAqua_map,
    pathname: "/efgaqua",
    banner: "https://i.ibb.co/dkLLGVL/EFG-Aqua-Farming.png",
    about: `In the year 2021 Euro Foods Group established EFG AQUA FARMING in Purba Jhiler Danga, Khulna, which is a Shrimp farming unit. EFG expanded the scope of shrimp farming for maximum output from natural resources using minimal environmental impact and options for a sustainable future.`,
    established: 2021,
    location: "Purba Jhiler Danga, Khulna",
    area: "18 Acres",
    employee: "120+",
    certifications: [],
    keyEvents: [
      {
        keyEvents: {
          title: "Benefits of Aqua Farming",
          events: [
            "Alternative Food Source",
            "Increase Jobs in the Market",
            "Low Environmental Impact",
            "Natural Source of Water",
          ],
        },
        cards: [
          {
            icon: "",
            title: "Key Product",
            details:
              "White water fish, prawn from Lake, River and Ponds (Block Frozen, Individual Quick Frozen (IQF)",
          },
          {
            icon: "",
            title: "Targeted Market",
            details:
              "99% export to UK, USA, Canada, Australia, South Korea, Italy and local Bangladesh market",
          },
          {
            icon: "",
            title: "Production Capacity",
            details: "2500 Metric Tons",
          },
        ],
      },
    ],
    ourProducts: [
      {
        image: aqua_farming,
        details: "details here",
      },
      {
        image: aqua_farming_2,
        details: "details here",
      },
      {
        image: aqua_farming_3,
        details: "details here",
      },
      {
        image: aqua_farming_4,
        details: "details here",
      },
    ],
  },
  {
    state: 8,
    title: "EFG Tech Ltd.",
    motto: "company motto",
    map: efgt_map,
    pathname: "/efgt",
    banner: "https://i.ibb.co/3z3JQ9m/EFG-Tech-Big-Image.png",
    about: `EFG Tech is a promising ICT start-up that began its journey in 2021 with a vision to create a difference in the ICT sector by providing cutting-edge technology solutions for the domestic and international markets. Its mission is to develop software, industrial automation, mobile applications, ERP solutions, and remote IT support solutions for small and mid-sized businesses with the goal of enhancing competitiveness in this agile and ever-changing business world.
      In order to achieve this goal, we are always committed to hiring, training, and retaining talented human resources from the local market. This not only helps us achieve our business goal but also addresses the nation's unemployment problem. The team is equipped with highly skilled and experienced resources not only within the technology domain but also in the business processes across a range of industry sectors, which helps us place ourselves in a niche at the top of the pyramid to deliver unique solutions with greater operational efficiency, productivity, and cost savings for our clients.
      As an established technology-intensive company, we pride ourselves on providing a comprehensive suite of solutions comprising of Enterprise Resource Planning, Business Intelligence, infrastructure consultancy, offshore services, custom software and web development, software, and web application testing, and enterprise architecture consulting. The company is always focused on adopting cutting-edge technologies in the areas of mobile technology, cloud computing, and data science to deliver our proposition. At EFG Tech, we guarantee rapid, reliable, and robust information technology solutions that work.
        `,
    established: 2022,
    location: "Uttara, Dhaka",
    area: "1200 Sqft",
    employee: "20+",
    certifications: [],
    address: {
      webLink: "https://efgtech.com.bd/home",
    },
    keyEvents: [
      {
        keyEvents: {
          title: "Our Core Solution",
          events: [
            "Mobile Application",
            "Website Development",
            "Software Development",
            "ERP Solution",
            "System Integration",
            "Business Analytics",
          ],
        },
        cards: [
          {
            icon: "",
            title: "Commitment",
            details:
              "We provide variety of services to different businesses where commitment to each client is eminent. We build relationship bridge with clients basing on trust and encourage employees to go above and beyond no matter what.",
          },
          {
            icon: "",
            title: "Customer Centricity",
            details:
              "We have a dedicated team who proactively communicate in order to provide supreme customer support. To drive the quality and ensure customer satisfaction, we always focus on utmost customer centricity.",
          },
          {
            icon: "",
            title: "Transparency",
            details:
              "Fairness, transparency and sincerity are strictly maintained in every contract and transactions.",
          },
        ],
      },
    ],
    ourProducts: [
      {
        image: auto,
        details: "Business Automation ",
      },
      {
        image: itSol,
        details: "IT Solution",
      },
      {
        image: online,
        details: "MB Online",
      },
      {
        image: pos,
        details: "POS System",
      },
    ],
  },
];

//  <!-- helpCard -->
const helpCard = [
  {
    id: 1,
    title: "Quality full Product",
    img: qualityIcon,
    article:
      "Make the appearance of a mobile application that has quality and increases user convenience",
  },
  {
    id: 2,
    title: "first online custom trailing",
    img: navigateIcon,
    article:
      "Help create a website and redesign it so that it becomes a website that is more trendy and looks fresh",
  },
  {
    id: 3,
    title: "Online on demand Printing",
    img: treeIcon,
    article:
      "Help create a website and redesign it so that it becomes a website that is more trendy and looks fresh",
  },
];

export { data, helpCard };
