import React from "react";

const ProjectCard = ({ classes, cardData }) => {
  return (
    <div
      className={`2xl:w-[35vw] w-[45vw] tab1:w-[80vw] mobile:w-[80vw] mobs:w-[70vw] rounded-xl ${classes} bg-[#F1F1F1]`}
    >
      {cardData.map((items) => (
        <div key={items.title} className="flex mobs:flex-col">
          <div className=" basis-1/3 h-[152px] p-4 rounded-xl overflow-hidden">
            <img
              src={items.img}
              alt=""
              className="object-cover w-full h-full "
            />
          </div>
          <div className="p-4 pl-0 text-left mobs:pl-4 basis-2/3">
            <h2 className="text-2xl font-semibold mobile:text-base mobs:text-xs ">
              {items.title}
            </h2>
            <p className="text-xl mobile:text-sm mobs:text-[10px]">
              <span>Location: </span>
              <span className="font-semibold">{items.loc}</span>
            </p>
            {items.area === "" ? (
              ""
            ) : (
              <p className="text-xl mobile:text-sm mobs:text-[10px]">
                <span>Land Area: </span>{" "}
                <span className="font-semibold">{items.area}</span>
              </p>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectCard;
