import React, { useEffect, useState } from "react";

import Titles from "../../components/titles/Titles";
// import pic1 from "../../assets/img/first_tile.png";
// import pic2 from "../../assets/img/second_tile.png";
// import pic3 from "../../assets/img/third_tile.png";
// import pic4 from "../../assets/img/fourth_tile.png";
// import pic5 from "../../assets/img/head2.png";
// import pic6 from "../../assets/img/body.png";

// import pic7 from "../../assets/img/first_tile_1.png";
// import pic8 from "../../assets/img/second_tile_1.png";
// import pic9 from "../../assets/img/third_tile_1.png";
// import pic10 from "../../assets/img/fourth_tile_1.png";
// import pic11 from "../../assets/img/head4.png";
// import pic12 from "../../assets/img/body3.png";
import shofiul from "../../assets/img/Sheikh Shofiul Alam.png";
import reza from "../../assets/img/Selim Reza.png";
import iqbal from "../../assets/img/Iqbal Hussain.png";
import selim_sir from "../../assets/img/Shelim Hussain.png";
import shariful from "../../assets/img/shariful Alam.png";

// import head2 from "../../assets/img/head2.png";
// import body from "../../assets/img/body.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
import "swiper/scss";
import Card from "../../components/card/Card";

import "./Heroes.scss";

import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();
// import HeroSlider from "../../components/heroSlider/HeroSlider";

const heads = [
  {
    id: 1,
    img: selim_sir,
    name: "Shelim Hussain MBE",
    designation: "Chairman",
    field: "Euro Foods Group",
  },
  {
    id: 2,
    img: shariful,
    name: "Md. Shariful Alam",
    designation: "Managing Director",
    field: "Euro Foods Group",
  },
  {
    id: 3,
    img: shofiul,
    name: "Sheikh Shofiul Alam",
    designation: "Director",
    field: "Euro Foods Group, Bangladesh Operation",
  },
  {
    id: 4,
    img: reza,
    name: "Selim Reza",
    designation: "Director",
    field: "Rosemco Foods LTD",
  },
  {
    id: 5,
    img: iqbal,
    name: "Md. Iqbal Hossain",
    designation: "Director",
    field: "EFG Aqua Farming",
  },
];

const Heroes = () => {
  const [content, setContent] = useState(1);

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (content === 2) {
        setContent(1);
      } else {
        setContent(content + 1);
      }
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [content]);

  // const items = [pic1, pic2, pic3, pic4, pic5, pic6];
  // const items1 = [pic7, pic8, pic9, pic10, pic11, pic12];
  return (
    <div className="w-full overflow-x-hidden">
      <Titles text="Chairman & Directors "></Titles>

      {/* {content === 1 && <HeroSlider img={items} />}
      {content === 2 && <HeroSlider img={items1} />} */}

      <div className="">
        <Swiper
          className="2xl:w-[1400px] w-[1280px] desktop:w-[980px] laptop:w-[768px] tablet:w-[640px] mobile:w-[480px] mobs:w-[360px] mx-auto mt-16 mb-20 relative"
          modules={[Navigation]}
          breakpoints={{
            // when window width is >= 640px
            360: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            480: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 110,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 3,
            },
            1024: {
              width: 1024,
              slidesPerView: 3,
            },
            1280: {
              width: 1280,
              slidesPerView: 4,
            },
            1535: {
              width: 1535,
              slidesPerView: 5,
            },
          }}
          // navigation
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          spaceBetween={110}
          loop={true}
        >
          {heads.map((item) => (
            <SwiperSlide key={item.id}>
              <Card
                type="heads"
                image={item.img}
                classes="w-72 laptop:w-[260px] tab2:my-1"
              >
                <div>
                  <h2 className="mb-2 text-xl font-bold">{item.name}</h2>
                  <h3 className="text-sm ">{item.designation}</h3>
                  <h3 className="text-sm ">{item.field}</h3>
                </div>
              </Card>
            </SwiperSlide>
          ))}
          <div className="flex items-center justify-center mt-8 text-lg font-semibold tab2:hidden">
            <div
              ref={navigationPrevRef}
              className="flex mr-14 hover:text-green-900"
            >
              <ChevronDoubleLeftIcon className="inline w-5 cursor-pointer" />
              <p className="ml-1 cursor-pointer select-none">Prev</p>
            </div>
            <div ref={navigationNextRef} className="flex hover:text-green-900">
              <p className="mr-1 cursor-pointer select-none">Next</p>
              <ChevronDoubleRightIcon className="inline w-5 cursor-pointer" />
            </div>
          </div>
        </Swiper>
      </div>

      {/* <div className=" mt-10 grid grid-cols-4 w-[85%] desktop:w-[100%] mx-auto laptop:grid-cols-2 laptop:w-[88%] gap-y-6 mobile:grid-cols-1">
        <div className="h-[344px] w-[255px] p-0 overflow-hidden rounded-2xl transition-all mx-auto bg-white">
          <img
            src="https://i.ibb.co/HrYfbr2/shelim.png"
            className="w-full h-[80%] object-cover"
            alt=""
          />
          <div className="pb-4 mt-2">
            <div className="flex flex-col items-center">
              <h1 className="font-semibold mobile:text-xs">Chairman</h1>
              <h2 className="text-2xl font-medium whitespace-nowrap mobile:text-sm">
                Selim Hussain MBE
              </h2>
            </div>
          </div>
        </div>
        <div className="h-[344px] w-[255px] p-0 overflow-hidden rounded-2xl transition-all mx-auto bg-white">
          <img
            src="https://eurofoods.com.bd/images/members/Sheikh-Shofiul-Alam.png"
            className="w-full h-[80%] object-contain"
            alt=""
          />
          <div className="pb-4 mt-2">
            <div className="flex flex-col items-center">
              <h1 className="font-semibold mobile:text-xs">Director</h1>
              <h2 className="text-[22px] font-medium whitespace-nowrap mobile:text-sm">
                Sheikh Shofiul Alam
              </h2>
            </div>
          </div>
        </div>
        <div className="h-[344px] w-[255px] p-0 overflow-hidden rounded-2xl transition-all mx-auto bg-white">
          <img
            src="https://eurofoods.com.bd/images/members/Selim-Reza.png"
            className="w-full h-[80%] object-contain"
            alt=""
          />
          <div className="pb-4 mt-2">
            <div className="flex flex-col items-center">
              <h1 className="font-semibold mobile:text-xs">Director</h1>
              <h2 className="text-2xl font-medium whitespace-nowrap mobile:text-sm">
                Selim Reza
              </h2>
            </div>
          </div>
        </div>
        <div className="h-[344px] w-[255px] p-0 overflow-hidden rounded-2xl transition-all mx-auto bg-white">
          <img
            src="https://eurofoods.com.bd/images/members/Md-Iqbal-Hossain.png"
            className="w-full h-[80%] object-contain"
            alt=""
          />
          <div className="pb-4 mt-2">
            <div className="flex flex-col items-center">
              <h1 className="font-semibold mobile:text-xs">Director</h1>
              <h2 className="text-2xl font-medium whitespace-nowrap mobile:text-sm">
                Md. Iqbal Hossain
              </h2>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="grid mobile:grid-cols-1 grid-cols-2 mobile:mt-4 mt-8 gap-4  w-[80%] lg:w-[50%] max-h-[70vh] lg:max-h-[80vh] mx-auto">
        <div className="mobile:hidden grid grid-cols-2 gap-[-2px] grid-rows-2 max-h-[70vh]">
          <div
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-out"
            data-aos-mirror="true"
            // data-aos-offset='100'
            // data-aos-anchor-placement='top-center'
            xs={4}
            className="object-fill"
            // style={{
            //   backgroundImage: `url(${first})`,
            //   backgroundSize: "cover",
            // }}
          >
            <img className="object-fill w-full h-full " src={first} alt="" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-out"
            data-aos-mirror="true"
            xs={4}
            className="object-fill overflow-hidden"
            // style={{
            //   backgroundImage: `url(${second})`,
            //   backgroundSize: "cover",
            // }}
          >
            <img className="object-fill w-full h-full" src={second} alt="" />
          </div>
          <div
            data-aos="fade-right"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-out"
            data-aos-mirror="true"
            xs={4}
            className="object-fill overflow-hidden"
            // style={{
            //   backgroundImage: `url(${third})`,
            //   backgroundSize: "cover",
            // }}
          >
            <img className="object-fill w-full h-full" src={third} alt="" />
          </div>
          <div
            data-aos="fade-left"
            data-aos-delay="50"
            data-aos-duration="1000"
            data-aos-easing="ease-out"
            data-aos-mirror="true"
            xs={4}
            className="object-fill overflow-hidden"
            // style={{
            //   backgroundImage: `url(${fourth})`,
            //   backgroundSize: "cover",
            // }}
          >
            <img className="object-fill w-full h-full " src={fourth} alt="" />
          </div>
        </div>

        <div
          data-aos="fade-left"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-out"
          data-aos-mirror="true"
          xs={4}
          className="grid mobile:w-[70vw] mobile:max-h-fit mobile:mx-auto grid-rows-2 max-h-[70vh]"
        >
          <div className="overflow-hidden ">
            <img
              className="w-full h-full mobile:scale-x-125 lg:scale-x-125"
              src={head2}
              alt=""
            />
          </div>
          <div className="overflow-hidden">
            <img className="object-fill w-full h-full" src={body} alt="" />
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Heroes;
