import React from "react";
// import article2 from "../../assets/img/article2.png";
// import arrow from "../../assets/img/year.png";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/Topbar";
// import head from "../../assets/img/head.png";
// import efg20 from "../../assets/img/efg20.png";
// import gif1 from "../../assets/gif/about_city.gif";
// import gif2 from "../../assets/gif/about_factory.gif";
import liaish_sir from "../../../src/assets/img/Abu_Llaish_Fokir.png";
import bayezid_sir from "../../../src/assets/img/Md. Bayezid Bostami.png";
import masud_sir from "../../../src/assets/img/Md._Masud_Ran.png";
import rakib_sir from "../../../src/assets/img/Rakibul Ahsan.png";
import asad_sir from "../../../src/assets/img/Shah Asad Ali.png";

import mission from "../../../src/assets/img/our_mission.png";
import vision from "../../../src/assets/img/our_vission.png";
import value from "../../../src/assets/img/our_value.png";

import check_circle from "../../../src/assets/icons/check-circle.svg";

import top_banner from "../../assets/img/aboutus-banner.jpg";
import top_banner_mobile from "../../assets/img/about_mobile_top.png";
// import top_badge from "../../assets/img/badge.png";
import Titles from "../../components/titles/Titles";
import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
import useHandleScroll from "../../hooks/useHandleScroll";
import Footer from "../../components/footer/Footer";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/solid";

import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

// Import Swiper styles
import "swiper/scss";
import Card from "../../components/card/Card";

AOS.init();

const heads = [
  {
    id: 1,
    img: liaish_sir,
    name: "Abu Llaish Fokir",
    designation: "Factory Manager",
    field: "Eurasia Food Processing (BD) Ltd",
  },
  {
    id: 2,
    img: bayezid_sir,
    name: "Md. Bayezid Bostami",
    designation: "Head Of IT (EFG BD), Operation Manager",
    field: "EFG BD, EFG Tech",
  },
  {
    id: 3,
    img: masud_sir,
    name: "Md. Masud Rana",
    designation: "Operation Manager",
    field: "Eurasia Food Processing (BD) Ltd",
  },
  {
    id: 4,
    img: asad_sir,
    name: "Shah Asad Ali ",
    designation: "Factory Manager",
    field: "Saidowla Pvt. Enterprise Ltd.",
  },
];

const Aboutus = () => {
  const scrollPosition = useHandleScroll();

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <>
      <TopBar></TopBar>
      <Navbar></Navbar>
      <div id="aboutus" className="">
        {/* quote and the year with chairman sirs logo */}
        {/* <div className="w-[90vw] h-[25vh] tablet:hidden md:h-[30vh] lg:h-[40vh] lg:w-[70vw] md:w-[80vw] xl:w-[60vw] 2xl:w-[60vw] mx-auto"> */}
        {/* <div className="relative flex mt-20">
            <div
              className="md:h-[30vh] lg:h-[40vh] tablet:w-[80vw] w-[50vw] h-[25vh]"
              style={{
                backgroundImage: `url(${article2})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
              }}
            > */}
        {/* <p className="p-10 text-left">
              Euro Foods Group is a leading international manufacturer and
              distributor of frozen and fresh foods, serving the restaurant,
              catering and specialist supermarket sectors. Our marketing Our
              marketing materials and campaigns will always be accurate, to help
              our customers make informed decisions and better use of our goods
              and services.
            </p> */}
        {/* <div className="px-10 text-left">
              <span className="text-left">
                Together – let us grow fruitfully,
              </span>
              <br /> <span>Shelim Hussain</span> <br /> <span>Chairman</span>
              <br /> <span>EURO FOODS GROUP</span>
            </div> */}
        {/* <img
                className="absolute md:w-[15vw] md:right-[10vw] lg:w-[12vw] lg:right-[4vw] 2xl:right-[-5vw] 2xl:w-[9vw] xl:w-[9vw] xl:right-[-5vw] top-0 w-[15vw] right-[10vw] "
                src={head}
                alt=""
              />
              <img
                data-aos="fade-left"
                data-aos-offset="200"
                data-aos-delay="10"
                data-aos-duration="800"
                data-aos-easing="ease-in-out" */}
        {/* // data-aos-mirror="true"
                // data-aos-once="false"
                // data-aos-anchor-placement="top-center"
                className="absolute tablet:right-[13vw] tablet:bottom-4 tablet:h-[6vh] md:right-[10vw] md:bottom-4 lg:right-0 lg:bottom-5 xl:right-[-9vw] 2xl:right-[-9vw] xl:bottom-5 2xl:bottom-5 h-[11vh] w-[35vw] md:h-[8vh] lg:h-[11vh]"
                src={arrow}
                alt=""
              />
            </div>
          </div>
        </div> */}

        {/* gif added */}
        {/* <div className="flex w-3/4 mx-auto mt-8 tablet:hidden">
          <div className="relative basis-1/3">
            <img
              className="w-[23vw] h-[21vh]"
              src="https://i.ibb.co/XC2wnk8/image.png"
              alt=""
            />
            <img
              className="absolute lg:w-[6vw] lg:h-[9vh] md:right-[-1vw] md:bottom-[-4vh] md:w-[7vw] md:h-[9vh] lg:right-[-1vw] lg:bottom-[-4vh] xl:w-[6vw] xl:h-[9vh] xl:right-[-1vw] xl:bottom-[-4vh] 2xl:w-[6vw] 2xl:h-[9vh] 2xl:right-[-0vw] 2xl:bottom-[3vh]"
              src="https://i.ibb.co/XC2wnk8/image.png"
              alt=""
            /> */}
        {/* <div className="absolute bg-[#ECCE81] md:w-[7vw] md:h-[8vh] md:right-[7vw] md:bottom-[-9vh] lg:w-[7vw] lg:h-[10vh] lg:right-[6vw] lg:bottom-[-11vh] xl:w-[7vw] xl:h-[10vh] xl:right-[6vw] xl:bottom-[-11vh] 2xl:w-[7vw] 2xl:h-[10vh] 2xl:right-[6vw] 2xl:bottom-[-6vh]"></div> */}
        {/* </div>
          <div className="mr-8 2xl:mt-3 basis-1/4">
            <div className="2xl:h-[20vh] 2xl:w-[15vw] lg:ml-[1vw] mx-auto flex justify-center items-center ">
              <img src={efg20} alt="" />
            </div> */}
        {/* <div className="absolute bg-[#9C9C30] md:h-[12vh] md:bottom-[-8vh] md:right-[2vw] md:w-[12vw] lg:h-[12vh] lg:bottom-[-8vh] lg:right-[2vw] lg:w-[12vw] xl:h-[12vh] xl:bottom-[-8vh] xl:right-[2vw] xl:w-[12vw] 2xl:h-[12vh] 2xl:bottom-[-5vh] 2xl:right-[2vw] 2xl:w-[10vw] flex justify-center items-center font-bold text-white">
            <h2>
              5+ <br /> Sister Concerns
            </h2>
          </div> */}
        {/* </div>
          <div className="basis-1/3">
            <img src={gif2} alt="" />
          </div>
        </div> */}

        {/* mobile view options */}
        {/* <div className="relative mt-20 mx-auto w-[90vw] tablet:block hidden">
          <div>
            <img className="w-[65vw]" src={article2} alt="" />
          </div>
          <div className="absolute right-0 bottom-2">
            <img className="w-[45vw]" src={arrow} alt="" />
          </div>
          <div className="absolute right-2 top-[0vh] w-[17vw]">
            <img src={head} alt="" />
          </div>
        </div> */}

        {/* <div className="relative mx-auto w-[90vw] mt-3 tablet:block hidden">
          <div className="absolute right-[-4vw] h-[15vh] w-[50vw]">
            <img className="w-2/3 ml-6 h-1/2" src={efg20} alt="" />
          </div>

          <div className="absolute bottom-[-23vh] right-0 h-[15vh] w-[40vw]">
            <img
              className="w-full h-1/2"
              src="https://i.ibb.co/XC2wnk8/image.png"
              alt=""
            />
          </div>

          <div className="absolute mt-1 left-0 h-[15vh] w-[40vw]">
            <img className="w-full h-full" src={gif2} alt="" />
          </div>
        </div>
       */}
      </div>

      {/* top banner section here */}
      <section>
        <div className="">
          {/* banner image */}
          <div className="w-full h-[450px] mobile:h-[220px] relative  ">
            <img
              className="object-cover w-full h-full mobile:hidden"
              src={top_banner}
              alt=""
            />
            <img
              className="hidden object-cover w-full h-full mobile:block"
              src={top_banner_mobile}
              alt=""
            />
            <div className="absolute left-[50%] top-2/4">
              <div className="relative left-[-50%]">
                <h2 className="mx-2 mb-1 text-5xl font-normal text-white mobs:text-2xl tab2:text-4xl desktop:text-5xl mobile:text-3xl">
                  About Us
                  <hr className="py-[2px] mobile:py-[1px] bg-white" />
                </h2>
              </div>
            </div>
          </div>
          {/* <div className="h-[80px] mobs:h-[50px] tab2:h-[60px] tab2:top-[499px] w-full mx-auto absolute top-[489px] mobile:top-[317px] mobs:top-[322px]"> */}
          {/* badge */}
          {/* <img
              className="relative inline-block w-3/4 h-full"
              src={top_badge}
              alt=""
            /> */}
          {/* duration */}
          {/* <div className="absolute w-full mx-auto top-[24%] mobs:top-[18%]">
              <div className="flex justify-between w-2/4 mx-auto tab2:w-[62%]">
                <h2 className="text-4xl font-bold text-white tab2:text-3xl">
                  2002
                </h2>
                <h2 className="text-4xl font-bold text-white tab2:text-3xl">
                  {new Date().getFullYear()}
                </h2>
              </div>
            </div> */}
          {/* </div> */}
        </div>
      </section>

      {/* about us articles */}
      <article className="wide:w-[88%] w-[73%] mx-auto pt-10">
        <div>
          <p className="text-base text-justify mobile:text-sm ">
            <span className="font-bold">
              Euro Foods Group (EFG), Bangladesh
            </span>{" "}
            is one of the leading companies in the food and fish processing
            industry in Bangladesh, who is manufacturing branded generic
            products for local and overseas markets in Asia, Europe, and the
            North American region. EFG adopted the most recent technology to
            formulate and produce generic formulations ensuring optimum quality
            compared to the international brands. <br />
            <br />
            EFG started its operation in Bangladesh on February 5, 2002, by
            establishing a company named{" "}
            <span className="font-bold">EURASIA FOOD PROCESSING (BD) LTD</span>.
            It was a joint venture of the UK and Bangladesh. Key products were
            frozen Paratha & Snacks, Fruits & Vegetables, Sweets & Bakeries,
            Rice and Spices. <br />
            <br />
            Later on, in 2005 Euro Foods Group acquired{" "}
            <span className="font-bold">SAIDOWLA (PVT.) ENTERPRISE LTD</span> in
            Sunamgonj, Sylhet, which is a fish processing supplier. Their key
            products are white water fishes, prawns from lakes, rivers, and
            ponds (Block Frozen, Individual Quick Frozen (IQF)), and 99% export
            to the UK, USA, Canada, Australia, South Korea, Italy, and remaining
            in the local Bangladesh market. <br /> <br />
            Later on, in 2008 Euro Foods Group acquired{" "}
            <span className="font-bold">ROSEMCO FOODS LTD</span> in Niklapur,
            Rupsha, Khulna; which is also a fish processing supplier. Their key
            product is prawns from farms and sea. It exports 99% to the UK, USA,
            Canada, Australia, South Korea, Italy and remaining in the local
            Bangladesh market. <br /> <br />
            In 2021 EFG established its fashion segment.{" "}
            <span className="font-bold">EFG Fashion</span> provides strategic
            intelligence, trend forecasts, actionable insights, and accurate
            product performance data, and sharp creative judgment, designed to
            help our customers get closer to certainty in uncertain times and to
            seize precise opportunities in accelerating markets. Its present
            capacity is 20,000 T-Shirts/M. The company has generated over 150
            employment and plays an important role in the local economy. <br />{" "}
            <br />
            <span className="font-bold">MasalaBazaar</span>, a concern of Euro
            Foods Group established in 2021 in Sylhet, is one of the premium
            super shops of Bangladesh that meets all your domestic shopping
            requirements. Our fresh and frozen produce is sourced and approved
            from our own farms and highly regarded suppliers from around the
            world. <br /> <br />
            Later on, in 2021 Euro Foods Group established{" "}
            <span className="font-bold">EFG AQUA FARMING</span> in Purba Jhiler
            Danga, Khulna, which is a Shrimp farming unit. EFG expanded the
            scope of shrimp farming for maximum output from natural resources
            using minimal environmental impact and options for a sustainable
            future. <br /> <br />
            In 2021, Euro Foods Group entered the tech industry by forming{" "}
            <span className="font-bold">EFG Tech</span>. EFG Tech is a
            professional IT solution provider of Euro Foods Group in Bangladesh.
            It has been involved in the automation of the business process by
            information technology tools. The company is determined to provide
            all sorts of high valued and cost-effective services. We have a
            diverse workforce and we believe this diversity is our strength. We
            are working with Bangladesh and UK-based clients. We are providing
            cloud-based solutions, big data, product development & maintenance,
            UI & UX Design, E-commerce, and ERP services. Our aim is to serve
            the clients with the best possible solutions and gain a firm
            position in the market. <br /> <br />
            Euro Foods Group is engaged with a strategic marketing process that
            involves building customer relationships by creating and delivering
            superior customer value aiming to produce high customer equity. EFG
            successfully and efficiently blends skilled people, resources,
            organizational structure, and decisions for implementing strategies
            at all levels. <br /> <b></b>
            Euro Foods Group is continuously creating innovative ideas in its
            every step of operation to add distinctiveness and competitiveness
            to the products and services.
          </p>
        </div>
      </article>

      {/* Operational Heads */}

      <Titles text="Operational Heads"></Titles>

      <div className="">
        <Swiper
          className="2xl:w-[1400px] w-[1280px] desktop:w-[980px] laptop:w-[768px] tablet:w-[640px] mobile:w-[480px] mobs:w-[360px] mx-auto mt-16 mb-20 relative"
          modules={[Navigation]}
          breakpoints={{
            // when window width is >= 640px
            360: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            480: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            640: {
              width: 640,
              slidesPerView: 2,
              spaceBetween: 110,
            },
            // when window width is >= 768px
            768: {
              width: 768,
              slidesPerView: 3,
            },
            1024: {
              width: 1024,
              slidesPerView: 3,
            },
            1280: {
              width: 1280,
              slidesPerView: 4,
            },
            1535: {
              width: 1535,
              slidesPerView: 5,
            },
          }}
          // navigation
          navigation={{
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }}
          spaceBetween={110}
          loop="true"
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
        >
          {heads.map((item) => (
            <SwiperSlide key={item.id}>
              <Card
                type="heads"
                image={item.img}
                classes="w-72 laptop:w-[260px] tab2:my-1"
              >
                <div>
                  <h2 className="mb-2 text-xl font-bold">{item.name}</h2>
                  <h3 className="text-sm ">{item.designation}</h3>
                  <h3 className="text-sm ">{item.field}</h3>
                </div>
              </Card>
            </SwiperSlide>
          ))}
          <div className="flex items-center justify-center mt-8 text-lg font-semibold tab2:hidden">
            <div
              ref={navigationPrevRef}
              className="flex mr-14 hover:text-green-900"
            >
              <ChevronDoubleLeftIcon className="inline w-5 cursor-pointer" />
              <p className="ml-1 cursor-pointer select-none">Prev</p>
            </div>
            <div ref={navigationNextRef} className="flex hover:text-green-900">
              <p className="mr-1 cursor-pointer select-none">Next</p>
              <ChevronDoubleRightIcon className="inline w-5 cursor-pointer" />
            </div>
          </div>
        </Swiper>
      </div>

      {/* Our Mission, vision, values */}
      <section id="mission" className="wide:w-[88%] w-[73%] mx-auto">
        {/* mission */}
        <Titles text="Our Mission"></Titles>
        <div className="grid grid-cols-12 gap-4 pt-4 mb-8">
          <p className="col-span-6 text-xl text-justify tab2:col-span-12 tab2:order-2 mobile:text-sm">
            To preserve and improve food quality by consistently delivering
            high-quality, safe, and hygiene food products and services that meet
            customer expectations across the globe through current good
            manufacturing practices, state-of-the-art technology, competent
            workforce, and efficient management.
          </p>
          <div className="col-span-6 tab2:col-span-12 tab2:order-1 tab2:h-[250px]">
            <img className="object-cover w-full h-full" src={mission} alt="" />
          </div>
        </div>

        {/* vision  */}
        <Titles text="Our Vision"></Titles>
        <div className="grid grid-cols-12 gap-4 pt-4 mb-8">
          <div className="col-span-6 tab2:col-span-12 tab2:h-[250px]">
            <img className="object-cover w-full h-full" src={vision} alt="" />
          </div>
          <p className="col-span-6 text-xl text-justify tab2:col-span-12 mobile:text-sm">
            To be the leading food processing company in Bangladesh in terms of
            expertise, innovation, and responsible entrepreneurship. We want to
            make a visible presence in international markets with our
            high-quality products & services.
          </p>
        </div>

        {/* value */}
        <Titles text="Our Value"></Titles>
        <div className="grid grid-cols-12 gap-4 pt-4 mb-8">
          {/* <p className="col-span-6 text-xl text-justify tab2:col-span-12 tab2:order-2 mobile:text-sm">

          </p> */}
          <div className="col-span-6 text-xl text-justify tab2:col-span-7 tab2:order-2 mobile:text-sm">
            <div className="flex items-center">
              <img src={check_circle} alt="" />
              <p className="pl-2">Respect people</p>
            </div>
            <div className="flex items-center">
              <img src={check_circle} alt="" />
              <p className="py-3 pl-2">Ensure Justice</p>
            </div>
            <div className="flex items-center">
              <img src={check_circle} alt="" />
              <p className="py-3 pl-2">Innovative approach</p>
            </div>
            <div className="flex items-center">
              <img src={check_circle} alt="" />
              <p className="py-3 pl-2">Delight customers</p>
            </div>
            <div className="flex items-center">
              <img src={check_circle} alt="" />
              <p className="py-3 pl-2">Responsible operations</p>
            </div>
          </div>

          <div className="col-span-6 tab2:col-span-5 tab2:order-1 tab2:h-[250px]">
            <img className="object-cover w-full h-full" src={value} alt="" />
          </div>
        </div>
      </section>

      {/* everything that define su */}
      {/* <div className="mt-20 w-[60%] desktop:w-[88%] mx-auto h-[20rem]">
        <h1 className="text-3xl font-semibold font-[inter] mb-6">
          EVERYTHING THAT DEFINES US
        </h1>
        <p className="text-lg font-medium leading-9 text-justify">
          Our relentless focus on quality and freshness have won us prestigious
          accolades including the Grocer Branded Award, the BAPA Food Pro Award
          and Best Aquaculture Practices Award. However, the award that we
          cherish the most is the one given by our consumers. EFG is recognized
          as one of the most trusted, valuable and popular brands among UK, US
          and BD consumers in various reputed surveys. EFG believes that ‘Taste
          & Trust’ are its sobriquet and will constantly endeavor to make a
          Billion people reach out for a delightful and healthy EFG’s product
          several times a day! Our foot print spreads across UK, USA, Europe
          through exports. And we are investing in a state- of- the- art
          facility in Dhaka & Shylet to service the local markets.
        </p>
      </div>
      <div className="w-[60%] desktop:w-[88%] mx-auto mb-20">
        <h2 className="mb-6 text-xl font-bold mt-14">
          OUR CORE VALUES AND BELIEFS
        </h2>
        <p className="mb-6 text-lg font-medium leading-9 text-justify">
          With harvesting the finest produce from nature comes a solemn
          responsibility to package only the best foodstuffs for families across
          the world. Our seamlessly integrated operations have access and
          control over the entire supply chain from farm to fork.
        </p>
        <div className="grid grid-cols-2 gap-8">
          <div className="font-semibold text-white rounded-lg bg-tertiary-color">
            <h2 className="py-4 font-bold rounded-t-lg bg-tertiary-color ">
              INVESTMENT IN INNOVATION
            </h2>
            <p className="p-6 text-justify">
              Without constantly redefining the limits of how we do business,
              EFG Foods could never stay at the forefront of a challenging food
              industry by constantly innovating our products and processes.
            </p>
          </div>
          <div className="font-semibold text-white rounded-lg bg-tertiary-color">
            <h2 className="py-4 font-bold rounded-t-lg bg-tertiary-color">
              INTEGRATED BUSINESS MODEL
            </h2>
            <p className="p-6 text-justify">
              An uncompromisingly dedicated team implements and monitors
              efficiency initiatives at the back-end to stay competitive in the
              industry.
            </p>
          </div>
          <div className="font-semibold text-white rounded-lg bg-tertiary-color">
            <h2 className="py-4 font-bold rounded-t-lg bg-tertiary-color">
              BRAND FOCUS
            </h2>
            <p className="p-6 text-justify">
              Our steady and sustained focus on creating a vibrant portfolio has
              resulted in the success of several brands like Crown Farms, JOY,
              Rohim.
            </p>
          </div>
          <div className="font-semibold text-white rounded-lg bg-tertiary-color">
            <h2 className="py-4 font-bold rounded-t-lg bg-tertiary-color">
              EXPANDING FOOTPRINT
            </h2>
            <p className="p-6 text-justify">
              A constantly growing distribution network helps us stay a leading
              brand globally. We launched countries this year.
            </p>
          </div>
          <div className="font-semibold text-white rounded-lg bg-tertiary-color">
            <h2 className="py-4 font-bold rounded-t-lg bg-tertiary-color">
              SUSTAINABLE GROWTH
            </h2>
            <p className="p-6 text-justify">
              Outpacing the industry with a major contribution from branded
              specialty food products.
            </p>
          </div>
          <div className="font-semibold text-white rounded-lg bg-tertiary-color">
            <h2 className="py-4 font-bold rounded-t-lg bg-tertiary-color">
              CUSTOMER-CENTRIC PRODUCTS
            </h2>
            <p className="p-6 text-justify">
              Understanding what the customer wants and needs is key to
              providing products that are peerless in quality and offer value
              for money.
            </p>
          </div>
          <div className="font-semibold text-white rounded-lg bg-tertiary-color">
            <h2 className="py-4 font-bold rounded-t-lg bg-tertiary-color">
              EXCELLENCE IN OPERATIONS
            </h2>
            <p className="p-6 text-justify">
              An uncompromisingly dedicated team implements and monitors
              efficiency initiatives at the back-end to stay competitive in the
              industry.
            </p>
          </div>
          <div className="font-semibold text-white rounded-lg bg-tertiary-color">
            <h2 className="py-4 font-bold rounded-t-lg bg-tertiary-color">
              ETHICAL BUSINESS PRACTICES
            </h2>
            <p className="p-6 text-justify">
              Operate processes with sincerity and honesty to deliver the best
              product possible.
            </p>
          </div>
        </div>
      </div> */}
      <Footer></Footer>
    </>
  );
};

export default Aboutus;
