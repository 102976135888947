import React from "react";

const SlideDesc = ({ title, text }) => {
  return (
    <>
      <h3 className="m-10 text-xl text-left desktop:text-lg laptop:text-sm tablet:m-5">
        {title}
      </h3>
      <p className="p-8 text-sm text-left desktop:text-xs desktop:-mt-10 mobs:hidden">
        {text}
      </p>
    </>
  );
};

export default SlideDesc;
