import React, { useCallback, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "./awards.scss";
import Titles from "../../components/titles/Titles";

// import awards1 from "../../assets/img/awards1.png";
// import awards2 from "../../assets/img/Group6.png";
// import awards3 from "../../assets/img/Group8.png";
// import awards4 from "../../assets/img/Group9.png";
// import awards5 from "../../assets/img/Group10.png";
// import awards6 from "../../assets/img/Group11.png";
// import Slide from "../../components/slide/Slide";
import SlideDesc from "../../components/slideDesc/SlideDesc";

import award_1 from "../../assets/icons/award_1.svg";
import award_2 from "../../assets/icons/award_2.svg";
import award_3 from "../../assets/icons/award_3.svg";
import award_4 from "../../assets/icons/award_4.svg";

const Awards = () => {
  const [slideNum, setSlideNum] = useState(1);
  const [slideDirection, setSLideDirection] = useState(1);
  const ref = useRef(null);
  const sliderRef = useRef(null);

  // const nextSlideHandler = () => {
  //   if (slideNum === 6) {
  //     setSlideNum(1);
  //   } else {
  //     setSlideNum(slideNum + 1);
  //   }
  //   setSLideDirection(1);
  // };

  // const prevSlideHandler = () => {
  //   if (slideNum === 1) {
  //     setSlideNum(6);
  //   } else {
  //     setSlideNum(slideNum - 1);
  //   }
  //   console.log(slideNum);
  //   setSLideDirection(0);
  // };

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
    if (slideNum === 1) {
      setSlideNum(3);
    } else {
      setSlideNum(slideNum - 1);
    }
    console.log(slideNum);
    setSLideDirection(0);
  }, [slideNum]);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
    if (slideNum === 3) {
      setSlideNum(1);
    } else {
      setSlideNum(slideNum + 1);
    }
    setSLideDirection(1);
  }, [slideNum]);

  const array = [
    {
      image: award_1,
      title: "HACCP",
      desc: "Hazard Analysis Critical Control Point (HACCP). We've achieved HACCP for ensuring food safety.",
    },
    {
      image: award_2,
      title: "HALAL",
      desc: "We ensuring the Islamic dietary standard, as prescribed in the Shari’ah",
    },
    {
      image: award_3,
      title: "HACCP Seafood",
      desc: "We've achieved BRCGS for product safety management from HACCP",
    },
    {
      image: award_4,
      title: "HACCP BRCGS",
      desc: "Euro Foods Group is a leading international manufacturer and distributor of frozen and fresh foods",
    },
  ];

  return (
    <>
      <div className="mt-10 ">
        <Titles text="Awards &#38; Certificates" />

        <div className="flex flex-row-reverse items-end hidden p-6 m-4 mobile:p-2 mobile:m-2">
          {/* award's images */}

          <div
            // style={contentProps}
            className="flex items-center justify-center mr-6 slide-container basis-2/3 mobile:mr-0 tablet:ml-0 tablet:mr-2"
            ref={ref}
            id="slider"
          >
            <Swiper
              ref={sliderRef}
              className=" w-[50vw] mx-auto tablet:h-[500px] h-[520px] "
              modules={[Navigation]}
              breakpoints={{
                // when window width is >= 640px
                640: {
                  width: 640,
                  slidesPerView: 2,
                },
                // when window width is >= 768px
                768: {
                  width: 768,
                  slidesPerView: 3,
                },
                1024: {
                  width: 768,
                  slidesPerView: 2,
                },
                1280: {
                  width: 1280,
                  slidesPerView: 4,
                },
                1535: {
                  width: 900,
                  slidesPerView: 3,
                },
              }}
              // navigation
              spaceBetween={20}
              // slidesPerView={3}
              loop="true"
            >
              {/* <SwiperSlide>
                <img src={awards1} alt="" />
              </SwiperSlide> */}
              <SwiperSlide>
                <img src="https://i.ibb.co/sKDCCnZ/BRC-Food.png" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img
                  src="https://i.ibb.co/khQ9JLP/Best-Aquaculture-Practices.png"
                  alt=""
                />
              </SwiperSlide>
              {/* <SwiperSlide>
                <img src={awards4} alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src={awards5} alt="" />
              </SwiperSlide> */}
              <SwiperSlide>
                <img
                  src="https://i.ibb.co/NV7T62Z/HACCP-Certification.png"
                  alt=""
                />
              </SwiperSlide>
            </Swiper>
            {/* {slideNum === 1 && (
              <Slide
                slideDirection={slideDirection}
                state={slideNum}
                image1={awards1}
                image2={awards2}
                image3={awards3}
              />
            )}
            {slideNum === 2 && (
              <Slide
                slideDirection={slideDirection}
                state={slideNum}
                image1={awards3}
                image2={awards1}
                image3={awards4}
              />
            )}
            {slideNum === 3 && (
              <Slide
                slideDirection={slideDirection}
                state={slideNum}
                image1={awards4}
                image2={awards3}
                image3={awards5}
              />
            )}
            {slideNum === 4 && (
              <Slide
                slideDirection={slideDirection}
                state={slideNum}
                image1={awards5}
                image2={awards4}
                image3={awards6}
              />
            )}
            {slideNum === 5 && (
              <Slide
                slideDirection={slideDirection}
                state={slideNum}
                image1={awards6}
                image2={awards5}
                image3={awards2}
              />
            )}
            {slideNum === 6 && (
              <Slide
                slideDirection={slideDirection}
                state={slideNum}
                image1={awards2}
                image2={awards6}
                image3={awards1}
              />
            )} */}
          </div>

          {/* award's description box */}
          <div className="basis-1/3 relative tablet:h-[500px] h-[520px] ">
            <div className="font-bold text-black">
              {slideNum === 1 && (
                <SlideDesc title="1. BRC FOOD CERTIFICATED" text="" />
              )}
              {slideNum === 2 && (
                <SlideDesc title="2. BEST AQUACULTURE PRACTICES" text="" />
              )}
              {slideNum === 3 && (
                <SlideDesc title="3. HACCP CERTIFIED" text="" />
              )}
              {slideNum === 4 && (
                <SlideDesc
                  title="4. INTERNATIONAL HALAL INTEGRITY ALLIANCE"
                  text=""
                />
              )}
              {slideNum === 5 && (
                <SlideDesc title="5. MATRIX QAS INTERNATIONAL" text="" />
              )}
              {slideNum === 6 && (
                <SlideDesc title="6. HACCP CERTIFIED" text="" />
              )}

              {/* slider Icons */}
              <div className="absolute flex justify-start bottom-16 left-16 wrap-slider mobile:mt-1 mobs:mt-20 laptop:left-8">
                {/* prev */}
                <div
                  className="box bg-[#ECF1F2] h-[80px] w-[90px] flex items-center justify-center desktop:h-[70px] desktop:w-[80px] laptop:h-[60px] laptop:w-[70px]"
                  role="button"
                  onClick={handlePrev}
                  // onClick={prevSlideHandler}
                  tabIndex="0"
                >
                  <div>
                    <span>
                      <svg
                        className="rotate-180"
                        width="33"
                        height="24"
                        viewBox="0 0 33 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.31 12h-30M19.81 23l11-11-11-11"
                          stroke="#000"
                          strokeWidth="2"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>

                {/* vertical line */}
                <div className="w-[1px] h-[80px] bg-white desktop:h-[70px] laptop:h-[60px]"></div>

                {/* next */}
                <div
                  className="box bg-[#ECF1F2] h-[80px] w-[90px] flex items-center justify-center desktop:h-[70px] desktop:w-[80px] laptop:h-[60px] laptop:w-[70px]"
                  role="button"
                  onClick={handleNext}
                  // onClick={nextSlideHandler}
                  tabIndex="0"
                >
                  <div>
                    <span>
                      <svg
                        width="33"
                        height="24"
                        viewBox="0 0 33 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M30.31 12h-30M19.81 23l11-11-11-11"
                          stroke="#000"
                          strokeWidth="2"
                        ></path>
                      </svg>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* revised design */}
        <div className="grid mx-52 grid-cols-12 mt-[100px] mb-16 wide:mx-24 laptop:mx-40 tablet:mx-16 gap-x-4 2xl:gap-x-24  gap-y-28 mobs:mx-6">
          {array.map((items, index) => (
            <div
              key={items.image}
              className="h-[304px] hover:bg-green-900 rounded-xl relative border-green-900 border-[1.5px] transition-colors col-span-3 laptop:col-span-6 mobile:col-span-6 mobile:h-[218px] group hover:animate-wiggle"
            >
              <img
                src={items.image}
                alt={index}
                className="absolute left-[50%] -top-20 mobile:-top-14 -translate-x-1/2 mobile:w-1/2 mobile:h-1/2"
              />
              <div className="relative h-[70%]">
                <p className="mt-24 mobile:mt-14 mobs:mt-12 text-xl font-bold tracking-[.1rem] mobile:text-sm mobs:text-[10px] group-hover:text-white words">
                  {items.title}
                </p>

                <div className="mx-auto text-center w-[90%] mt-10">
                  <p className="laptop:text-sm mobile:text-xs group-hover:text-white">
                    {items.desc}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Awards;
