import React from "react";
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/Topbar";
import useHandleScroll from "../../hooks/useHandleScroll";
import Feedback from "../../layout/feedBack/Feedback";
import banner from "../../../src/assets/img/contact_us_banner.jpg";
import Titles from "../../components/titles/Titles";
import MapIcon from "../../../src/assets/icons/map-pin.svg";
import PhoneIcon from "../../../src/assets/icons/phone.svg";
import EmailIcon from "../../../src/assets/icons/mail.svg";

const ContactUs = () => {
  const size = useHandleScroll();
  document.title = "Contact US";
  return (
    <div id="contactus">
      <TopBar></TopBar>
      <Navbar sPosition={size}></Navbar>
      {/* top banner for contact us */}
      <section className="relative">
        <div className=" w-full h-[400px] mobile:h-[250px]">
          <img className="object-cover w-full h-full" src={banner} alt="" />
        </div>
        <div className="absolute left-1/2 top-2/4">
          <div className="relative left-[-50%]">
            <h2 className="mx-2 mb-1 text-5xl font-normal text-white mobs:text-2xl tab2:text-4xl desktop:text-5xl mobile:text-3xl">
              Contact Us
              <hr className="py-[2px] mobile:py-[1px] bg-white" />
            </h2>
          </div>
        </div>
      </section>

      <Titles text="Our Address"></Titles>
      {/* address section */}
      <div className="grid items-center grid-cols-3 gap-4 justify-items-center wide:w-[88%] w-[73%] mx-auto">
        <h5 className="py-4 text-xl font-semibold tab2:hidden">Find Us</h5>
        <h5 className="py-4 text-xl font-semibold tab2:hidden">
          Help Line Number
        </h5>
        <h5 className="py-4 text-xl font-semibold tab2:hidden">
          Email Address
        </h5>
        <div className="flex flex-col tab2:w-2/3 mobile:w-11/12 tab2:col-span-3">
          {/* location */}
          <h5 className="hidden py-4 text-lg font-semibold tab2:block mobile:text-sm">
            Find Us
          </h5>
          <div className="flex items-center">
            <img
              className=" mobile:h-[18px] mobile:w-[18px] h-[25px] w-[30px] object-cover"
              src={MapIcon}
              alt=""
            />
            <p className="pl-3 text-sm mobile:text-xs">
              Euro Foods Group - Bangladesh Operations, Head Office 7th Floor
              (Navana Oval, Plot - 5) , Sonargaon Janapath, Uttara, Dhaka 1230.
            </p>
          </div>
        </div>
        <div className="flex flex-col tab2:col-span-3">
          {/* phone */}
          <h5 className="hidden py-4 text-lg font-semibold tab2:block mobile:text-sm">
            Help Line Number
          </h5>
          <div className="flex items-center">
            <img
              className="h-[25px] w-[30px] mobile:h-[18px] mobile:w-[18px] object-cover"
              src={PhoneIcon}
              alt=""
            />
            <p className="pl-3 text-sm mobile:text-xs">
              +880248955205 <br />
              +880248955206
            </p>
          </div>
        </div>
        <div className="flex flex-col tab2:col-span-3">
          {/* phone */}
          <h5 className="hidden py-4 text-lg font-semibold tab2:block mobile:text-sm">
            Email Address
          </h5>
          <div className="flex items-center">
            <img
              className="h-[25px] w-[30px] mobile:h-[18px] mobile:w-[18px] object-cover"
              src={EmailIcon}
              alt=""
            />
            <p className="pl-3 text-sm mobile:text-xs">info@eurofoods-bd.com</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center justify-center">
        <h5 className="pt-10 text-lg font-semibold mobile:text-sm">
          Business Hour
        </h5>
        <p className="pt-3 pb-4 text-lg font-semibold mobile:pb-0 tab2:w-2/3 mobile:w-3/4 mobile:text-xs">
          Our Customer Care team is available from 8 AM-10 PM at the following
          avenues:
        </p>
      </div>

      <div className="mt-20 mobile:mt-4">
        <Feedback type="contactus"></Feedback>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ContactUs;
