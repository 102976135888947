import React from "react";
import GalaryCommon from "../../components/gallerycommon/GalaryCommon";

const AllGalary = () => {
  return (
    <div className="mt-5">
      <GalaryCommon></GalaryCommon>
    </div>
  );
};

export default AllGalary;
