import React, { useEffect, useState } from "react";

import build from "../../assets/icons/build.svg";
import visitor from "../../assets/icons/visitor.svg";
import distributed from "../../assets/icons/distributed.svg";
import job_search from "../../assets/icons/job_search.svg";
import ShowcaseCard from "../../components/showcaseCard/ShowcaseCard";

import youtube from "../../assets/icons/youtube_logo1.svg";
import icon_play from "../../assets/gif/play_icon.gif";
import { AllContext } from "../../hooks/useContextApi";
import Modal from "../../components/modal/Modal";

// import aqua_banner from "../../assets/img/aqua_banner.png";
// import rosemco_banner from "../../assets/img/rosemco_banner.png";
// import saidola_banner from "../../assets/img/saidola_banner.png";
// import masala_banner from "../../assets/img/masala_banner.png";
// import fashion_banner from "../../assets/img/fashion_banner.png";
// import eurasia_banner from "../../assets/img/eurasia_banner.png";
// import tech_banner from "../../assets/img/tech_banner.png";

const Showcase = () => {
  // const [open, setOpen] = useState(false);
  const [slide, setSlide] = useState(1);
  const [stopTimer, setStopTimer] = useState(false);
  const values = React.useContext(AllContext);

  const array = [
    { image: build, title: "Start 2002" },
    { image: visitor, title: "Sister Companies 9+" },
    { image: distributed, title: "Own Product Variant 571+" },
    { image: job_search, title: "Employees 1200+" },
  ];

  useEffect(() => {
    let timer1;
    if (stopTimer === false) {
      timer1 = setTimeout(() => {
        if (slide === 4) {
          setSlide(1);
        } else {
          setSlide(slide + 1);
        }
      }, 4500);
      // setSlide(1);
      setStopTimer(false);
    } else if (stopTimer === true) {
      clearTimeout(timer1);
      setStopTimer(true);
    }

    return () => {
      clearInterval(timer1);
    };
  }, [slide, stopTimer]);

  return (
    <div className="relative ">
      {/* upper-section */}
      <div className="flex z-50 h-[550px] laptop:h-[600px]">
        {/* left side writings */}
        <div className="px-32 py-20 font-bold laptop:px-4 text-left 2xl:w-[40%] w-[30%] wide:w-[50%] laptop:w-full laptop:z-20">
          <h1 className="text-5xl tracking-wider mobile:tracking-wide laptop:text-2xl mobile:text-lg">
            <span className="mr-4 mobile:mr-1 text-primary-color">EURO</span>
            <span className="text-secondary-color">FOODS</span>
          </h1>
          <h1 className="my-4 text-5xl font-normal tracking-wider mobile:my-1 mobile:text-lg laptop:text-2xl">
            GROUP
          </h1>
          <p className="font-medium laptop:absolute laptop:bottom-28 laptop:mx-16 mobile:!mx-0 tablet:bottom-44 mobile:bottom-52 mobile:text-sm mobs:text-justify mobile:pr-2 mobs:mb-2">
            Euro Foods Group is a 100% export-oriented company since 2002. We've
            achieved many recognitions from national and international
            regulators for our quality and best practices
          </p>
          <button
            onClick={() => values.openModal()}
            className="relative flex flex-col justify-center items-center z-[40] h-[140px] hover:text-blue-600 mr-auto"
          >
            <img className="h-full pr-2 shimmer" src={icon_play} alt="" />
            <h2 className="absolute block font-bold bottom-5 mobile:hidden">
              Anniversary Wish
            </h2>
          </button>
        </div>
      </div>
      {/* right side image slider */}
      <div className="absolute h-[550px] laptop:h-[400px] tablet:h-[350px] mobile:h-[300px] w-[90%] wide:w-[80%] top-0 right-0 overflow-hidden laptop:w-[100%] mobile:w-[100%] mobs:w-full">
        <div className="relative z-20 h-full laptop:z-0">
          <ShowcaseCard slide={slide}></ShowcaseCard>
        </div>
      </div>
      {/* <div className="md:hidden">
        <p className="">
          Euro Foods Group is a 100% export-oriented company since 2002. We've
          achieved many recognitions from national and international regulators
          for our quality and best practices
        </p>
      </div> */}
      {/* bottom icon's box */}
      <div className="absolute w-4/5 mobs:w-full mobs:border-x-0 mobs:rounded-none -translate-x-1/2 border-[1px] tablet:border-none tablet:bg-transparent border-black rounded-xl left-1/2 -bottom-14 mobile:-bottom-8 z-20 bg-white py-4">
        {/* icons grid */}
        <div className="grid grid-cols-12 gap-4">
          <div className="col-span-1 row-span-2 md:hidden"></div>
          {array.map((items, index) => (
            <div
              key={items.title}
              className="flex flex-col items-center justify-center col-span-3 tablet:col-span-5"
            >
              <img src={items.image} alt={index} />
              <p className="mobile:text-xs">{items.title}</p>
            </div>
          ))}
          <div className="col-span-1 col-start-12 row-span-2 row-start-1 md:hidden"></div>
        </div>
      </div>
      {values.open && (
        <div className="fixed left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-50 modal-transition">
          <Modal
            open={values.open}
            type="video"
            closeFunc={values.closeModal}
            videoLink="https://masalabazaar.blob.core.windows.net/videos/wishing_video.mp4"
          ></Modal>
        </div>
      )}
    </div>
  );
};

export default Showcase;
