import React from "react";
import "./Topbar.scss";
import efgLogo from "../../assets/img/efglogo.png";
const TopBar = ({ isProduct }) => {
  return (
    <>
      <nav className="flex justify-between items-center h-[80px] bg-[#FFF6EA] px-16 mobs:px-4 hidden">
        <div className="mt-1">
          <a href="/home" className="logo ">
            <img src={efgLogo} alt="euro-food-logo" className="h-25 w-60" />
          </a>
        </div>
        {/* //  <!-- Social Icons --> */}
        <div>
          <ul className="flex justify-between w-[200px] social_icons items-end mobs:w-[160px]">
            <li>
              <a href="https://www.facebook.com/eurofoodsgroup">
                <span>
                  <svg
                    className={
                      isProduct
                        ? "fill-white hover:fill-green-500 hover:transition-colors "
                        : "hover:fill-green-500 hover:transition-colors"
                    }
                    x="0px"
                    y="0px"
                    width="23"
                    height="23"
                    viewBox="0 2 24 24"
                  >
                    <path d="M17.525,9H14V7c0-1.032,0.084-1.682,1.563-1.682h1.868v-3.18C16.522,2.044,15.608,1.998,14.693,2 C11.98,2,10,3.657,10,6.699V9H7v4l3-0.001V22h4v-9.003l3.066-0.001L17.525,9z"></path>
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/eurofoodsgroup">
                <span>
                  <svg
                    className={
                      isProduct
                        ? "fill-white hover:fill-green-500 hover:transition-colors "
                        : "hover:fill-green-500 hover:transition-colors"
                    }
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 30 30"
                  >
                    <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"></path>
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCWmbqmPt6a6fhlsyXeEvodQ">
                <span>
                  <svg
                    className={
                      isProduct
                        ? "fill-white hover:fill-green-500 hover:transition-colors "
                        : "hover:fill-green-500 hover:transition-colors"
                    }
                    x="0px"
                    y="0px"
                    width="26"
                    height="26"
                    viewBox="0 0 24 24"
                  >
                    <path d="M 12 4 C 12 4 5.7455469 3.9999687 4.1855469 4.4179688 C 3.3245469 4.6479688 2.6479687 5.3255469 2.4179688 6.1855469 C 1.9999687 7.7455469 2 12 2 12 C 2 12 1.9999687 16.254453 2.4179688 17.814453 C 2.6479687 18.675453 3.3255469 19.352031 4.1855469 19.582031 C 5.7455469 20.000031 12 20 12 20 C 12 20 18.254453 20.000031 19.814453 19.582031 C 20.674453 19.352031 21.352031 18.674453 21.582031 17.814453 C 22.000031 16.254453 22 12 22 12 C 22 12 22.000031 7.7455469 21.582031 6.1855469 C 21.352031 5.3255469 20.674453 4.6479688 19.814453 4.4179688 C 18.254453 3.9999687 12 4 12 4 z M 12 6 C 14.882 6 18.490875 6.1336094 19.296875 6.3496094 C 19.465875 6.3946094 19.604391 6.533125 19.650391 6.703125 C 19.891391 7.601125 20 10.342 20 12 C 20 13.658 19.891391 16.397875 19.650391 17.296875 C 19.605391 17.465875 19.466875 17.604391 19.296875 17.650391 C 18.491875 17.866391 14.882 18 12 18 C 9.119 18 5.510125 17.866391 4.703125 17.650391 C 4.534125 17.605391 4.3956094 17.466875 4.3496094 17.296875 C 4.1086094 16.398875 4 13.658 4 12 C 4 10.342 4.1086094 7.6011719 4.3496094 6.7011719 C 4.3946094 6.5331719 4.533125 6.3946094 4.703125 6.3496094 C 5.508125 6.1336094 9.118 6 12 6 z M 10 8.5351562 L 10 15.464844 L 16 12 L 10 8.5351562 z"></path>
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/in/eurofoods/">
                <span>
                  <svg
                    className={
                      isProduct
                        ? "fill-white hover:fill-green-500 hover:transition-colors "
                        : "hover:fill-green-500 hover:transition-colors"
                    }
                    x="0px"
                    y="0px"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21,3H3v18h18V3z M9,17H6.477v-7H9V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2 c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302 s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977C13.93,10.407,14.581,10,15.802,10 C17.023,10,18,10.977,18,13.174V17z"></path>
                  </svg>
                </span>
              </a>
            </li>
            <li>
              <a href="">
                <span>
                  <svg
                    className={
                      isProduct
                        ? "fill-white hover:fill-green-500 hover:transition-colors "
                        : "hover:fill-green-500 hover:transition-colors"
                    }
                    fill="#000000"
                    viewBox="0 0 50 50"
                    width="24px"
                    height="24px"
                  >
                    <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z" />
                  </svg>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
};

export default TopBar;
