import AOS from "aos";
import "aos/dist/aos.css";
import React from "react";
// import Card from "../card/Card";
import insider_pic from "../../assets/img/insider-pic.png";
import express_pic from "../../assets/img/express-pic.png";
import express_pic2 from "../../assets/img/express-pic2.png";
import calender from "../../assets/icons/calendar.svg";
// import Button from "../button/Button";
import Titles from "../titles/Titles";
import "./News.scss";
// import arrow from "../../assets/icons/";

AOS.init();

const blogs = [
  {
    id: 1,
    isDesktop: true,
    title: "Wales Business Insider",
    source: "Insider Media",
    news: `One of the UK’s biggest suppliers of food to the Indian restaurant trade is planning to acquire businesses as it targets turnover of £500m.EFG Food & Tech Holdings, which trades as Euro Foods Group, is on course to make a £6m profit on £110m turnover for 2021, managing director Shelim Hussain told Insider.The Cwmbran company, which supplies meat, fish, samosas and other products to restaurants, made a £4.5m profit on turnover of £94m in 2020.
    Hussain said Euro Foods is targeting sales £500m within ten years.
    Interviewed in Wales Business Insider magazine, Hussain said: If we are to go to £500m, we will need lots of cash. So I am looking at flotation or debt – considering which is the best way.`,
    link: "https://www.insidermedia.com/news/wales/acquisitions-on-the-menu-for-restaurant-supplier",
    date: "3 Dec 2021",
    img: insider_pic,
  },
  {
    id: 2,
    isDesktop: false,
    title: "Comfort food given to Walsall Manor Hospital staff",
    source: "Express & Star",
    news: `Euro Foods Group, based in Darlaston, has donated food to the ICU and other NHS staff at Walsall Manor Hospital. Mohammed Habibus Samad and Moulana Abdur Rahim Uddin, from Euro Foods Group with ICU matron Angela Dixon and fundraising manager Georgie Westley.`,
    link: "https://www.expressandstar.com/news/local-hubs/walsall/2021/02/03/comfort-food-given-to-walsall-manor-hospital-staff/",
    date: "3 Feb 2021",
    img: express_pic,
  },
  {
    id: 3,
    isDesktop: false,
    title: "Snacks donated to fuel front line",
    source: "Express & Star",
    news: `Eurofoods Darlaston hands over food to staff at Darlaston Health Centre, with respiratory nurse Annette Stubb and clinical lead Kathryn Bevan accepting the food from Shaun Garbett from Eurofoods Darlaston depot`,
    link: "https://www.expressandstar.com/news/health/2021/02/11/snacks-donated-to-fuel-front-line/",
    date: "11 Feb 2021",
    img: express_pic2,
  },
];

const News = () => {
  return (
    <div className="my-20">
      <Titles text="News & Articles"></Titles>
      <div className="grid grid-cols-3 tablet:grid-cols-1 desktop:grid-cols-2 tablet:gap-16 desktop:gap-8  gap-16 w-[85%] tablet:w-[60%] mobile:w-[75%] mobs:w-[90%] mx-auto pt-10">
        {blogs.map((item) => (
          <div
            key={item.id}
            className={
              item.isDesktop
                ? "desktop:w-3/5 tablet:w-full tablet:col-span-1 desktop:mx-auto desktop:col-span-2"
                : "w-full"
            }
          >
            {/* image part */}
            <div className="">
              <img className="object-contain w-full" src={item.img} alt="" />
            </div>
            {/* article part */}
            <article className="w-11/12 mx-auto relative drop-shadow-md bg-white rounded-[20px] mt-[-80px] p-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img src={calender} alt="" />
                  <h5 className="pl-2 text-sm font-medium mobile:text-sm">
                    {item.date}
                  </h5>
                </div>
                <div>
                  <h5 className="text-sm font-medium mobile:text-sm">
                    Source: {item.source}
                  </h5>
                </div>
              </div>

              <p className="py-5 text-xs text-justify ellipse mobile:text-xs">
                {item.news}
              </p>
              <div className="flex items-center justify-end ">
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="py-5 mr-1 text-sm font-bold mobile:py-3 desktop:py-2 mobile:text-sm"
                  href={item.link}
                >
                  Read full article
                </a>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                  <path
                    fillRule="evenodd"
                    d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </article>
          </div>
        ))}
      </div>
    </div>

    // <div className="w-full my-20 overflow-x-hidden">
    //   <Titles text="NEWS & ARTICLES"></Titles>
    //   <div className="flex flex-col md:flex-col lg:flex-row sm:flex-col  justify-center w-[90vw] mt-10 mx-auto max-h-fit">
    //     <div
    //       data-aos="fade-right"
    //       data-aos-delay="50"
    //       data-aos-duration="1000"
    //       xs={4}
    //       className="lg:w-[60%] sm:w-[100%] md:w-[100%] w-[100%] lg:max-h-full"
    //     >
    //       <Card
    //         type="newsOnly"
    //         image={insider}
    //         classes="h-[30vh] flex flex-col justify-end items-center lg:h-[100%] lg:mr-3 lg:p-4 lg:pb-5 md:p-3 sm:p-3 mobile:p-3 mobile:mb-2 sm:mb-2"
    //       >
    //         <h3 className="font-bold text-white lg:py-2 lg:mb-2 lg:text-xl sm:mb-0 sm:py-0 md:mb-0 md:py-1 mobile:mb-0 mobile:py-1">
    //           Wales Business Insider
    //         </h3>
    //         <p className="hidden text-white lg:block">
    //           One of the UK’s biggest suppliers of food to the Indian restaurant
    //           trade is planning to acquire businesses as it targets turnover of
    //           £500m. EFG Food & Tech Holdings, which trades as Euro Foods Group,
    //           is on course to make a £6m profit on £110m turnover for 2021,
    //           managing director Shelim Hussain told Insider.
    //         </p>
    //         <Button
    //           text="READ MORE"
    //           type="submit"
    //           isButton={1}
    //           classes="mobile:py-2 mobile:px-4 mobile:mt-0 mobile:text-sm mobile:rounded-[20px] lg:py-2 lg:text-lg lg:mt-2 lg:px-6 lg:font-medium lg:rounded-[0px] sm:py-2 sm:px-4 sm:mt-0 sm:text-sm sm:rounded-[0px] md:py-2 md:px-4 md:mt-0 md:text-sm md:rounded-[20px]"
    //         ></Button>
    //       </Card>
    //     </div>
    //     <div
    //       data-aos="fade-left"
    //       data-aos-delay="50"
    //       data-aos-duration="1000"
    //       xs={4}
    //       className="lg:w-[40%] lg:h-[65vh] sm:w-[100%] md:w-[100%]"
    //     >
    //       <Card
    //         type="newsOnly"
    //         image={walsall}
    //         classes="h-[30vh] p-4 pb-10 mb-2 flex flex-col justify-end items-center lg:h-[49%] lg:mr-3 lg:p-4 lg:pb-5 md:p-3 sm:p-3 mobile:p-3 mobile:mb-2 sm:mb-2"
    //       >
    //         <h3 className="font-bold text-white lg:py-2 lg:mb-2 lg:text-xl sm:mb-0 sm:py-0 md:mb-0 md:py-1 mobile:mb-0 mobile:py-1">
    //           Comfort food given to Walsall Manor Hospital staff
    //         </h3>
    //         <Button
    //           type="submit"
    //           isButton={1}
    //           text="READ MORE"
    //           classes="mobile:py-2 mobile:px-4 mobile:mt-0 mobile:text-sm mobile:rounded-[20px] lg:py-2 lg:text-lg lg:mt-2 lg:px-6 lg:font-medium lg:rounded-[0px] sm:py-2 sm:px-4 sm:mt-0 sm:text-sm sm:rounded-[0px] md:py-2 md:px-4 md:mt-0 md:text-sm md:rounded-[20px]"
    //         ></Button>
    //       </Card>
    //       <Card
    //         type="newsOnly"
    //         image={snacks}
    //         classes="h-[30vh] lg:h-[49%] mt-2 flex flex-col justify-end items-center p-4 pb-10 lg:mr-3 lg:p-4 lg:pb-5 md:p-3 sm:p-3 mobile:p-3 mobile:mb-2 sm:mb-2"
    //       >
    //         <h3 className="font-bold text-white lg:py-2 lg:mb-2 lg:text-xl sm:mb-0 sm:py-0 md:mb-0 md:py-1 mobile:mb-0 mobile:py-1">
    //           Snacks donated to fuel front line
    //         </h3>
    //         <Button
    //           type="submit"
    //           isButton={1}
    //           text="READ MORE"
    //           classes="mobile:py-2 mobile:px-4 mobile:mt-0 mobile:text-sm mobile:rounded-[20px] lg:py-2 lg:text-lg lg:mt-2 lg:px-6 lg:font-medium lg:rounded-[0px] sm:py-2 sm:px-4 sm:mt-0 sm:text-sm sm:rounded-[0px] md:py-2 md:px-4 md:mt-0 md:text-sm md:rounded-[20px]"
    //         ></Button>
    //       </Card>
    //     </div>
    //   </div>
    // </div>
  );
};

export default News;
