import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";
import galary_banner from "../../assets/img/galary_banner.png";
import Navbar from "../../components/navbar/Navbar";
import TopBar from "../../components/topbar/Topbar";
import useHandleScroll from "../../hooks/useHandleScroll";
import AllGalary from "../allgalary/AllGalary";

import maintanance from "../../assets/img/under-maintenance.jpg";
import Footer from "../../components/footer/Footer";
import { useContext } from "react";
import { AllContext } from "../../hooks/useContextApi";
import Modal from "../../components/modal/Modal";

const routes = [
  {
    name: "All Projects",
    path: "allprojects",
    current: false,
  },
  {
    name: "Eurasia",
    path: "eurasia",
    current: false,
  },
  {
    name: "Rosemco",
    path: "rosemco",
    current: false,
  },
  {
    name: "EFG Fashion",
    path: "efgf",
    current: false,
  },
  {
    name: "Saidowla",
    path: "saidowla",
    current: false,
  },
  {
    name: "Masalabazaar",
    path: "masalabazaar",
    current: false,
  },
  {
    name: "EFG Tech",
    path: "efgt",
    current: false,
  },
  {
    name: "EFG Aqua",
    path: "efgaqua",
    current: false,
  },
];

const Galary = () => {
  const [active, setActive] = useState([...routes]);
  const location = useLocation();

  const values = useContext(AllContext);
  // console.log(location);

  const size = useHandleScroll();

  useEffect(() => {
    const updateRoutes = active.map((data) => {
      if (location.pathname.includes(data.path)) {
        data.current = true;
      } else {
        data.current = false;
      }
      return data;
    });

    setActive(updateRoutes);
  }, [location.pathname]);

  return (
    <div
      className={
        values.openImg
          ? "blur pointer-events-none overflow-hidden  w-[100vw]"
          : ""
      }
    >
      <TopBar></TopBar>
      <Navbar sPosition={size}></Navbar>
      <div className="hidden">
        {/* banner section */}
        <section className="relative">
          <div className=" w-full h-[400px] mobile:h-[250px]">
            <img
              className="object-cover w-full h-full"
              src={galary_banner}
              alt=""
            />
          </div>
          <div className="absolute left-1/2 top-2/4">
            <div className="relative left-[-50%]">
              <h2 className="mx-2 mb-1 text-5xl font-normal text-white mobs:text-2xl tab2:text-4xl desktop:text-5xl mobile:text-3xl">
                Gallery
                <hr className="py-[2px] mobile:py-[1px] bg-white" />
              </h2>
            </div>
          </div>
        </section>

        {/* internal routes */}

        <div className="flex justify-center mt-10 tab1:mt-0">
          <nav className="flex justify-between items-center bg-[#FFCD72] h-11 tab1:child:px-5 child:whitespace-nowrap tab1:!w-[1023px] tab1:overflow-x-scroll w-2/3 tab1:px-[0px] px-5 tab1:rounded-none rounded-md text-xs font-bold child-hover:text-[#006A4E]">
            {active.map((item) => (
              <Link
                key={item.path}
                to={item.path}
                className={
                  item.current ||
                  (location.pathname === "/gallery" &&
                    item.path === "allprojects")
                    ? "text-[#006A4E]"
                    : ""
                }
              >
                {item.name}
              </Link>
            ))}
          </nav>
        </div>
        <div>
          {location.pathname === "/gallery" ? <AllGalary /> : <Outlet />}
        </div>
      </div>
      <div className="w-[100vw] h-[100vh]">
        <img className="w-full h-full" src={maintanance} alt="" />
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Galary;
