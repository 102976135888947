import React, { useContext, useState } from "react";
import "./VideoCard.scss";

import play_button from "../../assets/gif/play_button.gif";
import Modal from "../modal/Modal";
import { AllContext } from "../../hooks/useContextApi";

const VideoCard = ({ bg }) => {
  // const values = React.useContext(AllContext);
  // const [open, setOpen] = useState(false);

  // const openModal = () => {
  //   setOpen(true);
  // };
  // const closeModal = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <div className="flex flex-col w-full tab2:w-[300px] h-[270px] rounded-[5px] overflow-hidden">
        <div className="container h-3/5 bg-[#FFCD72]">
          {/* <img className="object-cover w-full h-full image" src={bg} alt="" /> */}
          <video id="vid" width="100%" height="auto" controls>
            <source
              src="https://masalabazaar.blob.core.windows.net/videos/wishing_video.mp4"
              type="video/mp4"
            />
          </video>
          {/* <div class="middle">
            <div class="text">
              <button
                onClick={() => values.openVideoModal()}
                className="relative flex flex-col justify-center items-center z-[40] h-[140px]  mr-auto"
              >
                <img
                  className="rounded-[50%] h-[80px] w-[80px]"
                  src={play_button}
                  alt=""
                />
              </button>
              
            </div>
          </div> */}
        </div>
        <div className="p-2 h-2/5 bg-[#FFCD72]">
          <h2 className="pt-1 pb-1 text-base font-semibold text-left">
            All Projects
          </h2>
          <p className="text-xs text-left">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet.
          </p>
        </div>
      </div>
      {/* {values.openVideo && (
        <div className="fixed left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%] z-50 modal-transition">
          <Modal
            open={values.openVideo}
            closeFunc={values.closeVideoModal}
            videoLink="https://masalabazaar.blob.core.windows.net/videos/wishing_video.mp4"
          ></Modal>
        </div>
      )} */}
    </>
  );
};

export default VideoCard;
