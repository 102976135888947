import React from "react";
import { useLocation } from "react-router-dom";
import CompanyPage from "../../components/companypage/CompanyPage";

const Saidowla = ({ galary }) => {
  const location = useLocation();
  return (
    <>
      {galary ? (
        <div id="saidowla">
          <CompanyPage pathname={location.pathname}></CompanyPage>
        </div>
      ) : (
        <div className="mt-20">this is saidowla galary</div>
      )}
    </>
  );
};

export default Saidowla;
