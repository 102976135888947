import { Link, NavLink, useNavigate } from "react-router-dom";
import efgLogo from "../../assets/icons/efglogo.svg";
import "./navbar.scss";
// import useHandleScroll from "../../hooks/useHandleScroll";

/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState, useRef, useEffect } from "react";
// import { ChevronDownIcon } from "@heroicons/react/solid";
import { Disclosure, Menu, Transition, Popover } from "@headlessui/react";
import { BellIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
// import Button from "../button/Button";
import PopOverBtn from "../PopOverBtn.js/PopOverBtn";

const navigation = [
  { name: "HOME", link: "/", current: true },
  { name: "ABOUT US", link: "/aboutus", current: false },
  { name: "PRODUCTS", link: "/products", current: false },
  { name: "efgLOGO", link: "/", current: false },
  { name: "COMPANIES", link: "#companies", current: false },
  { name: "PROJECTS", link: "/projects", current: false },
  { name: "CONTACT US", link: "/contactus", current: false },
];

const company = [
  { name: "Eurasia", link: "/eurasia", current: false },
  { name: "Rosemco", link: "/rosemco", current: false },
  { name: "Saidowla", link: "/saidowla", current: false },
  { name: "MasalaBazar", link: "/masalabazaar", current: false },
  { name: "EFG Fashion", link: "/efgf", current: false },
  { name: "EFG Aqua", link: "/efgaqua", current: false },
  { name: "EFG Tech", link: "/efgt", current: false },
];

const about = [
  { name: "About EFG", link: "/aboutus", current: false },
  { name: "Chairman's Message", link: "/message", current: false },
];

const Navbar = ({ sPosition }) => {
  const [activeNav, setActiveNav] = useState([...navigation]);
  const [companyActiveNav, setCompanyActiveNav] = useState([...company]);
  const [aboutActiveNav, setAboutActiveNav] = useState([...about]);

  const navigate = useNavigate();
  // console.log(activeNav);

  // console.log(navigate);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const goToTarget = (location) => {
    navigate(location);
  };
  // const scrollPosition = useHandleScroll();

  /*---------------------------------------------------------------------
    HOVER EFFECT
  ----------------------------------------------------------------------*/

  const [backdrop, setBackdrop] = useState(false);

  const linksArray = [
    // [[title: string, href: string], ...]
    ["About Us", "/aboutus"],
    ["Message From Our Chairman", "/message"],
    ["Gallery", "/gallery"],
  ];

  const companyArray = [
    // [[title: string, href: string], ...]
    ["Eurasia", "/eurasia"],
    ["Rosemco", "/rosemco"],
    ["Saidowla", "/saidowla"],
    ["MasalaBazar", "/masalabazaar"],
    ["EFG Fashion", "/efgf"],
    ["EFG Aqua", "/efgaqua"],
    ["EFG Tech", "/efgt"],
  ];

  const backdropHandler = () => {
    setBackdrop((toggler) => !toggler);
  };

  return (
    <>
      {/* <div
        onClick={backdropHandler}
        className={
          backdrop === true
            ? "absolute inset-x-0 bottom-0 top-[70px] h-[100%] bg-black opacity-50 z-[90] tab2min:hidden"
            : " tab2min:hidden"
        }
      ></div> */}
      {/* new navbar */}
      <Disclosure as="nav" className="fixed inset-x-0 top-0 z-[500]">
        {({ open }) => (
          <>
            <div className="flex bg-[#FFCD72] h-[70px] font-normal relative">
              <div
                onClick={() => goToTarget("/")}
                className="text-left basis-2/4 laptop:basis-1/4"
              >
                <img
                  src={efgLogo}
                  alt="efgLogo"
                  className="ml-32 shimmer laptop:ml-2"
                />
              </div>
              <div className="flex items-center justify-around mr-10 desktop:selection:mr-0 basis-2/4 laptop:basis-3/4 tab2:hidden">
                <NavLink
                  to="/"
                  className="hover:text-[#F4070C] hover:font-semibold"
                >
                  Home
                </NavLink>

                {/* about us popover */}

                <PopOverBtn
                  linksArray={linksArray}
                  menuTitle="About"
                  mode="pc"
                ></PopOverBtn>

                <NavLink
                  to="/products"
                  className="hover:text-[#F4070C] hover:font-semibold"
                >
                  Products
                </NavLink>

                {/* companies popover */}

                <PopOverBtn
                  linksArray={companyArray}
                  menuTitle="Companies"
                  mode="pc"
                ></PopOverBtn>

                <NavLink
                  to="/projects"
                  className="hover:text-[#F4070C] hover:font-semibold"
                >
                  Projects
                </NavLink>
                <NavLink
                  to="/contactus"
                  className="hover:text-[#F4070C] hover:font-semibold"
                >
                  Contact Us
                </NavLink>
              </div>
              {/* Mobile menu button*/}
              <div className="absolute inset-y-0 flex items-center right-2 tab2min:hidden z-[120]">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open && backdrop ? (
                    <XIcon
                      onClick={backdropHandler}
                      className="block w-8 h-8 text-black "
                      aria-hidden="true"
                    />
                  ) : (
                    <MenuIcon
                      onClick={backdropHandler}
                      className="block w-8 h-8 text-black "
                      aria-hidden="true"
                    />
                  )}
                </Disclosure.Button>
              </div>

              {/* new mobile nav-bar */}
              <Transition
                show={backdrop ? true : false}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-200"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Disclosure.Panel className="tab2min:hidden absolute right-0 bg-[#FFCD72] z-[100] w-[80vw] h-[100vh] top-[70px] bottom-0 ">
                  <div className="flex flex-col items-start px-2 pt-2 pb-3 mx-auto ml-10 space-y-10">
                    <NavLink
                      to="/"
                      className="hover:text-[#F4070C]  border-b-2 border-black"
                    >
                      Home
                    </NavLink>

                    {/* about us popover */}

                    <PopOverBtn
                      linksArray={linksArray}
                      menuTitle="About"
                      mode="mobile"
                    ></PopOverBtn>

                    <NavLink
                      to="/products"
                      className="hover:text-[#F4070C]  border-b-2 border-black"
                    >
                      Products
                    </NavLink>

                    {/* companies popover */}

                    <PopOverBtn
                      linksArray={companyArray}
                      menuTitle="Companies"
                      mode="mobile"
                    ></PopOverBtn>

                    <NavLink
                      to="/projects"
                      className="hover:text-[#F4070C]  border-b-2 border-black"
                    >
                      Projects
                    </NavLink>
                    <NavLink
                      to="/contactus"
                      className="hover:text-[#F4070C]  border-b-2 border-black"
                    >
                      Contact Us
                    </NavLink>
                  </div>
                </Disclosure.Panel>
              </Transition>
            </div>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Navbar;

// const setActive = (data, type) => {
//   if (type === "nav") {
//     const newNav = navigation.map((item) => {
//       if (item.name === data) {
//         item.current = true;
//       } else {
//         item.current = false;
//       }
//       return item;
//     });

//     if (data !== "COMPANIES" || data !== "ABOUT US") {
//       const upNav = companyActiveNav.map((item) => {
//         item.current = false;
//         return item;
//       });
//       setCompanyActiveNav([...upNav]);
//       setAboutActiveNav([...upNav]);
//     }

//     setActiveNav([...newNav]);
//     // console.log(companyActiveNav);
//   } else if (data === "COMPANIES") {
//     const comNav = company.map((item) => {
//       if (item.name === data) {
//         item.current = true;
//       } else {
//         item.current = false;
//       }
//       return item;
//     });
//     setCompanyActiveNav([...comNav]);
//   } else if (data === "ABOUT US") {
//     const abtNav = about.map((item) => {
//       if (item.name === data) {
//         item.current = true;
//       } else {
//         item.current = false;
//       }
//       return item;
//     });
//     setCompanyActiveNav([...abtNav]);
//   }
// };

// {/* <div
// className={
//   sPosition > 78
//     ? "bg-[#FFF6EA] fixed z-50 right-0 left-0 nav top-0 transition hidden"
//     : "bg-[#DEB887] fixed z-50 right-0 left-0 nav hidden"
// }
// >
// <Disclosure as="nav" className="">
//   {({ open }) => (
//     <>
//       <div className="px-2 mx-auto max-w-7xl sm:px-6 lg:px-8 whitespace-nowrap">
//         <div className="relative flex items-center h-14">
//           <div className="absolute inset-y-0 left-0 flex items-center tab2min:hidden">
//             {/* Mobile menu button*/}
//             <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
//               <span className="sr-only">Open main menu</span>
//               {open ? (
//                 <XIcon className="block w-6 h-6" aria-hidden="true" />
//               ) : (
//                 <MenuIcon
//                   className="block w-6 h-6"
//                   aria-hidden="true"
//                 />
//               )}
//             </Disclosure.Button>
//           </div>

//           {/* Nav-Menu */}
//           <div className="hidden w-full tab2min:block sm:ml-6 ">
//             <div className="relative flex items-center justify-around hover-underline-animation">
//               {activeNav.map((item) =>
//                 item.name === "efgLOGO" ? (
//                   <>
//                     <img
//                       src={efgLogo}
//                       onClick={() => goToTarget("/")}
//                       alt="efgLOGO"
//                       height="50px"
//                       width="210px"
//                       className={
//                         sPosition > 80
//                           ? "block absolute mobile:w-24 ml-[-70px]  laptop:w-[200px] laptop:h-[60px] tablet:w-[150px] tablet:h-[60px] tab2:hidden"
//                           : "hidden"
//                       }
//                     />
//                     <p
//                       className={
//                         sPosition > 80 ? "block mx-28" : "hidden"
//                       }
//                     ></p>
//                   </>
//                 ) : (
//                   <NavLink
//                     to={
//                       item.name === "COMPANIES" ||
//                       item.name === "ABOUT US"
//                         ? ""
//                         : item.link
//                     }
//                     key={item.name}
//                     onClick={() => setActive(item.name, "nav")}
//                     className={classNames(
//                       item.current
//                         ? // ? "text-[#ee3436]"
//                           "text-[#08B05F] font-extrabold "
//                         : "text-black hover:text-[#08B05F] ",
//                       "px-4 py-4 text-sm tablet:text-xs font-normal hover:font-bold"
//                     )}
//                     aria-current={item.current ? "page" : undefined}
//                   >
//                     {item.name === "COMPANIES" ? (
//                       <>
//                         <Menu
//                           onClick={() => setActive(item.name, "nav")}
//                           as="div"
//                           className="relative inline-block ml-4 text-left"
//                         >
//                           <div>
//                             <Menu.Button className="inline-flex justify-center font-normal">
//                               {item.name}
//                               <ChevronDownIcon
//                                 className="w-5 h-5 ml-1 -mr-1"
//                                 aria-hidden="true"
//                               />
//                             </Menu.Button>
//                           </div>

//                           <Transition
//                             as={Fragment}
//                             enter="transition ease-out duration-100"
//                             enterFrom="transform opacity-0 scale-95"
//                             enterTo="transform opacity-100 scale-100"
//                             leave="transition ease-in duration-75"
//                             leaveFrom="transform opacity-100 scale-100"
//                             leaveTo="transform opacity-0 scale-95"
//                           >
//                             <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-right bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
//                               <div className="flex flex-col py-2 hover-underline-animation-off ">
//                                 {companyActiveNav.map((item) => (
//                                   <Menu.Item key={item.name}>
//                                     {({ active }) => (
//                                       <NavLink to={item.link}>
//                                         <button
//                                           onClick={() =>
//                                             setActive(
//                                               item.name,
//                                               "notnav"
//                                             )
//                                           }
//                                           className={classNames(
//                                             active || item.current
//                                               ? "bg-gray-300 text-gray-900"
//                                               : "text-gray-700",
//                                             "block px-4 py-2 text-sm text-left w-full font-semibold "
//                                           )}
//                                         >
//                                           {item.name}
//                                         </button>
//                                       </NavLink>
//                                     )}
//                                   </Menu.Item>
//                                 ))}
//                               </div>
//                             </Menu.Items>
//                           </Transition>
//                         </Menu>
//                       </>
//                     ) : item.name === "ABOUT US" ? (
//                       <>
//                         <Menu
//                           onClick={() => setActive(item.name, "nav")}
//                           as="div"
//                           className="relative inline-block ml-4 text-left"
//                         >
//                           <div>
//                             <Menu.Button className="inline-flex justify-center font-normal">
//                               {item.name}
//                               <ChevronDownIcon
//                                 className="w-5 h-5 ml-1 -mr-1"
//                                 aria-hidden="true"
//                               />
//                             </Menu.Button>
//                           </div>

//                           <Transition
//                             as={Fragment}
//                             enter="transition ease-out duration-100"
//                             enterFrom="transform opacity-0 scale-95"
//                             enterTo="transform opacity-100 scale-100"
//                             leave="transition ease-in duration-75"
//                             leaveFrom="transform opacity-100 scale-100"
//                             leaveTo="transform opacity-0 scale-95"
//                           >
//                             <Menu.Items className="absolute left-0 w-56 mt-2 origin-top-right bg-white rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
//                               <div className="flex flex-col py-2 hover-underline-animation-off ">
//                                 {aboutActiveNav.map((item) => (
//                                   <Menu.Item key={item.name}>
//                                     {({ active }) => (
//                                       <NavLink to={item.link}>
//                                         <button
//                                           onClick={() =>
//                                             setActive(
//                                               item.name,
//                                               "notnav"
//                                             )
//                                           }
//                                           className={classNames(
//                                             active || item.current
//                                               ? "bg-gray-300 text-gray-900"
//                                               : "text-gray-700",
//                                             "block px-4 py-2 text-sm text-left w-full font-semibold "
//                                           )}
//                                         >
//                                           {item.name}
//                                         </button>
//                                       </NavLink>
//                                     )}
//                                   </Menu.Item>
//                                 ))}
//                               </div>
//                             </Menu.Items>
//                           </Transition>
//                         </Menu>
//                       </>
//                     ) : (
//                       item.name
//                     )}
//                   </NavLink>
//                 )
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       {/* mobile view */}
//       <Transition
//         as={Fragment}
//         enter="transition ease-out duration-200"
//         enterFrom="transform opacity-0 scale-95"
//         enterTo="transform opacity-100 scale-100"
//         leave="transition ease-in duration-200"
//         leaveFrom="transform opacity-100 scale-100"
//         leaveTo="transform opacity-0 scale-95"
//       >
//         <Disclosure.Panel className="tab2min:hidden h-[100vh]">
//           <div className="flex flex-col items-start justify-between px-2 pt-2 pb-3 ml-4 space-y-1 ">
//             {activeNav
//               .filter((item) => item.name !== "efgLOGO")
//               .map((item) =>
//                 item.name === "COMPANIES" ? (
//                   <div
//                     key={item.name}
//                     className="flex justify-center my-8 ml-[-16px]"
//                   >
//                     <Menu
//                       as="div"
//                       className="relative inline-block ml-4"
//                     >
//                       <div className="text-left">
//                         <Menu.Button className="inline-flex justify-center">
//                           <button
//                             onClick={() => setActive(item.name, "nav")}
//                             className={classNames(
//                               item.current
//                                 ? " bg-message-button text-[#3f3f3f]"
//                                 : "text-gray-300 hover:bg-message-button hover:text-[#3f3f3f] ",
//                               " px-3 py-2 rounded-md text-base font-medium inline-flex justify-center"
//                             )}
//                           >
//                             <span>{item.name}</span>
//                             <ChevronDownIcon
//                               className="w-5 h-5 ml-1 -mr-1"
//                               aria-hidden="true"
//                             />
//                           </button>
//                         </Menu.Button>
//                       </div>

//                       <Transition
//                         as={Fragment}
//                         enter="transition ease-out duration-100"
//                         enterFrom="transform opacity-0 scale-95"
//                         enterTo="transform opacity-100 scale-100"
//                         leave="transition ease-out duration-7500"
//                         leaveFrom="transform opacity-100 scale-100"
//                         leaveTo="transform opacity-0 scale-95"
//                       >
//                         <Menu.Items className="w-56 mt-2 origin-top-right">
//                           <div className="flex flex-col py-2 hover-underline-animation-off ">
//                             {companyActiveNav.map((item) => (
//                               <Menu.Item
//                                 key={item.name}
//                                 className="my-0.5 "
//                               >
//                                 {({ active }) => (
//                                   <NavLink to={item.link}>
//                                     <button
//                                       onClick={() =>
//                                         setActive(item.name, "notnav")
//                                       }
//                                       className={classNames(
//                                         active || item.current
//                                           ? " bg-message-button text-[#3f3f3f]"
//                                           : "text-white hover:bg-message-button hover:text-[#3f3f3f]",
//                                         "block px-4 py-2 text-sm text-left w-full font-normal tracking-wider"
//                                       )}
//                                     >
//                                       {item.name}
//                                     </button>
//                                   </NavLink>
//                                 )}
//                               </Menu.Item>
//                             ))}
//                           </div>
//                         </Menu.Items>
//                       </Transition>
//                     </Menu>
//                   </div>
//                 ) : item.name === "ABOUT US" ? (
//                   <div
//                     key={item.name}
//                     className="flex justify-center my-8 ml-[-16px]"
//                   >
//                     <Menu
//                       as="div"
//                       className="relative inline-block ml-4"
//                     >
//                       <div className="text-left">
//                         <Menu.Button className="inline-flex justify-center">
//                           <button
//                             onClick={() => setActive(item.name, "nav")}
//                             className={classNames(
//                               item.current
//                                 ? " bg-message-button text-[#3f3f3f]"
//                                 : "text-gray-300 hover:bg-message-button hover:text-[#3f3f3f] ",
//                               " px-3 py-2 rounded-md text-base font-medium inline-flex justify-center"
//                             )}
//                           >
//                             <span>{item.name}</span>
//                             <ChevronDownIcon
//                               className="w-5 h-5 ml-1 -mr-1"
//                               aria-hidden="true"
//                             />
//                           </button>
//                         </Menu.Button>
//                       </div>

//                       <Transition
//                         as={Fragment}
//                         enter="transition ease-out duration-100"
//                         enterFrom="transform opacity-0 scale-95"
//                         enterTo="transform opacity-100 scale-100"
//                         leave="transition ease-out duration-7500"
//                         leaveFrom="transform opacity-100 scale-100"
//                         leaveTo="transform opacity-0 scale-95"
//                       >
//                         <Menu.Items className="w-56 mt-2 origin-top-right">
//                           <div className="flex flex-col py-2 hover-underline-animation-off ">
//                             {aboutActiveNav.map((item) => (
//                               <Menu.Item
//                                 key={item.name}
//                                 className="my-0.5 "
//                               >
//                                 {({ active }) => (
//                                   <NavLink to={item.link}>
//                                     <button
//                                       onClick={() =>
//                                         setActive(item.name, "notnav")
//                                       }
//                                       className={classNames(
//                                         active || item.current
//                                           ? " bg-message-button text-[#3f3f3f]"
//                                           : "text-white hover:bg-message-button hover:text-[#3f3f3f]",
//                                         "block px-4 py-2 text-sm text-left w-full font-normal tracking-wider"
//                                       )}
//                                     >
//                                       {item.name}
//                                     </button>
//                                   </NavLink>
//                                 )}
//                               </Menu.Item>
//                             ))}
//                           </div>
//                         </Menu.Items>
//                       </Transition>
//                     </Menu>
//                   </div>
//                 ) : (
//                   <Disclosure.Button
//                     key={item.name}
//                     as="button"
//                     className={classNames(
//                       item.current
//                         ? " bg-message-button text-[#3f3f3f]"
//                         : " text-gray-300  hover:bg-message-button hover:text-[#3f3f3f] ",
//                       "block px-3 py-2 rounded-md text-base font-medium"
//                     )}
//                     aria-current={item.current ? "page" : undefined}
//                   >
//                     <button
//                       onClick={() => {
//                         goToTarget(item.link);
//                         setActive(item.name, "nav");
//                       }}
//                     >
//                       {item.name}
//                     </button>
//                   </Disclosure.Button>
//                 )
//               )}
//           </div>
//         </Disclosure.Panel>
//       </Transition>
//     </>
//   )}
// </Disclosure>
// </div> */}
