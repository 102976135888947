import React from "react";
import Titles from "../../components/titles/Titles";
import efg_logo from "../../assets/img/efglogo.png";
import who_we_are from "../../assets/img/who_we_are.png";
import quality from "../../assets/icons/quality.svg";
import innovation from "../../assets/icons/innovation.svg";
import commitment from "../../assets/icons/commitment.svg";
import relation from "../../assets/icons/relation.svg";

import AOS from "aos";
import "aos/dist/aos.css"; // You can also use <link> for styles
// ..
AOS.init();

const Mission = () => {
  const array = [
    {
      image: quality,
      title: "Quality",
      desc: "We consistently delivers high-quality, safe, and effective specialty food products, that meet or exceed customer expectations.",
    },
    {
      image: innovation,
      title: "Innovation",
      desc: "We are continuously creating innovative idea to its every step of operation to add distinctiveness and competitiveness to the products and services.",
    },
    {
      image: commitment,
      title: "Commitment",
      desc: "We have an experienced workforce, equipped with continuing education and training in emerging Quality techniques and philosophy.",
    },
    {
      image: relation,
      title: "Customer Relationship",
      desc: "We engaged with strategic marketing process which involves building customer relationships by creating and delivering superior customer value aiming to produce high customer equity.",
    },
  ];

  return (
    <div
      data-aos="fade-up"
      data-aos-delay="0"
      data-aos-duration="600"
      data-aos-easing="ease-in-out"
      data-aos-mirror="false"
      data-aos-once="false"
      data-aos-anchor-placement="top-center"
      id="mission"
      className="mt-28 mobile:mt-12"
    >
      <div className="hidden mb-20 mt-18">
        {/* icons */}
        <div className="flex py-16 mobile:flex-col justify-evenly">
          {/* quality */}
          <div className="flex flex-col items-center justify-center ">
            <svg
              className="w-14 h-14 fill-green-500"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
            </svg>
            <h2 className="text-2xl font-medium text-black ">Quality</h2>
          </div>

          {/* innovation */}
          <div className="flex flex-col items-center justify-center mobile:mt-8 mobile:mb-4 ">
            <svg
              className="w-14 h-14 fill-green-500 stroke-green-500"
              x="0px"
              y="0px"
              viewBox="0 0 305.954 305.954"
            >
              <path
                d="M256.538,103.561C256.538,46.366,210.177,0,152.976,0C95.787,0,49.416,46.366,49.416,103.561
	c0,42.953,26.157,96.479,63.401,120.476c-5.295,0.165-9.565,4.46-9.565,9.804c0,3.575,1.901,6.704,4.747,8.434
	c1.092,0.664,1.092,2.195,0,2.859c-2.845,1.73-4.747,4.859-4.747,8.434c0,3.584,1.911,6.719,4.77,8.445
	c1.09,0.658,1.094,2.187,0.009,2.852c-3.076,1.886-5.058,5.417-4.747,9.33c0.416,5.239,5.034,9.156,10.29,9.156h16.421
	c0.23,12,10.411,22.603,22.973,22.603c12.58,0,22.752-10.603,22.98-22.603h16.478c4.723,0,9.006-3.156,10.032-7.766
	c0.979-4.397-1.091-8.57-4.496-10.712c-1.012-0.637-1.055-2.13-0.038-2.759c2.852-1.767,4.778-4.954,4.778-8.52
	c0-3.56-1.919-6.739-4.763-8.506c-1.001-0.622-1.001-2.119,0.001-2.74c2.844-1.765,4.762-4.94,4.762-8.5
	c0-5.344-4.27-9.642-9.564-9.807C230.391,200.043,256.538,146.513,256.538,103.561z M152.171,216.91
	c-0.401-11.844-2.507-18.704,3.692-35.134c2.654-7.032,12.909-19.107,7.064-68.754c-0.073-0.625,0.319-1.21,0.926-1.379
	c0.607-0.169,1.246,0.129,1.507,0.702c11.438,25.212,11.268,51.989,10.141,61.136c22.186-10.779,32.666-35.558,22.544-62.769
	c-8.248-22.215-45.363-46.475-62.833-56.911c-1.027-0.613-2.332-0.492-3.229,0.299s-1.181,2.072-0.699,3.167
	c5.048,11.475,9.286,33.849-10.963,64.547c-12.918,22.788,2.469,47.8,23.888,54.751c-6.255,14.355-5.829,25.67-2.398,38.862
	c-38.757-9.605-72.667-66.646-72.667-111.867c0-46.227,37.613-83.834,83.833-83.834c46.223,0,83.835,37.607,83.835,83.834
	C236.811,153.053,195.922,217.769,152.171,216.91z"
              />
            </svg>
            <h2 className="text-2xl font-medium text-black ">Innovation</h2>
          </div>

          {/* commitment */}
          <div className="flex flex-col items-center justify-center mobile:my-4 ">
            <svg
              className="w-14 h-14 fill-green-500 stroke-green-500"
              viewBox="0 -64 640 640"
            >
              <path d="M434.7 64h-85.9c-8 0-15.7 3-21.6 8.4l-98.3 90c-.1.1-.2.3-.3.4-16.6 15.6-16.3 40.5-2.1 56 12.7 13.9 39.4 17.6 56.1 2.7.1-.1.3-.1.4-.2l79.9-73.2c6.5-5.9 16.7-5.5 22.6 1 6 6.5 5.5 16.6-1 22.6l-26.1 23.9L504 313.8c2.9 2.4 5.5 5 7.9 7.7V128l-54.6-54.6c-5.9-6-14.1-9.4-22.6-9.4zM544 128.2v223.9c0 17.7 14.3 32 32 32h64V128.2h-96zm48 223.9c-8.8 0-16-7.2-16-16s7.2-16 16-16 16 7.2 16 16-7.2 16-16 16zM0 384h64c17.7 0 32-14.3 32-32V128.2H0V384zm48-63.9c8.8 0 16 7.2 16 16s-7.2 16-16 16-16-7.2-16-16c0-8.9 7.2-16 16-16zm435.9 18.6L334.6 217.5l-30 27.5c-29.7 27.1-75.2 24.5-101.7-4.4-26.9-29.4-24.8-74.9 4.4-101.7L289.1 64h-83.8c-8.5 0-16.6 3.4-22.6 9.4L128 128v223.9h18.3l90.5 81.9c27.4 22.3 67.7 18.1 90-9.3l.2-.2 17.9 15.5c15.9 13 39.4 10.5 52.3-5.4l31.4-38.6 5.4 4.4c13.7 11.1 33.9 9.1 45-4.7l9.5-11.7c11.2-13.8 9.1-33.9-4.6-45.1z" />
            </svg>
            <h2 className="text-2xl font-medium text-black ">Commitment</h2>
          </div>

          {/* reliability */}
          <div className="flex flex-col items-center justify-center mobile:mt-4 ">
            <svg
              x="0px"
              y="0px"
              className="w-14 h-14 fill-green-500 stroke-green-500"
              viewBox="0 0 24 24"
            >
              <path d="M 5 3 C 3.897 3 3 3.897 3 5 L 3 19 C 3 20.103 3.897 21 5 21 L 19 21 C 20.103 21 21 20.103 21 19 L 21 9.2421875 L 19 11.242188 L 19.001953 19 L 5 19 L 5 5 L 16.757812 5 L 18.757812 3 L 5 3 z M 21.292969 3.2929688 L 11 13.585938 L 7.7070312 10.292969 L 6.2929688 11.707031 L 11 16.414062 L 22.707031 4.7070312 L 21.292969 3.2929688 z"></path>
            </svg>

            <h2 className="text-2xl font-medium text-black ">Reliability</h2>
          </div>
        </div>

        {/* EFG quotes box*/}
        <div className="bg-[#C3C5C8be] w-4/5 mx-auto my-4 py-14 flex mobile:flex-col flex-row items-center">
          {/* logo */}
          <div className="flex flex-col items-center m-7 basis-1/4">
            <img src={efg_logo} alt="" />
            <h4 className="font-semibold">ENSURING THE HIGHEST QUALITY</h4>
            <h4 className="font-semibold">Since 2002</h4>
          </div>

          {/* vertical line  */}

          <div className="w-1 mobile:hidden h-[120px] mx-5 bg-green-500"></div>
          <hr className="w-[120px] lg:hidden sm:hidden h-1 mx-5 bg-green-500" />

          {/* quote writings */}

          <div className="flex flex-col items-center m-5 mb-10 basis-3/4">
            <p className="font-medium text-left mobile:p-5">
              Food is also a great way for sharing culture with people from
              different backgrounds. We are able to share our culture and
              heritage through food and give each other nourishment. Being able
              to make a dish and share that with the people you love is one of
              the most universal concepts because it’s at the root of our
              survival.
            </p>
            <p className="py-2 font-medium text-left mobile:p-5">
              To help bring together the peoples of the world with precious,
              delicious, healthy food rooted in the culture of ancient Persia
              and perfected with modern food science.
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-[90%] laptop:w-[95%] mx-auto laptop:flex-col">
        {/* left image */}
        <div className=" basis-[40%] wide:basis-[45%] desktop:basis-[52%] laptop:basis-full laptop:grid laptop:grid-cols-12">
          <img
            src={who_we_are}
            alt="who_we_are"
            className="object-contain h-full laptop:col-span-6"
          />
          <div className="flex flex-col items-start text-left lg:hidden laptop:col-span-6">
            <Titles text="Who Are We?"></Titles>
            <p className="my-6 laptop:text-sm mobile:text-xs">
              Euro Foods Group is one of the leading company in the food
              industry in Bangladesh manufacturing branded generic products for
              local and overseas market in Asia, Europe and North America.
            </p>
          </div>
        </div>

        {/* right cards group */}
        <div className="basis-[60%] wide:basis-[55%] desktop:basis-[48%] laptop:gap-x-60 laptop:basis-full">
          <div className="flex flex-col items-start justify-between text-left laptop:hidden">
            <Titles text="Who Are We?"></Titles>
            <p className="my-6 ">
              Euro Foods Group is one of the leading company in the food
              industry in Bangladesh manufacturing branded generic products for
              local and overseas market in Asia, Europe and North America.
            </p>
          </div>
          <div className="grid grid-cols-12 gap-2 mt-8 desktop:gap-y-12 desktop:gap-x-10 mobile:gap-x-1 laptop:mt-16 mobile:mt-6">
            {array.map((items, index) => (
              <div
                key={items.image}
                className="h-[324px] rounded-xl relative border-green-900 border-[1.5px] col-span-3 desktop:col-span-6 laptop:col-span-6 mobile:h-[218px] animate-[fadeOut_2s_ease-in-out]"
              >
                <img
                  src={items.image}
                  alt={index}
                  className="absolute left-[50%] -top-16 mobile:-top-12 -translate-x-1/2 mobile:w-1/2 mobile:h-1/2"
                />
                <div className="relative h-[70%]">
                  <p className="mt-12 text-lg font-bold mobile:mt-14 mobs:mt-8 mobile:text-xs mobs:text-xs group-hover:text-white words">
                    {items.title}
                  </p>

                  <div className="absolute p-2 top-1/3 desktop:top-1/4 mobs:top-[10%]">
                    <p className="text-sm wide:text-xs desktop:text-sm mobile:text-xs group-hover:text-white">
                      {items.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mission;
