import React from "react";
import { useLocation } from "react-router-dom";
import CompanyPage from "../../components/companypage/CompanyPage";

const Rosemco = ({ galary }) => {
  const location = useLocation();
  return (
    <>
      {galary ? (
        <div id="rosemco">
          <CompanyPage pathname={location.pathname}></CompanyPage>
        </div>
      ) : (
        <div>This is rosemco galary</div>
      )}
    </>
  );
};

export default Rosemco;
